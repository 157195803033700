import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store/index";
import { isInquiryOrCart } from "../../../actions";


class OrderFilterBar extends Component {
  //List Layout View
  listLayout() {
    document.querySelector(".collection-grid-view").style = "opacity:0";
    document.querySelector(".product-wrapper-grid").style = "opacity:0.2";
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row")
      .childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-lg-12");
    });
    setTimeout(function () {
      document.querySelector(".product-wrapper-grid").style = "opacity: 1";
    }, 500);
  }

  //Grid Layout View
  gridLayout() {
    document.querySelector(".collection-grid-view").style = "opacity:1";
    document
      .querySelector(".product-wrapper-grid")
      .classList.remove("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row")
      .childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-lg-3");
    });
  }

  // Layout Column View
  LayoutView = (colSize) => {
    if (
      !document
        .querySelector(".product-wrapper-grid")
        .classList.contains("list-view")
    ) {
      var elems = document.querySelector(".infinite-scroll-component .row")
        .childNodes;
      [].forEach.call(elems, function (el) {
        el.className = "";
        el.classList.add("col-lg-" + colSize);
      });
    }

    this.props.onLayoutViewClicked(colSize);
  };

  render() {
    const { orderStatusList, isInquiry } = this.props;
    return (
      <div className="product-filter-content">
        <div className="search-count">
          {isInquiry ? (<h5
            style={{
              color: getColorCodeBasedOnKey("text_showing_order_order"),
            }}
          >
            {getValueFromLanguageObject("str_showing_inquiry")
              ? getValueFromLanguageObject("str_showing_inquiry")
              : "Showing Inquiry"} {" "}
            {this.props.orderList.length} {" "}
            {getValueFromLanguageObject("str_result")
              ? getValueFromLanguageObject("str_result")
              : "Results"}
          </h5>) : (<h5
            style={{
              color: getColorCodeBasedOnKey("text_showing_order_order"),
            }}
          >
            {getValueFromLanguageObject("str_showing_order")
              ? getValueFromLanguageObject("str_showing_order")
              : "Showing Order"} {" "}
            {this.props.orderList.length} {" "}
            {getValueFromLanguageObject("str_result")
              ? getValueFromLanguageObject("str_result")
              : "Results"}
          </h5>)}
          
        </div>
        {/* <div className="collection-view">
                    <ul>
                        <li><i
                            className="fa fa-th grid-layout-view" onClick={this.gridLayout}></i>
                        </li>
                        <li><i
                            className="fa fa-list-ul list-layout-view" onClick={this.listLayout}></i>
                        </li>
                    </ul>
                </div> */}
        {/* <div className="collection-grid-view">
                    <ul>
                        <li>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`}
                                alt=""
                                className="product-2-layout-view" onClick={() => this.LayoutView(6)} />
                        </li>
                        <li>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`}
                                alt=""
                                className="product-3-layout-view" onClick={() => this.LayoutView(4)} />
                        </li>
                        <li>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`}
                                alt=""
                                className="product-4-layout-view" onClick={() => this.LayoutView(3)} />
                        </li>
                        <li>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`}
                                alt=""
                                className="product-6-layout-view" onClick={() => this.LayoutView(2)} />
                        </li>
                    </ul>
                </div> */}
        <div className="right-column">
          <div className="product-page-filter custom-order-filter">
            <select
              onChange={(e) => this.props.handleOrderStatusChange(e.target.value)}
            >
              {isInquiry? (<option
                value={0}
                style={{
                  color: getColorCodeBasedOnKey("text_order_status_order"),
                }}
              >
                {getValueFromLanguageObject("str_inquiry_status_cap")
                  ? getValueFromLanguageObject("str_inquiry_status_cap")
                  : "Inquiry Status"}
              </option>) : (<option
                value={0}
                style={{
                  color: getColorCodeBasedOnKey("text_order_status_order"),
                }}
              >
                {getValueFromLanguageObject("str_order_status_cap")
                  ? getValueFromLanguageObject("str_order_status_cap")
                  : "Order Status"}
              </option>)}
              
              
              {orderStatusList.map((orderStatus) => {
                return (
                  <option
                    key={orderStatus.id}
                    value={orderStatus.id}
                    style={{
                      color: getColorCodeBasedOnKey("text_order_status_order"),
                    }}
                  >
                    {orderStatus.status}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="product-page-filter custom-order-filter">
            <select className="border-left-none"
              onChange={(e) =>
                this.props.handleSortingOrderFilter(e.target.value)
              }
            >
              <option
                value={0}
                style={{
                  color: getColorCodeBasedOnKey("text_sorting_items_order"),
                }}
              >
                {getValueFromLanguageObject("str_sorting_items")
                  ? getValueFromLanguageObject("str_sorting_items")
                  : "Sorting items"}
              </option>
              {!isInquiry &&
              <div>
              <option
                value={1}
                style={{
                  color: getColorCodeBasedOnKey("text_sorting_items_order"),
                }}
              >
                {getValueFromLanguageObject("str_price_high_to_low")
                  ? getValueFromLanguageObject("str_price_high_to_low")
                  : "Price: High to Low"}
              </option>
              <option
                value={2}
                style={{
                  color: getColorCodeBasedOnKey("text_sorting_items_order"),
                }}
              >
                {getValueFromLanguageObject("str_price_low_to_high")
                  ? getValueFromLanguageObject("str_price_low_to_high")
                  : "Price: Low to High"}
              </option>
              </div>}
              <option
                value={3}
                style={{
                  color: getColorCodeBasedOnKey("text_sorting_items_order"),
                }}
              >
                {getValueFromLanguageObject("str_date_new_to_old")
                  ? getValueFromLanguageObject("str_date_new_to_old")
                  : "Date: New to Old"}
              </option>
              <option
                value={4}
                style={{
                  color: getColorCodeBasedOnKey("text_sorting_items_order"),
                }}
              >
                {getValueFromLanguageObject("str_date_old_to_new")
                  ? getValueFromLanguageObject("str_date_old_to_new")
                  : "Date: Old to New"}
              </option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {isInquiryOrCart: isInquiryOrCart}
)(OrderFilterBar);
