import React, { Component } from "react";
import { Helmet } from "react-helmet";
import FilterBar from "../../collection/common/filter-bar";
import ProductListing from "../../collection/common/product-listing";
import StickyBox from "react-sticky-box";
import Breadcrumb from "../../common/breadcrumb";
import NewProduct from "../../common/new-product";
import Filter from "../../collection/common/filter";
import { getCall } from "../../../api/post";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store";
import ProductLoaderMain from "../contentloader/product-loader";

import { connect } from "react-redux";
import { getCommonCounter, getNotificationCounter } from "../../../actions";
import {
  getImageUrl,
  getPlaceHolderImage,
  getOfferBannerUrl,
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
  getPlaceHolderBanner,
} from "../../../services";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import MainLoader from "../../../animation/MainLoader";
import NoProductFoundProductAnim from "../../../animation/AnimFiles/NoProductFoundProductAnim";

class Products extends Component {
  state = {
    layoutColumns: 3,
    productList: [],
    newArrival: [],
    skip: 0,
    subCategoryId: "",
    categoryId: "",
    searchValue: "",
    isLoading: false,
    sortingOrder: 0,
    deepLinkCategoryId: "",
    deepLinkSubCategoryId: "",
    offerBanners: [],
    isLoaderVisible: true,
    AppTitle: "",
    showErrorMessageModal: false,
    errorMessage: "",
    masterDetailsObj: {},
    categories: [],
  };

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      this.props.history.push(`/login`);
      return;
    }
    this.initMasterDetailsId().then(() => {
      if (this.props.match.path && this.props.match.path === "/c/:id") {
        this.setState({ deepLinkCategoryId: this.props.match.params.id });
      }
      if (this.props.match.path && this.props.match.path === "/s/:id") {
        this.setState({ deepLinkSubCategoryId: this.props.match.params.id });
      }
      if (
        this.props.match.path !== "/c/:id" &&
        this.props.match.path !== "/s/:id"
      ) {
        this.setState({ isLoading: true });
        this.initProductList().then(() => {
          this.setState({ isLoading: false });
        });
      }
      this.initProductListNewProduct();
      this.initOfferBanner();
      this.initMerchantInfo();
      this.initCategory();
      this.props.notificationCounter();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initOfferBanner = async () => {
    try {
      const { masterDetailsObj } = this.state;
      const offerBannerUrl = getOfferBannerUrl(masterDetailsObj);
      const offerBanners = await getCall(offerBannerUrl, []);
      if (offerBanners.error) {
        this.loadErrorMessageAnimation(offerBanners.error.message);
        if (offerBanners.error.statusCode === 401) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("apiToken");
          this.props.history.push(`/login`);
          return;
        }
      } else {
        const banner = offerBanners.map((banner) => {
          return banner.media.length > 0 ? banner.media[0].image : "";
        });
        this.setState({ offerBanners: banner });
      }
    } catch (error) {
      this.setState({ offerBanners: [] });
    }
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCategory = async () => {
    const categoryUrl = "categories?filter[where][isCategoryData]=1";
    const categories = await getCall(categoryUrl, []);

    if (categories.error) {
      this.loadErrorMessageAnimation(categories.error.message);
    } else {
      this.setState({ categories: categories.data });
    }
  };

  initProductListNewProduct = async () => {
    const productListUrl =
      "products?filter[include]=productbrand&filter[include]=productmedia&filter[include]=category&filter[where][productstatus]=1&filter[where][availablequantity][gt]=0&filter[order][0]=created DESC&filter[skip]=0&filter[limit]=20";

    const newArrival = await getCall(productListUrl, []);
    if (newArrival.error) {
      this.loadErrorMessageAnimation(newArrival.error.message);
      if (newArrival.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ newArrival });
    }
  };

  openFilter = () => {
    document.querySelector(".collection-filter").style = "left: -15px";
  };

  initProductList = async () => {
    try {
      const {
        categoryId,
        subCategoryId,
        sortingOrder,
        skip,
        productList,
        searchValue,
      } = this.state;

      let productListUrl =
        "products?filter[include]=productbrand&filter[include]=productmedia&filter[include]=category&filter[where][productstatus]=1";

      // "products?filter[include]=productbrand&filter[include]=productmedia&filter[include]=category&filter[where][productstatus]=1&filter[where][availablequantity][gt]=0";

      if (subCategoryId && subCategoryId.length > 0) {
        productListUrl =
          productListUrl + "&filter[where][categoryId]=" + subCategoryId;
      } else {
        if (categoryId && categoryId.length > 0) {
          productListUrl =
            productListUrl + "&filter[where][maincategory]=" + categoryId;
        }
      }

      if (sortingOrder) {
        if (sortingOrder === "2") {
          //Price: High to Low
          productListUrl = productListUrl + "&filter[order][0]=price DESC";
        }
        if (sortingOrder === "3") {
          //Price: Low to High
          productListUrl = productListUrl + "&filter[order][0]=price ASC";
        }
        if (sortingOrder === "4") {
          //Best Selling
          productListUrl =
            productListUrl + "&filter[order][0]=sellcounter DESC";
        }
        if (sortingOrder === "5") {
          //Newest Products
          productListUrl = productListUrl + "&filter[order][0]=created DESC";
        }
      }

      if (searchValue.toString().length > 0) {
        productListUrl =
          productListUrl + `&filter[where][name][like]=%${searchValue}%`;
      }

      const skipLimit = "&filter[skip]=" + skip + "&filter[limit]=20";
      productListUrl = productListUrl + skipLimit;
      const products = await getCall(productListUrl, []);
      this.setState({ isLoading: false });
      setTimeout(() => {
        this.setState({ isLoaderVisible: false });
      }, 1000);
      if (products.error) {
        this.loadErrorMessageAnimation(products.error.message);
        if (products.error.statusCode === 401) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("apiToken");
          this.props.history.push(`/login`);
          return;
        }
      } else {
        if (skip > 0) {
          const pl = productList.concat(products);
          this.setState({ productList: pl });
        } else {
          this.setState({ productList: products });
        }
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handleCategoryWiseProductListFilter = (categoryId) => {
    this.setState(
      { categoryId, skip: 0, subCategoryId: "", isLoading: true },
      () => {
        this.initProductList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handleSubCategoryWiseProductListFilter = (subCategoryId) => {
    this.setState({ subCategoryId, skip: 0, isLoading: true }, () => {
      this.initProductList().then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  handleSubCategoryWiseProductListFilterDeepLinking = (
    subCategoryId,
    categoryId
  ) => {
    this.setState(
      { subCategoryId, categoryId, skip: 0, isLoading: true },
      () => {
        this.initProductList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handleSortingProductFilter = (order) => {
    this.setState({ sortingOrder: order, skip: 0, isLoading: true }, () => {
      this.initProductList().then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  handlePagination = (skip) => {
    this.setState({ skip }, () => {
      this.initProductList();
    });
  };

  onSearchProduct = (value) => {
    this.setState({ searchValue: value, skip: 0, isLoading: true }, () => {
      this.initProductList().then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  handleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      productList,
      newArrival,
      isLoading,
      offerBanners,
      isLoaderVisible,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
      masterDetailsObj,
      categories,
    } = this.state;

    return (
      <>
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>
              {AppTitle} |{" "}
              {getValueFromLanguageObject("str_collection_of_products")
                ? getValueFromLanguageObject("str_collection_of_products")
                : "Collection of Products"}{" "}
            </title>
            <meta
              name="description"
              content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
            />
          </Helmet>
          {/*SEO Support End */}

          <Breadcrumb
            title={
              getValueFromLanguageObject("str_products")
                ? getValueFromLanguageObject("str_products")
                : "Products"
            }
          />

          <section className="section-b-space">
            <div className="collection-wrapper">
              <div className="container">
                <div className="row">
                  {categories.length !== 0 && (
                    <div className="col-sm-3 collection-filter">
                      <StickyBox offsetTop={20} offsetBottom={20}>
                        <div>
                          <Filter
                            categoryId={this.state.deepLinkCategoryId}
                            subCategoryId={this.state.deepLinkSubCategoryId}
                            productList={productList}
                            handleCategoryWiseProductListFilter={
                              this.handleCategoryWiseProductListFilter
                            }
                            handleSubCategoryWiseProductListFilter={
                              this.handleSubCategoryWiseProductListFilter
                            }
                            handleSubCategoryWiseProductListFilterDeepLinking={
                              this
                                .handleSubCategoryWiseProductListFilterDeepLinking
                            }
                          />

                          <NewProduct newProducts={newArrival} />
                          {/* {newArrival.length === 0 && <NewProductLoaderMain />} */}
                          {/* <div className="collection-sidebar-banner">
                        <a href="#">
                          <img
                            src={`/assets/images/side-banner.png`}
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                      </div> */}
                        </div>
                      </StickyBox>
                      {/*side-bar banner end here*/}
                    </div>
                  )}

                  <div className="collection-content col">
                    <div className="page-main-content ">
                      <div className="">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="top-banner-wrapper">
                              <div style={{ height: "350px" }}>
                                <img
                                  src={
                                    offerBanners.length > 0
                                      ? getImageUrl(
                                          offerBanners[0],
                                          masterDetailsObj
                                        )
                                      : getPlaceHolderBanner()
                                  }
                                  className="img-fluid"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "content",
                                  }}
                                  alt=""
                                />
                              </div>
                              <div className="top-banner-content small-section">
                                <h4
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_products_products"
                                    ),
                                  }}
                                >
                                  {getValueFromLanguageObject("str_products")
                                    ? getValueFromLanguageObject("str_products")
                                    : "Products"}
                                </h4>
                              </div>
                            </div>

                            <div className="row mb-3 search-product">
                              <div className="col-md-6 col-sm-0 " />
                              <div className="col-md-6 col-sm-12 search-form subscribe-form d-flex justify-content-end">
                                <div className="form-group mb-0 ml-0 mr-0 d-flex">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="search-product"
                                    onChange={(e) => {
                                      if (e.target.value === "") {
                                        this.onSearchProduct(e.target.value);
                                      } else {
                                        this.setState({
                                          searchValue: e.target.value,
                                        });
                                      }
                                    }}
                                    placeholder={getValueFromLanguageObject(
                                      "str_search_products"
                                    )}
                                  />
                                  <button
                                    onClick={() => {
                                      const { searchValue } = this.state;
                                      this.onSearchProduct(searchValue);
                                    }}
                                    className="btn btn-solid product-search ml-3"
                                    type="submit"
                                    id=""
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="collection-product-wrapper">
                              <div className="product-top-filter">
                                <div className="container-fluid p-0">
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <div className="filter-main-btn">
                                        <span
                                          onClick={this.openFilter}
                                          className="filter-btn btn btn-theme"
                                        >
                                          <i
                                            className="fa fa-filter"
                                            aria-hidden="true"
                                          />
                                          {getValueFromLanguageObject(
                                            "str_filter"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <FilterBar
                                        productList={productList}
                                        handleSortingProductFilter={
                                          this.handleSortingProductFilter
                                        }
                                        onLayoutViewClicked={(colmuns) =>
                                          this.LayoutViewClicked(colmuns)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {productList.length !== 0 && (
                                <ProductListing
                                  productList={productList}
                                  colSize={this.state.layoutColumns}
                                  handlePagination={this.handlePagination}
                                  handleLoader={this.handleLoader}
                                />
                              )}
                              {productList.length === 0 && isLoaderVisible && (
                                <ProductLoaderMain />
                              )}

                              {productList.length === 0 && !isLoaderVisible && (
                                <NoProductFoundProductAnim />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
  }
)(Products);

// export default Products;
