import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import MainHeader from "../components/layouts/header/main_header";
import MainFooter from "./layouts/footer/main_footer";
import NotificationList from "./layouts/notifications/notification-list";
import { Drawer } from "@material-ui/core";
import { connect } from "react-redux";
import { getCommonCounter, getNotificationCounter } from "../actions";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import ThemeSettings from "./common/theme-settings";
import { withRouter } from "react-router-dom";

const notificationHeaderStyle = {
  textTransform: "uppercase",
  color: "#222222",
  fontSize: "20px",
  padding: "15px",
  backgroundColor: "#DCDCDC",
  fontWeight: "400",
};

class App extends Component {
  state = { isDrawerVisible: false };

  handleNotificicationDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({ isDrawerVisible: !isDrawerVisible });
  };

  onClickOnSettingsIcon = () => {
    this.props.history.push(`/settings`);
  };

  onCloseofNotificationDrawer = () => {
    this.setState({ isDrawerVisible: false });
    this.props.notificationCounter();
  };
  render() {
    const { isDrawerVisible } = this.state;

    return (
      <div>
        <NotificationContainer />
        <MainHeader
          handleNotificicationDrawer={this.handleNotificicationDrawer}
          onClickOnSettingsIcon={this.onClickOnSettingsIcon}
        />

        <Drawer
          anchor="right"
          open={isDrawerVisible}
          onClose={this.onCloseofNotificationDrawer}
        >
          <h3 style={notificationHeaderStyle}>Notifications</h3>
          <NotificationList />
        </Drawer>
        {this.props.children}
        <MainFooter />

        <ThemeSettings />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
  }
)(withTranslate(withRouter(App)));
// export default withTranslate(App);
