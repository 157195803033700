import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../common/breadcrumb";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../../store";
import { getCall, patchCall } from "../../../api/post";
import {
  getMerchantInformationUrl,
  getSettingUrl,
  getOrderStatusUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import OrderFilterBar from "../../collection/common/order-filter-bar";
import InfiniteScroll from "react-infinite-scroll-component";
import { getNotificationCounter, getCommonCounter } from "../../../actions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import CancelOrderAnim from "../../../animation/CancelOrderAnim";
import NoProductFoundOrderAnim from "../../../animation/AnimFiles/NoProductFoundOrderAnim";
import ConfirmAlertPopup from "../../../Alert/ConfirmAlertPopup";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import { isInquiryOrCart } from "../../../actions";

class Orders extends Component {
  state = {
    layoutColumns: 3,
    productList: [],
    newArrival: [],
    skip: 0,
    orderList: [],
    orderStatusList: [],
    orderStatusFilter: "0",
    sortingFilter: "0",
    hasMoreItems: true,
    limit: 10,
    isLoading: true,
    AppTitle: "",
    currentCatelogueType: {},
    merchantInfo: {},
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
  };

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      this.props.history.push(`/login`);
      return;
    }

    this.initMasterDetailsId().then(() => {
      this.fetchMoreItems();
      this.initMerchantInfo();
      this.initCatelogueSettings();
      this.initOrderStatus();
      this.initOrderList().then(() => {
        this.setState({ isLoading: false });
      });

      this.props.notificationCounter();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initOrderList = async () => {
    const {
      orderStatusFilter,
      skip,
      sortingFilter,
      orderList,
      limit,
    } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    let orderListUrl = `orders?filter[where][userId]=${id}&filter[where][inshoppingcart]=0&filter[include]=orderdetails`;

    if (orderStatusFilter !== "0") {
      orderListUrl =
        orderListUrl + "&filter[where][orderstatus]=" + orderStatusFilter;
    }

    if (sortingFilter !== "0") {
      if (sortingFilter === "1") {
        // Price: High to Low
        orderListUrl = orderListUrl + "&filter[order][1]=totalamount DESC";
      }
      if (sortingFilter === "2") {
        // Price: Low to High
        orderListUrl = orderListUrl + "&filter[order][1]=totalamount";
      }
      if (sortingFilter === "3") {
        // Date: New to Old
        orderListUrl = orderListUrl + "&filter[order][0]=date DESC";
      }
      if (sortingFilter === "4") {
        // Price: Old to New
        orderListUrl = orderListUrl + "&filter[order][0]=date";
      }
    } else {
      orderListUrl = orderListUrl + "&filter[order]=created DESC";
    }
    orderListUrl = orderListUrl + `&filter[skip]=${skip}&filter[limit]=10`;

    const orders = await getCall(orderListUrl, []);
    if (orders.error) {
      this.loadErrorMessageAnimation(orders.error.message);
      if (orders.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      if (skip > 0) {
        const pl = orderList.concat(orders);
        if (orders.length < limit || orders.length === 0) {
          this.setState({ hasMoreItems: false });
        } else {
          this.setState({ hasMoreItems: true });
        }
        this.setState({ orderList: pl });
      } else {
        if (orders.length < limit || orders.length === 0) {
          this.setState({ hasMoreItems: false });
        } else {
          this.setState({ hasMoreItems: false });
        }
        this.setState({ orderList: orders });
      }
    }
  };

  initOrderStatus = async () => {
    const { masterDetailsObj } = this.state;
    const orderStatusUrl = getOrderStatusUrl(masterDetailsObj);
    const orderStatusList = await getCall(orderStatusUrl, []);
    if (orderStatusList.error) {
      this.loadErrorMessageAnimation(orderStatusList.error.message);
      if (orderStatusList.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ orderStatusList });
    }
  };

  handleOrderStatusChange = (orderstatus) => {
    this.setState(
      {
        orderStatusFilter: orderstatus,
        skip: 0,
        hasMoreItems: true,
        isLoading: true,
      },
      () => {
        this.initOrderList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handleSortingOrderFilter = (sort) => {
    this.setState(
      { sortingFilter: sort, skip: 0, hasMoreItems: true, isLoading: true },
      () => {
        this.initOrderList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  // onClickOfViewOrder = (order, index) => {
  //     return (<Link to={`/ordersummary/${order.id}`}></Link>);
  // }

  onClickOfCancelOrder = (order, index) => {
    const { isInquiry } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        const submitClick = (props) => {
          this.setState({ isLoading: true }, () => {
            this.cancelOrder(order, index).then(() => {
              this.setState({ isLoading: false });
            });
            onClose();
          });
        };
        return (
          <>
            <ConfirmAlertPopup
              title={
                !isInquiry
                  ? getValueFromLanguageObject("str_cancel_order")
                    ? getValueFromLanguageObject("str_cancel_order")
                    : "Cancel Order"
                  : getValueFromLanguageObject("str_cancel_inquiry")
                  ? getValueFromLanguageObject("str_cancel_inquiry")
                  : "Cancel Inquiry"
              }
              message={
                isInquiry
                  ? getValueFromLanguageObject(
                      "str_are_you_sure_you_want_to_cancel_inquiry"
                    )
                    ? getValueFromLanguageObject(
                        "str_are_you_sure_you_want_to_cancel_inquiry"
                      )
                    : "Are you sure you want to cancel inquiry"
                  : getValueFromLanguageObject(
                      "str_are_you_sure_you_want_to_cancel_order"
                    )
                  ? getValueFromLanguageObject(
                      "str_are_you_sure_you_want_to_cancel_order"
                    )
                  : "Are you sure you want to cancel order"
              }
              animation={<CancelOrderAnim />}
              submit={
                getValueFromLanguageObject("str_yes_cancel_it")
                  ? getValueFromLanguageObject("str_yes_cancel_it")
                  : "Yes, Cancel it!"
              }
              submitClick={submitClick}
              cancelClick={onClose}
              cancel={
                getValueFromLanguageObject("str_no")
                  ? getValueFromLanguageObject("str_no")
                  : "No"
              }
            />
          </>
        );
      },
    });
  };

  cancelOrder = async (order, index) => {
    const { isInquiry } = this.props;
    const cancelOrderUrl = `orders/cancelOrder`;
    const cancelOrderObj = {
      orderstatus: 5,
      orderId: order.id,
    };

    const cancelOrder = await patchCall(cancelOrderUrl, cancelOrderObj);
    if (cancelOrder.error) {
      this.loadErrorMessageAnimation(cancelOrder.error.message);
      if (cancelOrder.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_inquiry_is_successfully_cancel")
            ? getValueFromLanguageObject(
                "msg_your_inquiry_is_successfully_cancel"
              )
            : "Your inquiry is successfully cancelled"
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject(
            "str_order_has_been_cancelled_successfully"
          )
            ? getValueFromLanguageObject(
                "str_order_has_been_cancelled_successfully"
              )
            : "Order has been cancelled successfully!"
        );
      }

      this.initOrderList();
      // orderList[index].orderstatus = 5;
      // this.setState({ orderList });
    }
  };

  renderOrderStatus(item, orderstatus) {
    const { orderStatusList } = this.state;
    const statusItem = orderStatusList.find(
      (statusItem) => statusItem.id === orderstatus
    );
    let orderStatusClass = "";
    if (statusItem && statusItem.id) {
      orderStatusClass =
        orderstatus === statusItem.id && statusItem.status === "PENDING"
          ? "order-status-pending"
          : statusItem.id && statusItem.status === "CONFIRMED"
          ? "order-status-confirmed"
          : statusItem.id && statusItem.status === "INPROGRESS"
          ? "order-status-inprogress"
          : statusItem.id && statusItem.status === "DELIVERED"
          ? "order-status-delivered"
          : statusItem.id && statusItem.status === "CANCELLED"
          ? "order-status-cancelled"
          : "order-status-rejected";
    }

    return (
      <span className={`${orderStatusClass} order-status-color`}>
        {statusItem && statusItem.status ? statusItem.status : ""}
      </span>
    );
  }

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  fetchMoreItems = () => {
    const { orderList } = this.state;
    this.handlePagination(orderList.length);
  };

  handlePagination = (skip) => {
    this.setState({ skip }, () => {
      this.initOrderList();
    });
  };

  getTotalofCart(cartList) {
    let total = 0;
    if (cartList.orderdetail)
      for (let i = 0; i < cartList.orderdetail.length; i++) {
        total =
          total +
          cartList.orderdetail[i].amount * cartList.orderdetail[i].quantity;
      }
    return total;
  }

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharges) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharges;
      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharges;
      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharges;
    } else {
      grandTotal = total + igst + shippingCharges;
    }
    return grandTotal;
  };

  renderOrderCancelIcon = (item, index) => {
    const { orderStatusList } = this.state;
    return (
      <>
        {orderStatusList.map((statusItem) => {
          return statusItem.id === item.orderstatus &&
            (statusItem.status === "pending" ||
              statusItem.status === "PENDING") ? (
            <div className="icon">
              <a href onClick={() => this.onClickOfCancelOrder(item, index)}>
                <i className="fa fa-times" />
              </a>
            </div>
          ) : (
            <></>
          );
        })}
      </>
    );
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };
  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const { symbol } = this.props;
    const { isInquiry } = this.props;

    const {
      orderList,
      orderStatusList,
      hasMoreItems,
      isLoading,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>
            {AppTitle} |{" "}
            {isInquiry
              ? getValueFromLanguageObject("str_inquiry_page")
                ? getValueFromLanguageObject("str_inquiry_page")
                : "Inquiry Page"
              : getValueFromLanguageObject("str_order_page")
              ? getValueFromLanguageObject("str_order_page")
              : "Order Page"}
          </title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {isInquiry ? (
          <Breadcrumb
            title={
              getValueFromLanguageObject("str_inquiry")
                ? getValueFromLanguageObject("str_inquiry")
                : "Inquiry"
            }
          />
        ) : (
          <Breadcrumb
            title={
              getValueFromLanguageObject("str_orders")
                ? getValueFromLanguageObject("str_orders")
                : "Orders"
            }
          />
        )}

        <section className="cart-section section-b-space">
          <div className="container">
            <div className="collection-product-wrapper order-page-filter">
              <div className="product-top-filter">
                <div className="container-fluid p-0">
                  {/* <div className="row">
                                        <div className="col-xl-12">
                                            <div className="filter-main-btn">
                                                <span onClick={this.openFilter}
                                                    className="filter-btn btn btn-theme"><i
                                                        className="fa fa-filter"
                                                        aria-hidden="true"></i> Filter</span>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="row">
                    <div className="col-12 ">
                      <OrderFilterBar
                        orderList={orderList}
                        orderStatusList={orderStatusList}
                        handleOrderStatusChange={this.handleOrderStatusChange}
                        handleSortingOrderFilter={this.handleSortingOrderFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <InfiniteScroll
              dataLength={orderList.length} //This is important field to render the next data
              next={this.fetchMoreItems}
              hasMore={hasMoreItems}
              loader={<div className="loading-cls" />}
              // endMessage={
              //     <p className="seen-cls seen-it-cls">
              //         <b>Yay! You have seen it all</b>
              //     </p>
              // }
            >
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 p-0">
                    <div className="table-responsive">
                      <table className="table cart-table table-responsive-xs">
                        <thead>
                          {isInquiry ? (
                            <tr className="table-head">
                              <th scope="col">
                                {getValueFromLanguageObject("str_inquiry_no")
                                  ? getValueFromLanguageObject("str_inquiry_no")
                                  : "Inquiry No"}
                              </th>
                              <th scope="col">
                                {getValueFromLanguageObject("str_date")
                                  ? getValueFromLanguageObject("str_date")
                                  : "Date"}
                              </th>
                              <th scope="col">
                                {getValueFromLanguageObject("str_items")
                                  ? getValueFromLanguageObject("str_items")
                                  : "items"}
                              </th>
                              <th scope="col">
                                {getValueFromLanguageObject(
                                  "str_inquiry_status"
                                )
                                  ? getValueFromLanguageObject(
                                      "str_inquiry_status"
                                    )
                                  : "Inquiry Status"}
                              </th>
                              <th scope="col">
                                {getValueFromLanguageObject("str_action")
                                  ? getValueFromLanguageObject("str_action")
                                  : "action"}
                              </th>
                            </tr>
                          ) : (
                            <tr className="table-head">
                              <th scope="col">
                                {getValueFromLanguageObject("str_order_no")
                                  ? getValueFromLanguageObject("str_order_no")
                                  : "Order No"}
                              </th>
                              <th scope="col">
                                {getValueFromLanguageObject("str_date")
                                  ? getValueFromLanguageObject("str_date")
                                  : "Date"}
                              </th>
                              <th scope="col">
                                {getValueFromLanguageObject("str_items")
                                  ? getValueFromLanguageObject("str_items")
                                  : "items"}
                              </th>
                              <th scope="col">
                                {getValueFromLanguageObject("str_order_status")
                                  ? getValueFromLanguageObject(
                                      "str_order_status"
                                    )
                                  : "Order Status"}
                              </th>
                              <th scope="col">
                                {getValueFromLanguageObject("str_total")
                                  ? getValueFromLanguageObject("str_total")
                                  : "total"}
                              </th>
                              <th scope="col">
                                {getValueFromLanguageObject("str_action")
                                  ? getValueFromLanguageObject("str_action")
                                  : "action"}
                              </th>
                            </tr>
                          )}
                        </thead>
                        {orderList.map((item, index) => {
                          return (
                            <tbody key={index}>
                              <tr>
                                <td>
                                  <Link to={`/ordersummary/${item.id}`}>
                                    {item.orderno}
                                  </Link>
                                </td>
                                <td>
                                  <Moment format="DD-MMM-YYYY">
                                    {item.date}
                                  </Moment>
                                </td>
                                <td>
                                  <div className="qty-box">
                                    {item.orderdetail !== undefined
                                      ? item.orderdetail.length
                                      : 0}
                                  </div>
                                </td>

                                <td>
                                  {this.renderOrderStatus(
                                    item,
                                    item.orderstatus
                                  )}
                                </td>
                                {!isInquiry && (
                                  <td>
                                    <h2 className="td-color">
                                      {symbol}

                                      {parseFloat(
                                        this.getGrandTotal(
                                          this.getTotalofCart(item),
                                          this.getCGSTFromTotal(
                                            this.getTotalofCart(item)
                                          ),
                                          this.getSGSTFromTotal(
                                            this.getTotalofCart(item)
                                          ),
                                          this.getIGSTFromTotal(
                                            this.getTotalofCart(item)
                                          ),
                                          item.shippingprice
                                        )
                                      ).toFixed(2)}
                                    </h2>
                                  </td>
                                )}

                                <td>
                                  <div className="icon">
                                    {/* onClick={() => { this.onClickOfViewOrder(item, index) }} */}
                                    <Link to={`/ordersummary/${item.id}`}>
                                      <i className="fa fa-eye" />
                                    </Link>
                                  </div>
                                  {item.orderstatus &&
                                    this.renderOrderCancelIcon(item, index)}
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </InfiniteScroll>
          </div>
        </section>
        {orderList.length === 0 && <NoProductFoundOrderAnim />}

        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

// export default Orders;
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    isInquiryOrCart: isInquiryOrCart,
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
  }
)(Orders);
