import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import ProductItem from "./product-item";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../../store";
import { postCall, getCall } from "../../../api/post";
import { getCommonCounter, isInquiryOrCart } from "../../../actions";
import { Link } from "react-router-dom";
import {
  getPincodeDeliveryUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import NoSpecialProductFoundAnim from "../../../animation/NoSpecialProductFoundAnim";
import SpecialProductContentLoaderAnim from "../../../animation/SpecialProductContentLoaderAnim";
import AddToCartAnim from "../../../animation/AddToCartAnim";

class SpecialProducts extends Component {
  state = {
    isPincodeDeliveryEnable: false,
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    showAddToCartMessageModal: false,
    addToCartMessage: "",
    masterDetailsObj: {},
  };
  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initPincodeDelivery();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  addToCart = async (product, qty, isInquiry, pincode) => {
    try {
      this.props.handleLoader(true);
      const userDetails = loadUserDetailsFromLocalStorage();

      const addtocartUrl = isInquiry
        ? "orders/productInquiry"
        : "orders/addtocart";
      var addtocartObj = {
        orderstatus: 1,
        inshoppingcart: 1,
        userId: userDetails.id,
        orderdetails: [
          {
            quantity: qty,
            productId: product.id,
          },
        ],
        cityId: userDetails.cityId,
        date: new Date(),
      };
      if (!isInquiry) {
        const totalAmountObj = { totalamount: product.price * qty };
        addtocartObj = { ...addtocartObj, ...totalAmountObj };
      }

      const addToCartResponse = await postCall(addtocartUrl, addtocartObj);
      this.props.handleLoader(false);
      if (addToCartResponse.error) {
        this.loadErrorMessageAnimation(addToCartResponse.error.message);
        if (addToCartResponse.error.statusCode === 401) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("apiToken");
          this.props.history.push(`/login`);
          return;
        }
      } else {
        if (isInquiry) {
          this.loadAddToCartMessageAnimation(
            getValueFromLanguageObject("str_add_to_inquiry_successfully")
              ? getValueFromLanguageObject("str_add_to_inquiry_successfully")
              : "Product Inquiry Added Successfully"
          );
        } else {
          this.loadAddToCartMessageAnimation(
            getValueFromLanguageObject("str_add_to_cart_successfully")
              ? getValueFromLanguageObject("str_add_to_cart_successfully")
              : "Product Added to Cart Successfully."
          );
        }
      }
      this.props.counter();
    } catch (error) {
      this.props.handleLoader(false);
    }
  };

  initPincodeDelivery = async () => {
    const { masterDetailsObj } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const pincodeDelivery = await getCall(
      getPincodeDeliveryUrl(masterDetailsObj),
      []
    );
    if (pincodeDelivery.error) {
      this.loadErrorMessageAnimation(pincodeDelivery.error.message);
    } else {
      if (pincodeDelivery.length > 0) {
        const status = pincodeDelivery[0].status;
        this.setState({ isPincodeDeliveryEnable: status === 1 ? true : false });
      }
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  loadAddToCartMessageAnimation = (message) => {
    this.setState({
      addToCartMessage: message,
      showAddToCartMessageModal: true,
    });
  };

  render() {
    const {
      symbol,
      productsNewArrival,
      // productsFeature,
      productsBestSelling,
      // productsRecentlyViewed,
      isContentLoaderVisible,
    } = this.props;
    const {
      isPincodeDeliveryEnable,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      showAddToCartMessageModal,
      addToCartMessage,
    } = this.state;

    return (
      <>
        <section className="section-b-space ratio_square">
          <div className="container-fluid">
            <div className="title2">
              <h4>
                {getValueFromLanguageObject("str_new_collection")
                  ? getValueFromLanguageObject("str_new_collection")
                  : "New Collection"}
              </h4>
              <h2 className="title-inner2">
                {getValueFromLanguageObject("str_trending_products")
                  ? getValueFromLanguageObject("str_trending_products")
                  : "Trending Products"}
              </h2>
            </div>
            <div className="row">
              <div className="col">
                <div className="theme-tab layout7-product">
                  <Tabs className="theme-tab">
                    <TabList className="tabs tab-title">
                      <Tab>
                        {getValueFromLanguageObject("str_new_arrival")
                          ? getValueFromLanguageObject("str_new_arrival")
                          : "NEW ARRIVAL"}
                      </Tab>
                      <Tab>
                        {getValueFromLanguageObject("str_best_selling")
                          ? getValueFromLanguageObject("str_best_selling")
                          : "BEST SELLING"}
                      </Tab>
                      {/* <Tab>
                      {getValueFromLanguageObject("str_recently_viewed")
                        ? getValueFromLanguageObject("str_recently_viewed")
                        : "RECENTLY VIEWED"}
                    </Tab> */}
                    </TabList>

                    <TabPanel>
                      <div className="no-slider row">
                        {productsNewArrival
                          .slice(0, 8)
                          .map((product, index) => (
                            <ProductItem
                              product={product}
                              symbol={symbol}
                              onAddToCartClicked={(
                                product,
                                qty,
                                isInquiry,
                                pincode
                              ) => {
                                this.addToCart(
                                  product,
                                  qty,
                                  this.props.isInquiry,
                                  pincode
                                );
                              }}
                              isPincodeDeliveryEnable={isPincodeDeliveryEnable}
                              key={index}
                            />
                          ))}

                        {productsNewArrival.length === 0 &&
                          isContentLoaderVisible && (
                            <SpecialProductContentLoaderAnim />
                          )}

                        {productsNewArrival.length === 0 &&
                          !isContentLoaderVisible && (
                            <NoSpecialProductFoundAnim />
                          )}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="no-slider row">
                        {productsBestSelling
                          .slice(0, 8)
                          .map((product, index) => (
                            <ProductItem
                              product={product}
                              symbol={symbol}
                              onAddToCartClicked={(
                                product,
                                qty,
                                isInquiry,
                                pincode
                              ) =>
                                this.addToCart(
                                  product,
                                  qty,
                                  this.props.isInquiry,
                                  pincode
                                )
                              }
                              isPincodeDeliveryEnable={isPincodeDeliveryEnable}
                              key={index}
                            />
                          ))}
                        {productsBestSelling.length === 0 &&
                          isContentLoaderVisible && (
                            <SpecialProductContentLoaderAnim />
                          )}

                        {productsNewArrival.length === 0 &&
                          !isContentLoaderVisible && (
                            <NoSpecialProductFoundAnim />
                          )}
                      </div>
                    </TabPanel>

                    {/* <TabPanel>
                    <div className=" no-slider row">
                      {productsRecentlyViewed.slice(0, 8).map((product, index) => (
                        <ProductItem
                          product={product}
                          symbol={symbol}
                          onAddToCartClicked={(product, qty) => this.addToCart(product, qty, this.props.isInquiry)}
                          key={index}
                        />
                      ))}
                      {productsRecentlyViewed.length === 0 && <ContentLoaderMain />}
                    </div>
                  </TabPanel> */}
                    <div className="action-item">
                      <Link to={`/products`} className="btn btn-solid">
                        {getValueFromLanguageObject("str_view_all_products")
                          ? getValueFromLanguageObject("str_view_all_products")
                          : "View All Products"}
                      </Link>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </section>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
        {showAddToCartMessageModal && (
          <AddToCartAnim
            show={showAddToCartMessageModal}
            message={addToCartMessage}
            onClose={() => {
              this.setState({ showAddToCartMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
  symbol: getCurrencySymbol(),
});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    isInquiryOrCart: isInquiryOrCart,
  }
)(SpecialProducts);
