import React, { Component } from "react";
import Lottie from "react-lottie";
import Modal from "react-responsive-modal";
import specialProductContentLoaderAnimJSON from "./specialProductContentLoaderAnimJSON.json";
import { getValueFromLanguageObject } from "../store";

class SpecialProductContentLoaderAnim extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: specialProductContentLoaderAnimJSON,
    };
  }

  render() {
    return (
      <>
        <div className="col-12 d-flex justify-content-center">
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="animationSize-200-200"> 
                      <Lottie
                        options={this.defaultOptions}
                      />
                      <h3 className="mt-4">
                        <strong>
                          Please Wait...! We are fetching product for you.
                        </strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default SpecialProductContentLoaderAnim;
