import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getCall } from "../../../api/post";
import {
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
} from "../../../services";

class LogoImage extends Component {
  state = { profilepic: "", masterDetailsObj: {} };

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      this.renderLogoDynamic();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  renderLogoDynamic = async () => {
    const { masterDetailsObj } = this.state;
    let path = `${process.env.SERVER_URL}`;
    const logoUrl = getMerchantInformationUrl(masterDetailsObj);
    const logoResponse = await getCall(logoUrl, []);
    if (logoResponse.error) {
      return;
    } else {
      path = `${path}/api/containers/profilepic-${masterDetailsObj.codename}/download/${
        logoResponse.profilepic
      }`;
      this.setState({ profilepic: path });
    }
  };

  render() {
    return (
      <Link to={`/`}>
        <img
          src={this.state.profilepic}
          alt="Logo"
          className="img-fluid"
          onError={(e) => {
            e.target.onerror = null;
          }}
        />
      </Link>
    );
  }
}

export default LogoImage;
