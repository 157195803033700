import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store";
import { postCall, getCall } from "../../../api/post";
import { confirmAlert } from "react-confirm-alert";
import {
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import { isMobile } from "react-device-detect";
import { isUserAllowedToRegister } from "../../../actions";
import { connect } from "react-redux";
import ConfirmAlertPopup from "../../../Alert/ConfirmAlertPopup";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import LogoutAnim from "../../../animation/AnimFiles/LogoutAnim";

class TopBar extends Component {
  state = {
    isMyAccountVisible: false,
    contactUs: {},
    userId: "",
    AppTitle: "",
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
  };

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      this.initContactUs();
      const userDetails = loadUserDetailsFromLocalStorage();
      if (userDetails === undefined) {
        this.setState({ isMyAccountVisible: true });
        return;
      } else {
        this.setState({ userId: userDetails.id });
      }
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  onClickOfMyProfile = () => {
    this.props.history.push("/myprofile");
  };

  onClickOfLogOut = async () => {
    const submitClick = (props) => {
      this.setState({ isLoading: true }, () => {
        this.logout().then(() => {
          this.setState({ isLoading: false });
        });
        // onClose();
      });
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <>
            <ConfirmAlertPopup
              title={"Log Out"}
              message={"Are you sure you want to logout?"}
              animation={<LogoutAnim />}
              submit={"Yes, Logout!"}
              submitClick={submitClick}
              cancelClick={onClose}
              cancel={"No"}
            />
          </>
        );
      },
    });
  };

  logout = async () => {
    const logoutUrl = "users/logout";
    const logOut = await postCall(logoutUrl, {});

    if (logOut.error) {
      this.loadErrorMessageAnimation(logOut.error.message);
    } else {
      localStorage.removeItem("apiToken");
      localStorage.removeItem("userDetails");
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_you_are_successfully_logged_out")
          ? getValueFromLanguageObject("str_you_are_successfully_logged_out")
          : "You are successfully logged out!"
      );
      setTimeout(() => {
        window.location.reload();
      }, 700);
    }
  };

  initContactUs = async () => {
    const { masterDetailsObj } = this.state;
    const contantusUrl = getMerchantInformationUrl(masterDetailsObj);
    const contactUs = await getCall(contantusUrl, []);
    if (contactUs.error) {
      this.loadErrorMessageAnimation(contactUs.error.message);
      if (contactUs.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        // this.props.history.push("/login");
        return;
      }
    } else {
      this.setState({ contactUs, AppTitle: contactUs.companyname });
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      contactUs,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
    } = this.state;
    return (
      <>
        <div
          style={{ backgroundColor: getColorCodeBasedOnKey("bg_top_header") }}
          className="top-header"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="header-contact">
                  <ul>
                    <li
                      style={{
                        color: getColorCodeBasedOnKey("txt_top_header_color"),
                      }}
                    >
                      {`${
                        AppTitle
                          ? (getValueFromLanguageObject("str_welcome_to")
                              ? getValueFromLanguageObject("str_welcome_to")
                              : "Welcome To") +
                            " " +
                            AppTitle
                          : ""
                      }`}
                    </li>

                    {contactUs.cellnumber && (
                      <li>
                        <i className="fa fa-phone" aria-hidden="true" />
                        {getValueFromLanguageObject("str_call_us")
                          ? getValueFromLanguageObject("str_call_us")
                          : "Call Us"}
                        : {contactUs.cellnumber}
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 text-right">
                <ul className="header-dropdown">
                  <li
                    className="onhover-dropdown mobile-account"
                    style={{
                      color: getColorCodeBasedOnKey(
                        "txt_my_account_top_header"
                      ),
                      right:
                        isMobile && this.state.isMyAccountVisible ? "44%" : "",
                    }}
                  >
                    <i
                      className="fa fa-user"
                      style={{
                        fontFamily: isMobile ? "themify" : "FontAwesome",
                      }}
                      aria-hidden="true"
                    />{" "}
                    {getValueFromLanguageObject("str_my_account")
                      ? getValueFromLanguageObject("str_my_account")
                      : "My Account"}
                    {this.state.isMyAccountVisible && (
                      <ul className="onhover-show-div">
                        <li
                          style={{
                            color: getColorCodeBasedOnKey(
                              "txt_login_top_header"
                            ),
                          }}
                        >
                          <Link to={`/login`} data-lng="en">
                            {getValueFromLanguageObject("str_login")
                              ? getValueFromLanguageObject("str_login")
                              : "Login"}
                          </Link>
                        </li>
                        <li
                          style={{
                            color: getColorCodeBasedOnKey(
                              "txt_register_top_header"
                            ),
                          }}
                        >
                          <Link to={`/signup`} data-lng="en">
                            {getValueFromLanguageObject("str_register")
                              ? getValueFromLanguageObject("str_register")
                              : "Register"}
                          </Link>
                        </li>
                      </ul>
                    )}
                    {!this.state.isMyAccountVisible && (
                      <ul className="onhover-show-div">
                        <li
                          style={{
                            color: getColorCodeBasedOnKey(
                              "txt_my_profile_top_header"
                            ),
                          }}
                          onClick={() => {
                            this.onClickOfMyProfile();
                          }}
                        >
                          <Link to="#">
                            {getValueFromLanguageObject("str_my_profile")
                              ? getValueFromLanguageObject("str_my_profile")
                              : "My Profile"}
                          </Link>
                        </li>
                        <li
                          style={{
                            color: getColorCodeBasedOnKey(
                              "txt_logout_top_header"
                            ),
                          }}
                          onClick={() => {
                            this.onClickOfLogOut();
                          }}
                        >
                          <Link to="#">
                            {getValueFromLanguageObject("str_logout")}
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isRegisterAllow: state.settings.isRegisterAllow,
});
export default connect(
  mapStateToProps,
  { isUserAllowedToRegister: isUserAllowedToRegister }
)(withTranslate(withRouter(TopBar)));
