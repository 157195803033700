import React, { Component } from "react";
import Lottie from "react-lottie";
import mainLoaderJSON from "./mainLoaderJSON.json";

class MainLoader extends Component {
  defaultOptions = {};
  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: mainLoaderJSON,
    };
  }

  render() {
    return (
      <>
        <div className="loader-wrapper loader d-flex justify-content-center animationSize-200-200">
          <Lottie
            options={this.defaultOptions}
          />
        </div>
      </>
    );
  }
}

export default MainLoader;
