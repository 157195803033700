import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../../common/index.scss";
import Slider from "react-slick";

// Import custom components
import Header from "../header/header";
import SpecialProducts from "./special-products";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../../store/index";
import { getCall } from "../../../api/post";
import MainFooter from "../../layouts/footer/main_footer";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getOfferBannerUrl,
  getMerchantInformationUrl,
  getCatalogueJewellaryUrl,
  getMasterDetailsIdUrl,
  getPlaceHolderBanner,
} from "../../../services";
import NotificationList from "../notifications/notification-list";
import Drawer from "@material-ui/core/Drawer";
import { connect } from "react-redux";
import { getCommonCounter, getNotificationCounter } from "../../../actions";
import { NotificationContainer } from "react-notifications";

import ThemeSettings from "../../common/theme-settings";
import { isMobile } from "react-device-detect";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";

const notificationHeaderStyle = {
  textTransform: "uppercase",
  fontSize: "20px",
  padding: "15px",
  fontWeight: "400",
  color: "#222222",
  backgroundColor: "#F0F0F0",
};

class Dashboard extends Component {
  state = {
    productsNewArrival: [],
    productsFeature: [],
    productsBestSelling: [],
    productsRecentlyViewed: [],
    isDrawerVisible: false,
    isLoading: true,
    offerBanners: [],
    AppTitle: "",
    currentCatelogueType: {},
    showErrorMessageModal: false,
    errorMessage: "",
    isContentLoaderVisible: false,
    masterDetailsObj: {},
  };

  componentDidMount() {
    // document
    //   .getElementById("color")
    //   .setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color7.css`);

    this.initMasterDetailsId().then(() => {
      const userDetails = loadUserDetailsFromLocalStorage();
      if (userDetails === undefined) {
        this.props.history.push(`/login`);
        return;
      }
      this.initProductListNewArrival();
      this.initProductListFeature();
      this.initMerchantInfo();
      this.setState({ isContentLoaderVisible: true }, () => {
        this.initProductListBestSelling().then(() => {
          this.setState({ isLoading: false }, () => {
            setTimeout(() => {
              this.setState({ isContentLoaderVisible: false });
            }, 1500);
          });
        });
      });
      this.initOfferBanner();
      this.initCatelogueSettings();
      this.initProductListRecentlyViewed();
      this.props.notificationCounter();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initProductListNewArrival = async () => {
    const productListUrl =
      "products?filter[include]=productbrand&filter[include]=productmedia&filter[order][0]=created DESC&filter[include]=category&filter[where][productstatus]=1&filter[where][availablequantity][gt]=0&filter[skip]=0&filter[limit]=20";

    const products = await getCall(productListUrl, []);
    if (products.error) {
      this.loadErrorMessageAnimation(products.error.message);
      if (products.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ productsNewArrival: products });
    }
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.loadErrorMessageAnimation(merchantInfo.error.message);
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      this.loadErrorMessageAnimation(settingInfo.error.message);
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      if (settings && settings.registerallow === "Catalogue_Jewellary") {
        this.initGoldPriceJwelleryCatelogue();
      }
    }
  };

  initProductListFeature = async () => {
    const productListUrl =
      "products?filter[include]=productbrand&filter[include]=productmedia&filter[include]=category&filter[where][productstatus]=1&filter[where][availablequantity][gt]=0&filter[skip]=0&filter[limit]=20";

    const products = await getCall(productListUrl, []);
    if (products.error) {
      this.loadErrorMessageAnimation(products.error.message);
      if (products.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ productsFeature: products });
    }
  };

  // Best Selling
  initProductListBestSelling = async () => {
    const productListUrl =
      "products?filter[include]=productbrand&filter[include]=productmedia&filter[include]=category&filter[where][productstatus]=1&filter[where][availablequantity][gt]=0&filter[order][0]=sellcounter DESC&filter[skip]=0&filter[limit]=20";

    const products = await getCall(productListUrl, []);
    if (products.error) {
      this.loadErrorMessageAnimation(products.error.message);
      if (products.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ productsBestSelling: products });
    }
  };

  //  TODO Change Url Recently viewed product once done by back-end SideBar, currently same as best selling
  // Recently Viewed
  initProductListRecentlyViewed = async () => {
    const productListUrl =
      "products?filter[include]=productbrand&filter[include]=productmedia&filter[include]=category&filter[where][productstatus]=1&filter[where][availablequantity][gt]=0&filter[order][0]=sellcounter DESC&filter[skip]=0&filter[limit]=20";

    const products = await getCall(productListUrl, []);
    if (products.error) {
      this.loadErrorMessageAnimation(products.error.message);
      if (products.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ productsRecentlyViewed: products });
    }
  };

  initOfferBanner = async () => {
    try {
      const { masterDetailsObj } = this.state;
      const offerBannerUrl = getOfferBannerUrl(masterDetailsObj);
      const offerBanners = await getCall(offerBannerUrl, []);
      console.log("offerBanners");
      console.log(offerBanners);
      if (offerBanners.error) {
        this.loadErrorMessageAnimation(offerBanners.error.message);
        if (offerBanners.error.statusCode === 401) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("apiToken");
          this.props.history.push(`/login`);
          return;
        }
      } else {
        const banner = offerBanners.map((banner) => {
          return banner.media.length > 0 ? banner.media[0].image : "";
        });
        this.setState({ offerBanners: banner });
      }
    } catch (error) {
      this.setState({ offerBanners: [] });
    }
  };

  initGoldPriceJwelleryCatelogue = async () => {
    const { masterDetailsObj } = this.state;
    const goldPriceUrl = getCatalogueJewellaryUrl(masterDetailsObj);
    const goldPrice = await getCall(goldPriceUrl, []);
    if (goldPrice.error) {
      this.loadErrorMessageAnimation(goldPrice.error.message);
    } else {
      if (goldPrice.length > 0 && goldPrice[0].status === 1) {
        this.setState({ goldPrice: goldPrice[0].text });
      }
    }
  };

  handleNotificicationDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({ isDrawerVisible: !isDrawerVisible });
  };

  onClickOnSettingsIcon = () => {
    this.props.history.push(`/settings`);
  };

  onCloseofNotificationDrawer = () => {
    this.setState({ isDrawerVisible: false });
    this.props.notificationCounter();
  };

  handleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      isDrawerVisible,
      isLoading,
      offerBanners,
      goldPrice,
      AppTitle,
      currentCatelogueType,
      showErrorMessageModal,
      errorMessage,
      isContentLoaderVisible,
      productsNewArrival,
      productsFeature,
      masterDetailsObj,
    } = this.state;
    const { symbol } = this.props;
    return (
      <>
        <div className="container-fluid layout-8">
          <NotificationContainer />
          <Helmet>
            <title>
              {getValueFromLanguageObject("str_welcome_to")
                ? getValueFromLanguageObject("str_welcome_to")
                : "Welcome To"}{" "}
              {AppTitle}
            </title>
          </Helmet>
          <Header
            handleNotificicationDrawer={this.handleNotificicationDrawer}
            onClickOnSettingsIcon={this.onClickOnSettingsIcon}
          />
          <section className="p-0 padding-bottom-cls">
            <Slider className="slide-1 home-slider">
              {offerBanners &&
                offerBanners.length > 0 &&
                offerBanners.map((banner, i) => {
                  return (
                    <div key={i}>
                      <div
                        className="home"
                        style={{
                          backgroundImage: `url(${getImageUrl(
                            banner,
                            masterDetailsObj
                          )})`,
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0%",
                          width: "100%",
                        }}
                      >
                        <div className="slider-contain">
                          <button
                            onClick={() => this.props.history.push("/products")}
                            className="btn btn-outline btn-classic"
                            style={{
                              marginLeft: "25px",
                            }}
                          >
                            {getValueFromLanguageObject("str_shop_now")
                              ? getValueFromLanguageObject("str_shop_now")
                              : "Shop Now"}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {offerBanners.length === 0 && (
                <div>
                  <div className="home">
                    <img
                      style={{ position: "absolute", width: "100%" }}
                      src={getPlaceHolderBanner()}
                      alt=""
                      className="img-fluid"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = getPlaceHolderImage();
                      }}
                    />
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <div className="slider-contain">
                            <div>
                              <button
                                onClick={() =>
                                  this.props.history.push("/products")
                                }
                                className="btn btn-outline btn-classic"
                              >
                                {getValueFromLanguageObject("str_shop_now")
                                  ? getValueFromLanguageObject("str_shop_now")
                                  : "Shop Now"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Slider>
          </section>

          <Drawer
            anchor="right"
            open={isDrawerVisible}
            onClose={this.onCloseofNotificationDrawer}
          >
            <h3 style={notificationHeaderStyle}>
              {isMobile && (
                <i
                  onClick={() => {
                    this.onCloseofNotificationDrawer();
                  }}
                  style={{ padding: "10px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                />
              )}
              {getValueFromLanguageObject("str_notification")
                ? getValueFromLanguageObject("str_notification")
                : "Notifications"}
            </h3>
            <NotificationList />
          </Drawer>

          <div
            className="layout-8-bg"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL
              }/assets/images/electronics/bg.jpg)`,
            }}
          >
            {/* <section className="banner-goggles ratio2_3">
            <div className="container-fluid">
              <div className="row partition3">
                {this.state.productsFeature
                  .slice(0, 3)
                  .map((product, index) => (
                    <div
                      key={index}
                      className="col-md-4"
                      onClick={() =>
                        this.props.history.push(`/singleproduct/${product.id}`)
                      }
                    >
                      <div className="collection-banner">
                        <div className="img-part">
                          <img
                            src={
                              product.productmedia.length > 0
                                ? getImageUrl(
                                    product.productmedia[0].productname
                                  )
                                : getPlaceHolderImage()
                            }
                            className="img-fluid blur-up lazyload bg-img"
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                getPlaceHolderImage();
                            }}
                          />
                        </div>
                        <div className="contain-banner banner-3">
                          <div>
                            <h4>{product.name}</h4>
                            <h2>
                              {"₹"}
                              {product.price}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {this.state.productsFeature.length === 0 && (
                  <div className="col-md-4">
                    <ContentLoaderMain />
                  </div>
                )}
              </div>
            </div>
          </section> */}

            {currentCatelogueType &&
              currentCatelogueType.registerallow === "Catalogue_Jewellary" && (
                <section className="banner-goggles ratio2_3 fixed-price">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-2">&nbsp;</div>
                      <div className="col-md-8">
                        <h3 className="title text-center">
                          Today's Gold/Silver Rate
                        </h3>

                        <div className="row">
                          {goldPrice &&
                            goldPrice.length > 0 &&
                            goldPrice.map((priceTag) => {
                              return (
                                <div className="col-md-4">
                                  <h4 className="current-price">
                                    <b>
                                      {symbol}
                                      {parseFloat(priceTag.price).toFixed(2)}
                                    </b>
                                    {priceTag.name}
                                  </h4>
                                </div>
                              );
                            })}
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

            <SpecialProducts
              productsNewArrival={productsNewArrival}
              productsBestSelling={productsFeature}
              isContentLoaderVisible={isContentLoaderVisible}
              productsRecentlyViewed={this.state.productsRecentlyViewed}
              handleLoader={this.handleLoader}
              type={"electronics"}
            />
          </div>

          <div className="footer-white">
            <MainFooter />
          </div>
          <ThemeSettings />
        </div>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
  }
)(Dashboard);
// export default Dashboard;
