export const GooglePayConfigure = ({
  merchantId,
  merchantName,
  amount,
  gateway,
  gatewayMerchantId,
}) => {
  return {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: [
            "AMEX",
            "DISCOVER",
            "INTERAC",
            "JCB",
            "MASTERCARD",
            "VISA",
          ],
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: gateway,
            gatewayMerchantId: gatewayMerchantId,
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: merchantId,
      merchantName: merchantName,
    },
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPriceLabel: "Total",
      totalPrice: amount,
      currencyCode: "INR",
      countryCode: "IN",
    },
  };
};
