import React, { Component, useRef, useState } from "react";

import Breadcrumb from "../../common/breadcrumb";
import { postCall, getCall } from "../../../api/post";
import {
  saveUserDetailsToLocalStorage,
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { connect } from "react-redux";
import { isUserAllowedToRegister } from "../../../actions";
import {
  getCitiesUrl,
  getSignupOptionsUrl,
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
  getSettingUrl,
} from "../../../services";
import { Helmet } from "react-helmet";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
// import OtpInput from 'react-otp-input';

class Register extends Component {
  state = {
    userName: "",
    cellNumber: "",
    companyName: "",
    emailId: "",
    cityId: "",
    gstin: "",
    cities: [],
    otp: "",
    isLoginButtonClicked: false,
    remainingTime: 60,
    isLoading: true,
    showErrorMessageModal: false,
    errorMessage: "",
    masterDetailsObj: {},
  };

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isUserAllowedToRegister(masterDetailsObj);
      this.checkIsUserAllowToRegister();
      this.initCities().then(() => {
        this.setState({ isLoading: false });
      });
      this.initSettings();
      this.initMerchantInfo();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []);
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  checkIsUserAllowToRegister = async () => {
    const { masterDetailsObj } = this.state;
    let isRegisterAllow = false;
    const userDetails = loadUserDetailsFromLocalStorage();
    const settingUrl = getSettingUrl(masterDetailsObj);
    const setting = await getCall(settingUrl, []);
    if (setting.error) {
      this.loadErrorMessageAnimation(setting.error.message);
    } else {
      for (let i = 0; i < setting.length; i++) {
        if (setting[i].registerallow === "ALLOW") {
          if (setting[i].status === 1) {
            isRegisterAllow = true;
          }
        }
      }
    }
    if (userDetails === undefined && !isRegisterAllow) {
      this.props.history.push("/login");
    } else {
      this.props.history.push("/signup");
    }
    if (userDetails !== undefined && !isRegisterAllow) {
      this.props.history.push("/login");
    }
  };

  initCities = async () => {
    const { masterDetailsObj } = this.state;
    const cities = await getCall(getCitiesUrl(masterDetailsObj), []);
    this.setState({ cities: cities });
  };

  initSettings = async () => {
    const { masterDetailsObj } = this.state;
    const signupOptions = await getCall(
      getSignupOptionsUrl(masterDetailsObj),
      []
    );

    if (signupOptions.error) {
    } else {
      if (signupOptions.length > 0) {
        this.setState({ signupOptions: JSON.parse(signupOptions[0].text) });
      }
    }
  };

  onClickOfCreateAccount = () => {
    const {
      userName,
      cellNumber,
      cityId,
      emailId,
      gstin,
      signupOptions,
    } = this.state;
    const { isRegisterAllow } = this.props;
    if (!isRegisterAllow) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("str_you_are_not_allowed_to_register")
          ? getValueFromLanguageObject("str_you_are_not_allowed_to_register")
          : "You are not allowed to register!"
      );
      return;
    }

    if (
      signupOptions[0].field_name === "Name" &&
      signupOptions[0].mandatory === 1 &&
      signupOptions[0].visible === 1 &&
      (userName.trim().length < 3 || userName.trim().length === 0)
    ) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("str_please_enter_valid_name")
          ? getValueFromLanguageObject("str_please_enter_valid_name")
          : "Please Enter Valid Name."
      );
      return;
    }

    if (
      signupOptions[5].field_name === "Mobile Number" &&
      signupOptions[5].mandatory === 1 &&
      signupOptions[5].visible === 1 &&
      cellNumber.trim().length !== 10
    ) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("msg_enter_mobile_number_incorrect")
          ? getValueFromLanguageObject("msg_enter_mobile_number_incorrect")
          : "Please enter correct mobile number."
      );
      return;
    }
    if (
      signupOptions[4].field_name === "City" &&
      signupOptions[4].mandatory === 1 &&
      signupOptions[4].visible === 1 &&
      cityId.trim().length === 0
    ) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("str_please_select_city")
          ? getValueFromLanguageObject("str_please_select_city")
          : "Please Select City."
      );
      return;
    }

    if (
      (signupOptions[1].field_name === "Email" &&
        signupOptions[1].mandatory === 1 &&
        signupOptions[1].visible === 1) ||
      emailId.trim().length > 0
    ) {
      const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (!re.test(emailId)) {
        this.loadErrorMessageAnimation(
          getValueFromLanguageObject("str_please_enter_valid_email_address")
            ? getValueFromLanguageObject("str_please_enter_valid_email_address")
            : "Please Enter Valid Email Address."
        );
        return;
      }
    }

    if (
      (signupOptions[2].field_name === "GSTIN" &&
        signupOptions[2].mandatory === 1 &&
        signupOptions[2].visible === 1) ||
      gstin.trim().length > 0
    ) {
      // if (gstin.trim().length > 0) {
      const re = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
      if (!re.test(gstin)) {
        this.loadErrorMessageAnimation(
          getValueFromLanguageObject("str_please_enter_valid_gst_number")
            ? getValueFromLanguageObject("str_please_enter_valid_gst_number")
            : "Please Enter Valid GST Number."
        );
        return;
      }
    }

    this.setState({ isLoading: true }, () => {
      this.signUp().then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  signUp = async () => {
    const { masterDetailsObj } = this.state;
    if (!masterDetailsObj.id) {
      return;
    }
    const signUpObj = {
      cellnumber: this.state.cellNumber,
      userstatus: "ACTIVE",
      registervia: "ALLOW",
      devicetoken: "",
      roleId: 2,
      cityId: this.state.cityId,
      companyname: this.state.companyName,
      username: this.state.userName,
      gstin: this.state.gstin,
      email: this.state.email,
      isregistered: false,
      masterdetailId: masterDetailsObj.id,
    };
    const signUp = await postCall("users", signUpObj);
    if (signUp.error) {
      this.loadErrorMessageAnimation(signUp.error.message);
    } else {
      if (signUp && signUp.id !== null) {
        localStorage.setItem("apiToken", signUp.apitoken);
        this.setState(
          {
            userId: signUp.id,
            apiToken: signUp.apitoken,
            isLoginButtonClicked: true,
          },
          async () => {
            this.setState({ isLoading: true }, () => {
              this.getOtp().then(() => {
                this.setState({ isLoading: false });
              });
            });
          }
        );
      } else {
        this.setState({ isLoginButtonClicked: false });
      }
    }
  };

  getOtp = async () => {
    const getOtpUrl = `usermetaauths/sendOtp?userId=${
      this.state.userId
    }&receiverType=SMS&type=signupotp`;
    await getCall(getOtpUrl, []);
  };

  onClickOfOTPLogin = () => {
    if (this.state.otp.trim().length === 0) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("str_please_enter_valid_otp")
          ? getValueFromLanguageObject("str_please_enter_valid_otp")
          : "Please enter valid OTP"
      );
    } else {
      this.setState({ isLoading: true }, () => {
        this.verifyOtp().then(() => {
          this.setState({ isLoading: false });
        });
      });
    }
  };

  verifyOtp = async () => {
    const verifyOtpUrl = "usermetaauths/verifyOtp";
    const verifyOtpObj = {
      signupotp: this.state.otp,
      userId: this.state.userId,
      devicetoken: "",
    };
    const verifyOtp = await postCall(verifyOtpUrl, verifyOtpObj);
    if (verifyOtp.error) {
      this.loadErrorMessageAnimation(verifyOtp.error.message);
    } else {
      // store all the user data to localstorage
      localStorage.setItem("apiToken", verifyOtp.apitoken);
      saveUserDetailsToLocalStorage(verifyOtp);
      this.props.history.push("/");
    }
  };

  resendOtp = () => {
    this.setState({ isLoading: true }, () => {
      this.getOtp().then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  handleChangeOfUserName = (event) => {
    this.setState({ userName: event.target.value });
  };

  handleChangeOfCellNumber = (event) => {
    const number = event.target.value.trim();

    if (!isNaN(number)) {
      this.setState({ cellNumber: number });
    } else {
      return;
    }
  };

  handleChangeOfCompanyName = (event) => {
    this.setState({ companyName: event.target.value });
  };

  handleChangeOfCity = (event) => {
    this.setState({ cityId: event.target.value });
  };

  handleChangeOfEmailId = (event) => {
    this.setState({ emailId: event.target.value });
  };

  handleChangeOfGSTIN = (event) => {
    this.setState({ gstin: event.target.value });
  };
  handleChangeOfOTPNumber = (event) => {
    this.setState({ otp: event.target.value });
    // this.setState({ otp });
  };

  onClickOfResendOtp = () => {
    this.setState({ remainingTime: 60 });
    this.resendOtp();
  };

  renderTime = ({ remainingTime }) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
      isNewTimeFirstTick.current = true;
      prevTime.current = currentTime.current;
      currentTime.current = remainingTime;
      this.setState({ remainingTime });
    } else {
      isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
      setTimeout(() => {
        setOneLastRerender((val) => val + 1);
      }, 20);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
      <div className="time-wrapper">
        <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
          {remainingTime}
        </div>
      </div>
    );
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.setState({
        AppTitle: "",
      });
    } else {
      this.setState({
        AppTitle: merchantInfo.companyname,
      });
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      isLoginButtonClicked,
      signupOptions,
      isLoading,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
    } = this.state;
    const { cities = [] } = this.state;
    return (
      <>
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>{AppTitle} | Create an account</title>
            <meta
              name="description"
              content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
            />
          </Helmet>
          {/*SEO Support End */}
          <Breadcrumb
            title={
              getValueFromLanguageObject("str_create_account")
                ? getValueFromLanguageObject("str_create_account")
                : "Create an Account"
            }
          />

          {/*Regsiter section*/}
          <section className="register-page section-b-space">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <h3
                    style={{
                      color: getColorCodeBasedOnKey("text_create_an_account"),
                    }}
                  >
                    {getValueFromLanguageObject("str_create_account")
                      ? getValueFromLanguageObject("str_create_account")
                      : "Create an Account"}
                  </h3>
                  <div className="theme-card">
                    {!isLoginButtonClicked && (
                      <form className="theme-form">
                        <div className="form-row">
                          {signupOptions &&
                            signupOptions.length > 0 &&
                            signupOptions[0].visible === 1 && (
                              <div className="col-md-12">
                                <label
                                  htmlFor="username"
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_name_signup"
                                    ),
                                  }}
                                >
                                  {getValueFromLanguageObject("str_name")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  placeholder={
                                    getValueFromLanguageObject(
                                      "str_enter_your_name"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_enter_your_name"
                                        )
                                      : "Enter Your name"
                                  }
                                  required={
                                    signupOptions[0].mandatory === 1
                                      ? true
                                      : false
                                  }
                                  maxLength="30"
                                  minLength="2"
                                  value={this.state.userName}
                                  onChange={this.handleChangeOfUserName}
                                />
                              </div>
                            )}

                          {signupOptions &&
                            signupOptions.length > 0 &&
                            signupOptions[5].visible === 1 && (
                              <div className="col-md-12">
                                <label htmlFor="cellnumber">
                                  {getValueFromLanguageObject("mobile_number")
                                    ? getValueFromLanguageObject(
                                        "mobile_number"
                                      )
                                    : "Mobile number"}
                                </label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  id="cellnumber"
                                  placeholder={
                                    getValueFromLanguageObject(
                                      "str_enter_mobile_number"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_enter_mobile_number"
                                        )
                                      : "Enter Mobile Number"
                                  }
                                  required={
                                    signupOptions[5].mandatory === 1
                                      ? true
                                      : false
                                  }
                                  maxLength="10"
                                  pattern="[0-9]{10}"
                                  // pattern="/^\?([0-9]{10})$/"
                                  value={this.state.cellNumber}
                                  onChange={this.handleChangeOfCellNumber}
                                />
                              </div>
                            )}
                          {/* </div> */}
                          {/* <div className="form-row"> */}
                          {signupOptions &&
                            signupOptions.length > 0 &&
                            signupOptions[3].visible === 1 && (
                              <div className="col-md-12">
                                <label htmlFor="companyname">
                                  {getValueFromLanguageObject(
                                    "str_company_name"
                                  )}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="companyname"
                                  placeholder={
                                    getValueFromLanguageObject(
                                      "str_enter_company_name"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_enter_company_name"
                                        )
                                      : "Enter Company Name"
                                  }
                                  required={
                                    signupOptions[3].mandatory === 1
                                      ? true
                                      : false
                                  }
                                  minLength="2"
                                  maxLength="30"
                                  value={this.state.companyName}
                                  onChange={this.handleChangeOfCompanyName}
                                />
                              </div>
                            )}
                          {signupOptions &&
                            signupOptions.length > 0 &&
                            signupOptions[4].visible === 1 && (
                              <div className="col-md-12">
                                <label htmlFor="city">
                                  {getValueFromLanguageObject("str_city")
                                    ? getValueFromLanguageObject("str_city")
                                    : "City"}
                                </label>
                                <select
                                  className="form-control custom-city"
                                  style={{ height: "54px" }}
                                  name="city"
                                  required={
                                    signupOptions[4].mandatory === 1
                                      ? true
                                      : false
                                  }
                                  placeholder={
                                    getValueFromLanguageObject(
                                      "str_select_city"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_select_city"
                                        )
                                      : "Select City"
                                  }
                                  value={this.state.cityId}
                                  onChange={this.handleChangeOfCity}
                                >
                                  <option>
                                    {getValueFromLanguageObject(
                                      "str_select_city"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_select_city"
                                        )
                                      : "Select City"}
                                  </option>
                                  {cities.map((city, index) => (
                                    <option key={index} value={city.id}>
                                      {city.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          {/* </div> */}

                          {/* <div className="form-row"> */}
                          {signupOptions &&
                            signupOptions.length > 0 &&
                            signupOptions[1].visible === 1 && (
                              <div className="col-md-12">
                                <label htmlFor="emailId">
                                  {getValueFromLanguageObject(
                                    "str_email_address"
                                  )
                                    ? getValueFromLanguageObject(
                                        "str_email_address"
                                      )
                                    : "Email Address"}
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="emailId"
                                  placeholder={
                                    getValueFromLanguageObject(
                                      "str_enter_email_address"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_enter_email_address"
                                        )
                                      : "Enter Email Address"
                                  }
                                  required={
                                    signupOptions[1].mandatory === 1
                                      ? true
                                      : false
                                  }
                                  minLength="5"
                                  pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
                                  maxLength="64"
                                  value={this.state.emailId}
                                  onChange={this.handleChangeOfEmailId}
                                />
                              </div>
                            )}
                          {signupOptions &&
                            signupOptions.length > 0 &&
                            signupOptions[2].visible === 1 && (
                              <div className="col-md-12">
                                <label
                                  htmlFor="gstin"
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_gstin_signup"
                                    ),
                                  }}
                                >
                                  {getValueFromLanguageObject("str_gstin")
                                    ? getValueFromLanguageObject("str_gstin")
                                    : "GSTIN"}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="gstin"
                                  placeholder={
                                    getValueFromLanguageObject(
                                      "str_enter_gstin"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_enter_gstin"
                                        )
                                      : "Enter GSTIN"
                                  }
                                  required={
                                    signupOptions[2].mandatory === 1
                                      ? true
                                      : false
                                  }
                                  value={this.state.gstin}
                                  onChange={this.handleChangeOfGSTIN}
                                />
                              </div>
                            )}
                        </div>

                        <div className="form-row">
                          <div
                            onClick={() => {
                              this.onClickOfCreateAccount();
                            }}
                            className="btn btn-solid"
                          >
                            {getValueFromLanguageObject("str_create_account")
                              ? getValueFromLanguageObject("str_create_account")
                              : "Create Account"}
                          </div>
                        </div>
                      </form>
                    )}
                    {isLoginButtonClicked && (
                      <form className="theme-form">
                        <div className="form-group">
                          <label htmlFor="otp">
                            {getValueFromLanguageObject("str_otp")
                              ? getValueFromLanguageObject("str_otp")
                              : "OTP"}
                          </label>
                          {/* <OtpInput
                            className="otp-field"
                            id="otp"
                            value={this.state.otp}
                            onChange={this.handleChangeOfOTPNumber}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                            separator={<span>-</span>}
                            htmlAttrs={{
                              autocomplete: 'off',
                              name: 'number'
                        }}
                        isInputSecure
                            inputStyle={{
                              width: "3rem",
                              height: "3rem",
                              margin: "20px 1rem",
                              fontSize: "2rem",
                              borderRadius: 4,
                              border: "2px solid rgba(0,0,0,0.3)",
                            }} /> */}
                          <input
                            type="number"
                            className="form-control"
                            id="otp"
                            onChange={this.handleChangeOfOTPNumber}
                            placeholder={
                              getValueFromLanguageObject(
                                "str_enter_otp_to_verify_your_account"
                              )
                                ? getValueFromLanguageObject(
                                    "str_enter_otp_to_verify_your_account"
                                  )
                                : "Enter OTP to verify your account"
                            }
                            required={true}
                          />
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                          <div
                            onClick={() => {
                              this.onClickOfOTPLogin();
                            }}
                            className="btn btn-solid"
                          >
                            {getValueFromLanguageObject("str_sign_up")
                              ? getValueFromLanguageObject("str_sign_up")
                              : "Sign Up"}
                          </div>
                          <div>
                            {this.state.remainingTime !== 0 && (
                              <CountdownCircleTimer
                                size={50}
                                isPlaying
                                duration={this.state.remainingTime}
                                colors={[
                                  ["#004777", 0.33],
                                  ["#F7B801", 0.33],
                                  ["#A30000"],
                                ]}
                              >
                                {this.renderTime}
                              </CountdownCircleTimer>
                            )}
                            {this.state.remainingTime === 0 && (
                              <div>
                                <h6>
                                  {getValueFromLanguageObject(
                                    "str_dont_receive_otp_with_question_mark"
                                  )
                                    ? getValueFromLanguageObject(
                                        "str_dont_receive_otp_with_question_mark"
                                      )
                                    : "Don't receive OTP?"}
                                </h6>
                                <h5
                                  className="resend-otp pb-0 mb-0"
                                  onClick={() => {
                                    this.onClickOfResendOtp();
                                  }}
                                >
                                  {getValueFromLanguageObject("str_resend_otp")
                                    ? getValueFromLanguageObject(
                                        "str_resend_otp"
                                      )
                                    : "Resend OTP"}
                                </h5>
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isRegisterAllow: state.settings.isRegisterAllow,
});

export default connect(
  mapStateToProps,
  { isUserAllowedToRegister: isUserAllowedToRegister }
)(Register);
