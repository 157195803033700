import React, { Component } from "react";
import { IntlActions } from "react-redux-multilingual";
import Pace from "react-pace-progress";
import store, {
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../../store";
import NavBar from "../navbar/navbar";
import CartContainer from "../../../containers/CartContainer";
import TopBar from "../topbar/topbar";
import { changeCurrency } from "../../../actions";
import { connect } from "react-redux";
import LogoImage from "../header/logo";

class Header extends Component {
  state = { isDrawerVisible: false };
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  /*=====================
			 Pre loader
			 ==========================*/
  componentDidMount() {
    // setTimeout(function () {
    //   document.querySelector(".loader-wrapper").style = "display: none";
    // }, 2000);
    // getNotificationCounter().then((counter) => {
    //   this.setState({ notificationCounter: counter });
    // });
  }

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  }
  // openSearch() {
  //   document.getElementById("search-overlay").style.display = "block";
  // }

  // closeSearch() {
  //   document.getElementById("search-overlay").style.display = "none";
  // }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  UNSAFE_componentWillUpdate() {}

  render() {
    const { notificationCounter, onClickOnSettingsIcon } = this.props;
    return (
      <div>
        <header>
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option" />
          {/*Top Header Component*/}
          <TopBar />

          <div
            className="container-fluid"
            style={{
              backgroundColor: getColorCodeBasedOnKey("bg_container_header"),
            }}
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu">
                  <div className="menu-left">
                    {/* <div className="navbar"> */}
                    {/* <a href="javascript:void(0)" onClick={this.openNav}> */}
                    {/* <div className="bar-style"> */}
                    {/* <i */}
                    {/* className="fa fa-bars sidebar-bar" */}
                    {/* aria-hidden="true" */}
                    {/* /> */}
                    {/* </div> */}
                    {/* </a> */}
                    {/*SideBar Navigation Component*/}
                    {/* <SideBar/> */}

                    {/* </div> */}
                    <div className="brand-logo">
                      <LogoImage />
                    </div>
                  </div>
                  <div className="menu-right pull-right">
                    {/*Top Navigation Bar Component*/}
                    <NavBar />

                    <div>
                      <div className="icon-nav">
                        <ul>
                          <li
                            className="onhover-div mobile-notification"
                            onClick={() => {
                              this.props.handleNotificicationDrawer();
                            }}
                          >
                            {/* <NotificationsIcon /> */}
                            <div>
                              <img
                                src={`${
                                  process.env.PUBLIC_URL
                                }/assets/images/icon/bell.png`}
                                className="img-fluid"
                                alt=""
                              />
                              <i class="fa fa-bell" aria-hidden="true" />
                            </div>
                            <div className="cart-qty-cls">
                              {notificationCounter}
                            </div>
                          </li>

                          <li className="onhover-div mobile-setting">
                            <div
                              onClick={() => {
                                // this.props.history.push(`/settings`);
                                onClickOnSettingsIcon();
                              }}
                            >
                              <img
                                src={`${
                                  process.env.PUBLIC_URL
                                }/assets/images/icon/setting.png`}
                                className="img-fluid"
                                alt=""
                              />
                              <i className="fa fa-cog" />
                            </div>
                          </li>

                          <CartContainer />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="search-overlay" className="search-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeSearch}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Search a Product"
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  notificationCounter: state.cartCounter.notificationCounter,
});

export default connect(
  mapStateToProps,
  { changeCurrency }
)(Header);
