import React, { Component } from "react";
import { connect } from "react-redux";
import "react-input-range/lib/css/index.css";
import { SlideToggle } from "react-slide-toggle";
import { filterBrand, filterColor, filterPrice } from "../../../actions";
import { getCall } from "../../../api/post";
import {
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store";
import { withRouter } from "react-router-dom";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";

class Filter extends Component {
  state = {
    categories: [],
    subCategories: [],
    openFilter: false,
    filteredCategory: "",
    filteredSubCategory: "",
    active: "",
    subActive: "",
    showErrorMessageModal: false,
    errorMessage: "",
  };

  componentDidMount() {
    this.initCategory();
  }

  UNSAFE_componentWillUpdate(nextProps, state) {
    if (this.props.match.path === "/c/:id") {
      if (
        nextProps.categoryId &&
        nextProps.categoryId !== "" &&
        state.filteredCategory === "" &&
        nextProps.categoryId !== state.filteredCategory
      ) {
        this.setState({ filteredCategory: nextProps.categoryId }, () => {
          this.deepLinkingHandleCategoryFilter(nextProps.categoryId);
        });
      }
    }
    if (this.props.match.path === "/s/:id") {
      if (
        nextProps.subCategoryId &&
        nextProps.subCategoryId !== "" &&
        state.filteredSubCategory === "" &&
        nextProps.subCategoryId !== state.filteredSubCategory
      ) {
        this.setState({ filteredSubCategory: nextProps.subCategoryId }, () => {
          this.deepLinkingHandleSubCategoryFilter(nextProps.subCategoryId);
        });
      }
    }
  }

  initParentCategry = async (subCategoryId) => {
    const singleCategoryUrl = "categories/" + subCategoryId;
    const singleCategory = await getCall(singleCategoryUrl, []);
    if (singleCategory.error) {
      this.loadErrorMessageAnimation(singleCategory.error.message);
    } else {
      this.setState({ parentCategory: singleCategory });
    }
  };

  initCategory = async () => {
    const categoryUrl = "categories?filter[where][isCategoryData]=1";
    const categories = await getCall(categoryUrl, []);

    if (categories.error) {
      this.loadErrorMessageAnimation(categories.error.message);
    } else {
      this.setState({ categories: categories.data });
    }
  };

  initSubCategory = async () => {
    const { filteredCategory } = this.state;
    const subcategoryUrl =
      "categories?filter[where][categorystatus]=1?filter[where][isCategory]=0&filter[where][parentId]=" +
      filteredCategory;
    const subCategories = await getCall(subcategoryUrl, []);
    if (subCategories.error) {
      this.loadErrorMessageAnimation(subCategories.error.message);
    } else {
      this.setState({ subCategories });
    }
  };

  closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -365px";
  };

  onClickOfCategory = (value) => {
    if (
      this.props.match.path === "/c/:id" ||
      this.props.match.path === "/s/:id"
    ) {
      this.setState(
        {
          filteredCategory: value,
          active: value,
        },
        () => {
          if (value !== "") {
            this.initSubCategory();
            this.props.history.replace(`/c/${value}`);
          } else {
            this.setState({ subCategories: [] });
            this.props.history.replace(`/products`);
          }
          this.props.handleCategoryWiseProductListFilter(value);
        }
      );
    } else {
      this.setState(
        {
          filteredCategory: value,
          active: value,
        },
        () => {
          if (value !== "") {
            this.initSubCategory();
          } else {
            this.setState({ subCategories: [] });
          }
          this.props.handleCategoryWiseProductListFilter(value);
        }
      );
    }
  };

  deepLinkingHandleCategoryFilter = (categoryId) => {
    this.initSubCategory();
    this.props.handleCategoryWiseProductListFilter(categoryId);
  };

  deepLinkingHandleSubCategoryFilter = (subCategoryId) => {
    this.initParentCategry(subCategoryId).then(() => {
      const { parentCategory } = this.state;
      this.setState({ filteredCategory: parentCategory.parentId }, () => {
        this.props.handleSubCategoryWiseProductListFilterDeepLinking(
          subCategoryId,
          parentCategory.parentId
        );
        this.initSubCategory().then(() => {
          this.setState({
            filteredSubCategory: subCategoryId,
          });
        });
      });
    });
  };

  onClickOfSubCategory = (value) => {
    if (this.props.match.path === "/s/:id") {
      this.setState(
        {
          filteredSubCategory: value,
          subActive: value,
        },
        () => {
          const { parentCategory } = this.state;
          this.props.history.replace(`/s/${value}`);
          this.props.handleSubCategoryWiseProductListFilterDeepLinking(
            value,
            parentCategory.parentId
          );
        }
      );
    } else {
      this.setState(
        {
          filteredSubCategory: value,
          subActive: value,
        },
        () => {
          this.props.handleSubCategoryWiseProductListFilter(value);
        }
      );
    }
  };

  colorHandle(event, color) {
    var elems = document.querySelectorAll(".color-selector ul li");
    [].forEach.call(elems, function(el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
    this.props.filterColor(color);
  }

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    let styles = {
      Padding: "0",
      border: "none",
      background: "none",
    };
    let subStyles = {
      width: "100%",
      Padding: "0",
      border: "none",
      background: "none",
    };

    const {
      filteredCategory,
      filteredSubCategory,
      categories,
      subCategories,
      showErrorMessageModal,
      errorMessage,
    } = this.state;
    return (
      <>
        {categories.length > 0 && (
          <div className="collection-filter-block">
            {/*brand filter start*/}
            <div className="collection-mobile-back">
              <span
                className="filter-back"
                onClick={(e) => this.closeFilter(e)}
              >
                <i className="fa fa-angle-left" aria-hidden="true" />{" "}
                {getValueFromLanguageObject("str_back")}
              </span>
            </div>

            {/* // Category */}

            <SlideToggle>
              {({ onToggle, setCollapsibleElement }) => (
                <div
                  className="collection-collapse-block"
                  style={{
                    // border: `1px solid ${getColorCodeBasedOnKey(
                    //   "border_category_box_products"
                    // )}`,
                    backgroundColor: getColorCodeBasedOnKey(
                      "bg_category_box_products"
                    ),
                  }}
                >
                  <h3
                    className="collapse-block-title"
                    onClick={onToggle}
                    style={{
                      color: getColorCodeBasedOnKey("text_category_products"),
                    }}
                  >
                    {getValueFromLanguageObject("str_category")}
                  </h3>
                  <div
                    className="collection-collapse-block-content"
                    ref={setCollapsibleElement}
                  >
                    <div className="collection-brand-filter">
                      <div value={filteredCategory} style={styles}>
                        <div className="custom-control custom-checkbox collection-filter-checkbox pl-0 subcategory-style table-style ">
                          <div
                            style={subStyles}
                            className="td-style"
                            onClick={() => {
                              this.onClickOfCategory("");
                            }}
                          >
                            {getValueFromLanguageObject("str_all")
                              ? getValueFromLanguageObject("str_all")
                              : "ALL"}
                          </div>
                        </div>
                        {categories.map((category, index) => {
                          return (
                            <div
                              key={index}
                              className="custom-control custom-checkbox collection-filter-checkbox pl-0 "
                            >
                              <div className="category-style">
                                <table className="table-style">
                                  <tr>
                                    <td
                                      style={styles}
                                      className={
                                        this.state.active === category.id
                                          ? "td-style"
                                          : ""
                                      }
                                      onClick={() => {
                                        this.onClickOfCategory(category.id);
                                      }}
                                    >
                                      {category.name}
                                    </td>
                                    <td
                                      className="th-style"
                                      onClick={() => {
                                        this.onClickOfCategory(category.id);
                                      }}
                                    >
                                      <i
                                        class="fa fa-angle-right"
                                        aria-hidden="true"
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              {subCategories.length > 0 && (
                                <div
                                  defaultValue={filteredSubCategory}
                                  style={styles}
                                >
                                  {subCategories
                                    .filter((subcategory) => {
                                      return (
                                        subcategory.parentId === category.id
                                      );
                                    })
                                    .map((subcategory, index) => {
                                      return (
                                        <div className="custom-control custom-checkbox  pl-0 ">
                                          <div className="subcategory-style">
                                            <table className="table-style">
                                              <tr>
                                                <td
                                                  className="td-style"
                                                  style={styles}
                                                  className={
                                                    this.state.subActive ===
                                                    subcategory.id
                                                      ? "subActive-style"
                                                      : ""
                                                  }
                                                  onClick={() => {
                                                    this.onClickOfSubCategory(
                                                      subcategory.id
                                                    );
                                                  }}
                                                >
                                                  {subcategory.name}
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </SlideToggle>
          </div>
        )}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  { filterBrand, filterColor, filterPrice }
)(withRouter(Filter));
