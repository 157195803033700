import React, { Component } from "react";
import { connect } from "react-redux";
import { getCall, postCall } from "../../../../api/post";
import { isInquiryOrCart } from "../../../../actions";
import { Chip } from "@react-md/chip";
import {
  getValueFromLanguageObject,
  getCurrencySymbol,
  loadUserDetailsFromLocalStorage,
} from "../../../../store";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getPincodeDeliveryUrl,
  getMasterDetailsIdUrl,
} from "../../../../services";
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ErrorMessageAnimationModal from "../../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../../animation/SuccessMessageAnimationModal";
import AddToCartAnim from "../../../../animation/AddToCartAnim";
const roundTo = require("round-to");

const productDesc = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  "-webkitLineClamp": "4",
  "-webkitBoxOrient": "vertical",
};
const publicUrl = window.location.pathname.split("/")[1];
const client = process.env.CLIENT_NAME;
class DetailsWithPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity:
        props.item.minimumorderquantity >= 1
          ? props.item.minimumorderquantity
          : 1,
      stock:
        props.item.availablequantity > 0
          ? getValueFromLanguageObject("str_instock")
            ? getValueFromLanguageObject("str_instock")
            : "InStock"
          : getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock!",
      nav3: null,
      category: {},
      shareUrl: "",
      productPrice: props.item.price,
      product: props.item,
      selectedVariation: [],
      pincode: "",
      isSubmitDisable: true,
      currentCatelogueType: {},
      isPincodeDeliveryEnable: false,
      isLoading: true,
      showErrorMessageModal: false,
      errorMessage: "",
      showSuccessMessageModal: false,
      successMessage: "",
      showAddToCartMessageModal: false,
      addToCartMessage: "",
      masterDetailsObj: {},
    };
  }

  componentDidMount() {
    this.setState({
      nav3: this.slider3,
    });

    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.initSingleCategry();
      this.initCatelogueSettings();
      this.initPincodeDelivery();
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initShareLink();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initSingleCategry = async () => {
    if (this.props.item.category.parentId !== null) {
      const singleCategoryUrl =
        "categories/" + this.props.item.category.parentId;
      const singleCategory = await getCall(singleCategoryUrl, []);
      if (singleCategory.error) {
        this.loadErrorMessageAnimation(singleCategory.error.message);
        if (singleCategory.error.statusCode === 401) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("apiToken");
          this.props.history.push(`/login`);
          return;
        }
      } else {
        this.setState({ category: singleCategory });
      }
    }
  };

  initPincodeDelivery = async () => {
    const { masterDetailsObj } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const pincodeDelivery = await getCall(
      getPincodeDeliveryUrl(masterDetailsObj),
      []
    );
    if (pincodeDelivery.error) {
      this.loadErrorMessageAnimation(pincodeDelivery.error.message);
      // this.setState({productPrice: this.state.productPrice,selectedVariation: this.state.selectedVariation})
    } else {
      if (pincodeDelivery.length > 0) {
        const status = pincodeDelivery[0].status;
        this.setState({ isPincodeDeliveryEnable: status === 1 ? true : false });
      }
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      if (settings.registerallow === "Catalogue_FABRIC") {
        this.initProductVariationQuantity(this.props.item);
      }
      this.setState({ currentCatelogueType: settings });
    }
  };

  initShareLink = async () => {
    const { product } = this.state;
    const shareLinkUrl = "products/shortnewURL";
    const link = `${window.location.hostname}/${
      client ? client : publicUrl
    }/p/${product.id}`;
    const shareLinkObj = {
      url: link,
    };
    const shareLinkResponse = await postCall(shareLinkUrl, shareLinkObj);
    if (shareLinkResponse.error) {
      this.loadErrorMessageAnimation(shareLinkResponse.error.message);
    } else {
      this.setState({ shareUrl: shareLinkResponse.shortUrl });
    }
  };

  initProductVariationQuantity = (item) => {
    let tempItem = item;
    let variationArrayTemp = [];
    if (tempItem.productvariation) {
      let variation = JSON.parse(tempItem.productvariation);
      for (let index = 0; index < variation.length; index++) {
        if (variation[index].quantity === null) {
          variation[index].quantity = 1;
          variationArrayTemp.push(variation[index]);
        }
      }
    }
    tempItem.productvariation = JSON.stringify(variationArrayTemp);
    this.setState({ product: tempItem });
  };

  minusQty = () => {
    const minimumorderquantity = this.props.item.minimumorderquantity
      ? this.props.item.minimumorderquantity
      : 1;
    if (this.state.quantity > minimumorderquantity) {
      this.setState({
        stock: getValueFromLanguageObject("str_instock")
          ? getValueFromLanguageObject("str_instock")
          : "InStock",
      });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.item.availablequantity > this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({
        stock: getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock!",
      });
    }
  };

  changeQty = (e) => {
    try {
      if (parseInt(e.target.value) < 1) {
        return;
      }
      this.setState({ quantity: parseInt(e.target.value) });
    } catch (error) {
      this.setState({ quantity: "" });
    }
  };

  setSelectedSize = (price) => {
    this.setState({ productPrice: price });
  };

  renderVariationText = (vari) => {
    let text = "";
    if (vari && vari.variation && vari.variation.length > 0) {
      text = vari.variation.map((variation) => {
        return variation.value;
      });
    }
    return text.join(" - ");
  };

  renderVariationQuantity = (vari) => {
    return vari.quantity;
  };

  decreaseProductVariation = (vari, index) => {
    if (vari.quantity === 0) {
      return;
    }
    const { product } = this.state;
    let tempItem = product;
    let variationArrayTemp = [];
    if (tempItem.productvariation) {
      let variation = JSON.parse(tempItem.productvariation);
      for (let i = 0; i < variation.length; i++) {
        if (i === index) {
          variation[i].quantity = variation[i].quantity - 1;
        }
        variationArrayTemp.push(variation[i]);
      }
    }
    tempItem.productvariation = JSON.stringify(variationArrayTemp);
    this.setState({ product: tempItem });
  };

  increaseProductVariation = (vari, index) => {
    const { product } = this.state;
    if (product.availablequantity <= vari.quantity) {
      return;
    }
    let tempItem = product;
    let variationArrayTemp = [];
    if (tempItem.productvariation) {
      let variation = JSON.parse(tempItem.productvariation);
      for (let i = 0; i < variation.length; i++) {
        if (i === index) {
          variation[i].quantity = variation[i].quantity + 1;
        }
        variationArrayTemp.push(variation[i]);
      }
    }
    tempItem.productvariation = JSON.stringify(variationArrayTemp);
    this.setState({ product: tempItem });
  };

  renderPriceVarientPanam = (productvariation) => {
    const {  masterDetailsObj } = this.state;
    const { symbol } = this.props;
    const priceVarient = JSON.parse(productvariation);
    return (
      priceVarient && (
        <ul>
          {priceVarient.map((vari, i) => {
            return (
              <div>
                <img
                  style={{ height: "50px", width: "50px" }}
                  src={
                    vari.image && vari.image.length > 0 && vari.image[0].name
                      ? getImageUrl(vari.image[0].name, masterDetailsObj)
                      : getPlaceHolderImage()
                  }
                  alt=""
                />
                {this.renderVariationText(vari)}
                <h3>
                  {symbol}
                  { parseFloat(vari.price).toFixed(2)}

                </h3>
                <div className="qty-box">
                  <div className="input-group">
                    <span className="input-group-prepend">
                      <button
                        type="button"
                        className="btn quantity-left-minus"
                        onClick={() => this.decreaseProductVariation(vari, i)}
                        data-type="minus"
                        data-field=""
                      >
                        <i className="fa fa-angle-left" />
                      </button>
                    </span>
                    <div className="form-control input-number">
                      {this.renderVariationQuantity(vari)}
                    </div>

                    <span className="input-group-prepend">
                      <button
                        type="button"
                        className="btn quantity-right-plus"
                        onClick={() => this.increaseProductVariation(vari, i)}
                        data-type="plus"
                        data-field=""
                      >
                        <i className="fa fa-angle-right" />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </ul>
      )
    );
  };

  renderPriceVarientKey = (productvariation) => {
    const priceVarient = JSON.parse(productvariation);
    let tempKey = [];
    priceVarient.forEach((element) => {
      element.variation.forEach((vari, i) => {
        tempKey[vari.key] = new Array();
      });
    });

    priceVarient.forEach((element) => {
      element.variation.forEach((vari, i) => {
        if (Object.keys(tempKey)[i] === vari.key) {
          if (JSON.stringify(tempKey[vari.key][i]) !== JSON.stringify(vari)) {
            tempKey[vari.key].push(vari);
          }
        }
      });
    });

    return (
      <div>
        {Object.keys(tempKey).map((item, index) => {
          return (
            <div key={index} className="border-product">
              <h6 className="product-title size-text">{item} : </h6>
              {tempKey[item].map((subitem, i) => {
                return (
                  <div className="size-box">
                    <div>
                      <li
                        onClick={() => {
                          this.onClickOfVariationValue(
                            priceVarient,
                            item,
                            subitem.value
                          );
                        }}
                        className={
                          this.state.active === subitem.value ? "active" : ""
                        }
                      >
                        {i === 0 &&
                          this.defaultSelectVariation(
                            priceVarient,
                            item,
                            subitem.value,
                            Object.keys(tempKey).length
                          )}
                        {subitem.value}
                      </li>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  onClickOfVariationValue = (priceVarient, keyy, valuee) => {
    let { selectedVariation } = this.state;
    let obj = { key: keyy, value: valuee };
    const i = selectedVariation.findIndex((_item) => _item.key === obj.key);
    if (i > -1) selectedVariation[i] = obj;
    else selectedVariation.push(obj);
    this.setState({ selectedVariation, active: valuee });

    priceVarient.forEach((variation) => {
      if (
        JSON.stringify(variation.variation) ===
        JSON.stringify(selectedVariation)
      ) {
        this.setState({ productPrice: variation.price, active: valuee });
      }
    });
  };

  defaultSelectVariation = (priceVarient, keyy, valuee, variationLength) => {
    let { selectedVariation } = this.state;
    if (variationLength === selectedVariation.length) {
      return;
    }
    let obj = { key: keyy, value: valuee };
    selectedVariation.push(obj);
    this.setState({ selectedVariation, active: valuee });
    priceVarient.forEach((variation) => {
      if (
        JSON.stringify(variation.variation) ===
        JSON.stringify(selectedVariation)
      ) {
        this.setState({ productPrice: variation.price, active: valuee });
      }
    });
  };

  renderPriceVarientValue = (productvariation) => {
    const { productPrice } = this.state;
    const priceVarient = JSON.parse(productvariation);
    return (
      priceVarient &&
      priceVarient.length > 0 && (
        <ul>
          {priceVarient.map((pc, i) => {
            return pc.variation.map((vari) => {
              return (
                <Chip
                  key={vari.value}
                  className={"chip-cls"}
                  selected={productPrice === pc.price}
                  selectedThemed
                  onClick={() => this.setSelectedSize(pc.price)}
                >
                  {vari.value}
                </Chip>
              );
            });
          })}
        </ul>
      )
    );
  };

  renderBundlePrice = (productvariation) => {
    let bundlePrice = 0;
    const variation = JSON.parse(productvariation);
    if (variation && variation.length > 0) {
      for (let index = 0; index < variation.length; index++) {
        const element = variation[index];
        if (element.price && element.quantity) {
          bundlePrice = bundlePrice + element.price * element.quantity;
        }
      }
    }
    return bundlePrice;
  };

  onClickOfSampleReuest = async (item) => {
    const userDetails = loadUserDetailsFromLocalStorage();
    const sampleRequestUrl = "orders/productInquiry";
    const sampleRequestObj = {
      orderstatus: 1,
      totalamount: item.price,
      inshoppingcart: 4,
      userId: userDetails.id,
      cityId: userDetails.cityId,
      orderdetails: [
        {
          productId: item.id,
        },
      ],
      date: new Date(),
    };
    const sampleRequest = await postCall(sampleRequestUrl, sampleRequestObj);
    if (sampleRequest.error) {
      this.loadErrorMessageAnimation(sampleRequest.error.message);
    } else {
      this.loadSuccessMessageAnimation(
        "Your Sample Request is placed successfully!"
      );
    }
  };

  getProductQuote = (item) => {
    const { symbol } = this.props;
    const { shareUrl } = this.state;
    return `Property Name : ${item.name} \n Price : ${symbol}${roundTo(
      item.price,
      2
    )} \n ${shareUrl}`;
  };

  getProductQuoteTwitter = (item) => {
    const { symbol } = this.props;
    return `Property Name : ${item.name} \n Price : ${symbol}${roundTo(
      item.price,
      2
    )}`;
  };

  pincodeHandleSubmit = (event) => {
    // event.preventDefault();
    this.setState({ pincode: event.target.value });
  };

  addToCartBase = () => {
    const { productPrice, product, selectedVariation } = this.state;
    const { item, isInquiry } = this.props;
    // this.setState({ selectedVariation});
    if (process.env.CLIENT_NAME === "panam") {
      this.addToCartClicked(
        product,
        this.state.quantity,
        isInquiry,
        productPrice,
        selectedVariation
      );
    } else {
      this.addToCartClicked(
        item,
        this.state.quantity,
        isInquiry,
        productPrice,
        selectedVariation
      );
    }
  };

  addToCartClicked = (
    item,
    quantity,
    isInquiry,
    productPrice,
    selectedVariation,
    pincode
  ) => {
    const { afterAddToCartClicked } = this.props;
    this.setState({ isLoading: true }, () => {
      this.addToCart(
        item,
        quantity,
        isInquiry,
        productPrice,
        selectedVariation,
        pincode
      ).then((res) => {
        if (!res.error) {
          afterAddToCartClicked();
          // this.setState({ isLoading: false }, () => {
          //   this.setState({ isLoading: true }, () => {
          //     this.initProduct().then(() => {
          //       this.setState({ isLoading: false });
          //     });
          //   });
          // });
        } else {
          this.setState({ isLoading: false });
        }
      });
    });
  };
  addToCart = async (
    product,
    qty,
    isInquiry,
    productPrice,
    selectedVariation,
    pincode
  ) => {
    const { currentCatelogueType } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    const addtocartUrl = isInquiry
      ? "orders/productInquiry"
      : "orders/addtocart";

    let orderdetailsObj = [];
    if (
      product.productvariation &&
      product.productvariation !== null &&
      product.productvariation !== "" &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_FABRIC"
    ) {
      const productVariationParse = JSON.parse(product.productvariation);

      if (
        productVariationParse &&
        productVariationParse.length > 0 &&
        !product.bundlepurchase
      ) {
        productVariationParse.map((variation) => {
          const tempObj = {
            quantity: variation.quantity,
            productId: product.id,
            variation: variation,
          };
          return orderdetailsObj.push(tempObj);
        });
      } else {
        orderdetailsObj = [
          {
            quantity: qty,
            productId: product.id,
          },
        ];
      }

      const addtocartObj = {
        orderstatus: 1,
        totalamount: !product.bundlepurchase
          ? this.renderBundlePrice(product.productvariation)
          : product.price * qty,
        inshoppingcart: 1,
        userId: userDetails.id,
        orderdetails: orderdetailsObj,
        cityId: userDetails.cityId,
        date: new Date(),
        // pincode: pincode
      };

      const addToCartResponse = await postCall(addtocartUrl, addtocartObj);
      if (addToCartResponse.error) {
        this.loadErrorMessageAnimation(addToCartResponse.error.message);

        if (addToCartResponse.error.statusCode === 401) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("apiToken");
          this.props.history.push(`/login`);
          return;
        }
      } else {
        if (isInquiry) {
          this.loadSuccessMessageAnimation(
            getValueFromLanguageObject("str_add_to_inquiry_successfully")
          );
        } else {
          this.loadAddToCartMessageAnimation(
            getValueFromLanguageObject("str_add_to_cart_successfully")
          );
        }
      }

      // this.props.counter();
      return addToCartResponse;
    } else if (
      product.productvariation &&
      product.productvariation !== null &&
      product.productvariation !== "" &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_FABRIC"
    ) {
      const productVariationParse = JSON.parse(product.productvariation);
      if (productVariationParse && productVariationParse.length > 0) {
        productVariationParse.map((variation) => {
          if (
            JSON.stringify(variation.variation) ===
            JSON.stringify(selectedVariation)
          ) {
            const tempObj = {
              quantity: qty,
              productId: product.id,
              variation: variation,
            };
            return orderdetailsObj.push(tempObj);
          }
        });
      } else {
        orderdetailsObj = [
          {
            quantity: qty,
            productId: product.id,
          },
        ];
      }

      const addtocartObj = {
        orderstatus: 1,
        totalamount: productPrice ? productPrice * qty : product.price * qty,
        inshoppingcart: 1,
        userId: userDetails.id,
        orderdetails: orderdetailsObj,
        cityId: userDetails.cityId,
        date: new Date(),
        // pincode: pincode
      };

      const addToCartResponse = await postCall(addtocartUrl, addtocartObj);
      if (addToCartResponse.error) {
        this.loadErrorMessageAnimation(addToCartResponse.error.message);
        if (addToCartResponse.error.statusCode === 401) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("apiToken");
          this.props.history.push(`/login`);
          return;
        }
      } else {
        if (isInquiry) {
          this.loadSuccessMessageAnimation(
            getValueFromLanguageObject("str_add_to_inquiry_successfully")
          );
        } else {
          this.loadAddToCartMessageAnimation(
            getValueFromLanguageObject("str_add_to_cart_successfully")
          );
        }
      }

      // this.props.counter();
      return addToCartResponse;
    } else {
      orderdetailsObj = [
        {
          quantity: qty,
          productId: product.id,
        },
      ];
    }

    const addtocartObj = {
      orderstatus: 1,
      totalamount: productPrice ? productPrice * qty : product.price * qty,
      inshoppingcart: 1,
      userId: userDetails.id,
      orderdetails: orderdetailsObj,
      cityId: userDetails.cityId,
      date: new Date(),
      // pincode: pincode
    };

    const addToCartResponse = await postCall(addtocartUrl, addtocartObj);
    if (addToCartResponse.error) {
      this.loadErrorMessageAnimation(addToCartResponse.error.message);
      if (addToCartResponse.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("str_add_to_inquiry_successfully")
        );
      } else {
        this.loadAddToCartMessageAnimation(
          getValueFromLanguageObject("str_add_to_cart_successfully")
        );
      }
    }
    // this.props.counter();
  };

  getCartListCounter = async () => {
    let cartCounter = 0;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return cartCounter;
    }
    const { id } = userDetails;
    const counterUrl = "commoncounters?filter[where][userId]=" + id;
    const commonCounter = await getCall(counterUrl, []).then();
    if (commonCounter.error) {
      this.loadErrorMessageAnimation(commonCounter.error.message);
    } else {
      if (commonCounter.length > 0) {
        cartCounter = commonCounter[0].cart;
      }
    }
    return cartCounter;
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  loadAddToCartMessageAnimation = (message) => {
    this.setState({
      addToCartMessage: message,
      showAddToCartMessageModal: true,
    });
  };

  render() {
    const { symbol, item, isInquiry } = this.props;

    const {
      stock,
      category,
      shareUrl,
      productPrice,
      product,
      selectedVariation,
      currentCatelogueType,
      isPincodeDeliveryEnable,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      showAddToCartMessageModal,
      addToCartMessage,
    } = this.state;

    return (
      <>
        <div className="col-lg-6 rtl-text">
          <div className="product-right">
            <h2> {item.name} </h2>
            {item.description && (
              <h6 style={productDesc}>{item.description}</h6>
            )}
            {item &&
              item.productvariation &&
              item.productvariation.length > 0 &&
              currentCatelogueType &&
              currentCatelogueType.registerallow === "Catalogue_FABRIC" && (
                <div className="border-product">
                  {this.renderPriceVarientPanam(product.productvariation)}
                </div>
              )}
            {isInquiry ? (
              <div />
            ) : (
              <h3>
                {symbol}
                { parseFloat(productPrice).toFixed(2)}
              </h3>
            )}

            {item &&
              item.productvariation &&
              item.productvariation.length > 0 &&
              currentCatelogueType &&
              currentCatelogueType.registerallow === "Catalogue_FABRIC" && (
                <div className="border-product">
                  {this.renderPriceVarientPanam(product.productvariation)}
                </div>
              )}

            {item &&
              item.productvariation &&
              item.productvariation.length > 0 &&
              currentCatelogueType &&
              currentCatelogueType.registerallow !== "Catalogue_FABRIC" &&
              this.renderPriceVarientKey(product.productvariation)}

            <div className="product-description border-product">
              {!isInquiry && (
                <span className="instock-cls">
                  {" "}
                  {stock}
                  {/* :{" "} */}
                  {/* {item
                ? item.availablequantity - this.state.quantity
                : this.state.quantity} */}
                </span>
              )}
              {currentCatelogueType &&
                currentCatelogueType.registerallow !== "Catalogue_FABRIC" && (
                  <h6 className="product-title">
                    {getValueFromLanguageObject("str_quantity")
                      ? getValueFromLanguageObject("str_quantity")
                      : "quantity"}
                  </h6>
                )}

              {currentCatelogueType &&
                currentCatelogueType.registerallow !== "Catalogue_FABRIC" && (
                  <div className="qty-box">
                    <div className="input-group">
                      <span className="input-group-prepend">
                        <button
                          type="button"
                          className="btn quantity-left-minus"
                          onClick={this.minusQty}
                          data-type="minus"
                          data-field=""
                        >
                          <i className="fa fa-angle-left" />
                        </button>
                      </span>
                      <input
                        type="number"
                        name="quantity"
                        value={this.state.quantity}
                        onChange={this.changeQty}
                        className="form-control input-number"
                      />
                      <span className="input-group-prepend">
                        <button
                          type="button"
                          className="btn quantity-right-plus"
                          onClick={this.plusQty}
                          data-type="plus"
                          data-field=""
                        >
                          <i className="fa fa-angle-right" />
                        </button>
                      </span>
                    </div>
                  </div>
                )}
            </div>
            <div className="product-buttons">
              {currentCatelogueType &&
                currentCatelogueType.registerallow === "Catalogue_FABRIC" && (
                  <div>
                    <h6 className="product-title">
                      {getValueFromLanguageObject("str_bundle_price")
                        ? getValueFromLanguageObject("str_bundle_price")
                        : "Bundle Price :"}{" "}
                      {symbol}{" "}
                      {this.renderBundlePrice(product.productvariation)}
                    </h6>
                    <br />
                  </div>
                )}

              <div
                className="btn btn-solid"
                onClick={async () => {
                  this.addToCartBase();
                }}
              >
                {" "}
                {isInquiry
                  ? getValueFromLanguageObject("str_add_to_inquiry")
                    ? getValueFromLanguageObject("str_add_to_inquiry")
                    : "Add To Inquiry"
                  : getValueFromLanguageObject("str_add_to_cart")
                  ? getValueFromLanguageObject("str_add_to_cart")
                  : "Add To Cart"}
              </div>

              {currentCatelogueType &&
                currentCatelogueType.registerallow === "Catalogue_FABRIC" && (
                  <div
                    className="btn btn-solid"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      this.onClickOfSampleReuest(item);
                    }}
                  >
                    {" "}
                    {getValueFromLanguageObject("str_sample_request")
                      ? getValueFromLanguageObject("str_sample_request")
                      : "Sample Request"}
                  </div>
                )}
            </div>
            <div className="border-product">
              <h6 className="product-title">
                {getValueFromLanguageObject("str_products_sub_category")
                  ? getValueFromLanguageObject("str_products_sub_category")
                  : "Product's Sub-Category"}
              </h6>
              <p>
                {item &&
                item.category !== undefined &&
                item.category.name !== undefined
                  ? item.category.name
                  : ""}
              </p>
            </div>
            {category.name && (
              <div className="border-product">
                <div>
                  <h6 className="product-title">
                    {getValueFromLanguageObject("str_products_category")
                      ? getValueFromLanguageObject("str_products_category")
                      : "Product's Category"}
                  </h6>
                  <p>{category.name}</p>
                </div>
              </div>
            )}
            {shareUrl !== "" && (
              <div className="border-product">
                <h6 className="product-title">Share This To</h6>
                <div className="product-icon">
                  <ul className="product-social">
                    <li>
                      <FacebookShareButton
                        url={shareUrl}
                        quote={this.getProductQuote(item)}
                        hashtag={`#${client ? client : publicUrl}`}
                      >
                        <FacebookIcon size={42} round />
                      </FacebookShareButton>
                    </li>

                    <li>
                      <TwitterShareButton
                        url={shareUrl}
                        via={this.getProductQuoteTwitter(item)}
                        hashtags={[`${client ? client : publicUrl}`]}
                      >
                        <TwitterIcon size={42} round />
                      </TwitterShareButton>
                    </li>

                    <li>
                      <TelegramShareButton
                        url={shareUrl}
                        title={this.getProductQuoteTwitter(item)}
                      >
                        <TelegramIcon size={42} round />
                      </TelegramShareButton>
                    </li>

                    <li>
                      <WhatsappShareButton
                        url={shareUrl}
                        title={this.getProductQuoteTwitter(item)}
                        separator=" :: "
                      >
                        <WhatsappIcon size={42} round />
                      </WhatsappShareButton>
                    </li>

                    <li>
                      <LinkedinShareButton
                        url={shareUrl}
                        source={client ? client : publicUrl}
                        summary={this.getProductQuoteTwitter(item)}
                      >
                        <LinkedinIcon size={42} round />
                      </LinkedinShareButton>
                    </li>

                    <li>
                      <EmailShareButton
                        url={shareUrl}
                        subject={"Product Details"}
                        body={this.getProductQuoteTwitter(item)}
                      >
                        <EmailIcon size={42} round />
                      </EmailShareButton>
                    </li>
                  </ul>
                </div>
                <div style={{ display: "flex", marginTop: "5px" }}>
                  <input
                    type="text"
                    disabled
                    name="shareInput"
                    value={shareUrl}
                    className="form-control"
                  />

                  <CopyToClipboard
                    text={shareUrl}
                    onCopy={() => {
                      this.loadSuccessMessageAnimation(
                        "Product Link Copied to Clipboard!"
                      );
                    }}
                  >
                    <div className="btn btn-solid">Copy</div>
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </div>
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
        {showAddToCartMessageModal && (
          <AddToCartAnim
            show={showAddToCartMessageModal}
            message={addToCartMessage}
            onClose={() => {
              this.setState({ showAddToCartMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    symbol: getCurrencySymbol(),
    isInquiry: state.settings.isInquiry,
  };
};

export default connect(
  mapStateToProps,
  {
    isInquiryOrCart: isInquiryOrCart,
  }
)(DetailsWithPrice);
