export const payment = ({
  clientName,
  clientLogo,
  amount,
  mobile,
  email,
  name,
  currency,
  orderId,
  key,
}) =>
  new Promise((resolve) => {
    const config = {
      // key: "rzp_test_wG5kjHKKMxbYbE",
      key: key,
      amount: 100 * amount, // Amount is in currency subunits. Default currency is INR. Hence, 29935 refers to 29935 paise or INR 299.35.
      currency,
      name: clientName,
      image: clientLogo,
      orderId: orderId,
      handler: (response) => {
        resolve(response);
      },
      prefill: {
        name: name,
        email: email,
        contact: mobile,
      },
    };

    const rzp1 = new window.Razorpay(config);
    rzp1.open();
  });
