import MainLoader from "../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../animation/SuccessMessageAnimationModal";

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getShippingOptionsUrl,
  getMerchantInformationUrl,
  getPincodeDeliveryUrl,
  getMasterDetailsIdUrl,
} from "../../services";
import {
  loadUserDetailsFromLocalStorage,
  removeCurrentLanguage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../store";
import { getCall, patchCall } from "../../api/post";
import {
  getCommonCounter,
  getNotificationCounter,
  isInquiryOrCart,
} from "../../actions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DeleteAnim from "../../animation/AnimFiles/DeleteAnim";
import NoProductFoundCartAnim from "../../animation/AnimFiles/NoProductFoundCartAnim";
import ConfirmAlertPopup from "../../Alert/ConfirmAlertPopup";

class cartComponent extends Component {
  state = {
    cartList: [],
    orderDetails: [],
    isLoading: true,
    isMerchantGSTEnable: false,
    merchantInfo: {},
    shippingCharges: [],
    AppTitle: "",
    isPincodeDeliveryEnable: false,
    currentCatelogueType: {},
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
  };
  constructor(props) {
    super(props);

    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      this.props.history.push(`/login`);
      return;
    }
  }

  removeUserDetailAndRedirectToLogin = () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("apiToken");
    removeCurrentLanguage();
    this.props.history.push(`/login`);
  };

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.initCartList().then(() => {
        this.setState({ isLoading: false });
      });
      this.initMerchantInfo();
      this.initCatelogueSettings();
      this.initShippingCharges();
      this.initPincodeDelivery();
      this.props.notificationCounter();
      this.props.isInquiryOrCart(masterDetailsObj);
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };
  initCartList = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;

    const cartListUrl = `orders?filter[where][userId]=${id}&filter[where][inshoppingcart]=1&filter[order]=created`;
    const cartList = await getCall(cartListUrl, []);
    if (cartList.error) {
      this.loadErrorMessageAnimation(cartList.error.message);
      if (cartList.error.statusCode === 401) {
        this.removeUserDetailAndRedirectToLogin();
        return;
      }
    } else {
      this.setState({ cartList });
      if (cartList.length > 0) {
        this.setState({ orderDetails: cartList[0].orderdetail });
      } else {
        this.setState({ orderDetails: [] });
      }
    }
  };

  initShippingCharges = async () => {
    const { masterDetailsObj } = this.state;
    const shippingChargesUrl = getShippingOptionsUrl(masterDetailsObj);
    const shippingCharges = await getCall(shippingChargesUrl, []);
    this.setState({ shippingCharges: shippingCharges });
  };

  minusQty = (orderDetail, index) => {
    const { isInquiry } = this.props;

    this.setState({ isLoading: true }, () => {
      if (isInquiry) {
        this.editInquiryApi(orderDetail, index, 0).then(() => {
          this.setState({ isLoading: false });
        });
      } else {
        this.editCartApi(orderDetail, index, 0).then(() => {
          this.setState({ isLoading: false });
        });
      }
    });
  };

  plusQty = (orderDetail, index) => {
    const { isInquiry } = this.props;

    this.setState({ isLoading: true }, () => {
      if (isInquiry) {
        this.editInquiryApi(orderDetail, index, 1).then(() => {
          this.setState({ isLoading: false });
        });
      } else {
        this.editCartApi(orderDetail, index, 1).then(() => {
          this.setState({ isLoading: false });
        });
      }
    });
  };
  editInquiryApi = async (orderDetail, index, operation) => {
    const { orderDetails, cartList } = this.state;
    const orderId = cartList[0].id;

    const editInquiryUrl = `orders/${orderId}?filter[orderedit]=true`;
    var editInquiryObj = {};
    const userDetails = loadUserDetailsFromLocalStorage();
    const tempQty =
      operation === 0 ? orderDetail.quantity - 1 : orderDetail.quantity + 1;
    const { id } = userDetails;
    if (orderDetail.products.minimumorderquantity > tempQty) {
      return;
    }
    if (tempQty === 0) {
      return;
    }

    editInquiryObj = {
      userId: id,
      inshoppingcart: 1,
      orderdetails: [
        {
          id: orderDetail.id,
          quantity: tempQty,
        },
      ],
    };
    const editInquiry = await patchCall(editInquiryUrl, editInquiryObj);
    if (editInquiry.error) {
      this.loadErrorMessageAnimation(editInquiry.error.message);
      if (editInquiry.error.statusCode === 401) {
        this.removeUserDetailAndRedirectToLogin();
        return;
      }
    } else {
      const tempOrderDetail = orderDetails;
      tempOrderDetail[index].quantity = tempQty;
      this.setState({ orderDetails: tempOrderDetail });

      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("msg_your_inquiry_updated_successfully")
          ? getValueFromLanguageObject("msg_your_inquiry_updated_successfully")
          : "Your inquiry updated successfully"
      );
    }
  };

  editCartApi = async (orderDetail, index, operation) => {
    const {
      orderDetails,
      merchantInfo,
      isMerchantGSTEnable,
      currentCatelogueType,
    } = this.state;
    const { isInquiry } = this.props;
    // operation == 0 --- Decrese Qty
    // operation == 1 --- Increase Qty
    const { cartList } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const total = this.getTotalofCart(cartList);
    const tempQty =
      operation === 0 ? orderDetail.quantity - 1 : orderDetail.quantity + 1;

    if (orderDetail.products.minimumorderquantity > tempQty) {
      return;
    }
    if (tempQty === 0) {
      return;
    }
    const tempAmount = orderDetail.amount;
    const orderId = cartList[0].id;
    const editCartUrl = "orders/" + orderId;
    var editCartObj = {};

    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      editCartObj = {
        totalamount:
          operation === 0
            ? this.getGrandTotal(
                this.getTotalofCart(cartList) - tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(cartList) - tempAmount
                )
              )
            : this.getGrandTotal(
                this.getTotalofCart(cartList) + tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(cartList) + tempAmount
                )
              ),
        userId: id,
        inshoppingcart: 1,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        tax: {
          cgst: merchantInfo.CGST,
          sgst: merchantInfo.SGST,
          cgstPrice:
            operation === 0
              ? this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                )
              : this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
          sgstPrice:
            operation === 0
              ? this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                )
              : this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
        },
        baseprice:
          operation === 0
            ? this.getTotalofCart(cartList) - tempAmount
            : this.getTotalofCart(cartList) + tempAmount,
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(cartList) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(cartList) + tempAmount
              ),
      };
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      editCartObj = {
        totalamount:
          operation === 0
            ? this.getGrandTotal(
                this.getTotalofCart(cartList) - tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(cartList) - tempAmount
                )
              )
            : this.getGrandTotal(
                this.getTotalofCart(cartList) + tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(cartList) + tempAmount
                )
              ),
        userId: id,
        inshoppingcart: 1,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        baseprice:
          operation === 0
            ? this.getTotalofCart(cartList) - tempAmount
            : this.getTotalofCart(cartList) + tempAmount,
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(cartList) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(cartList) + tempAmount
              ),
      };
    } else {
      editCartObj = {
        totalamount:
          operation === 0
            ? total -
              tempAmount -
              this.getShippingCharges(
                this.getTotalofCart(cartList) - tempAmount
              )
            : total +
              tempAmount +
              this.getShippingCharges(
                this.getTotalofCart(cartList) + tempAmount
              ),
        userId: id,
        inshoppingcart: 1,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(cartList) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(cartList) + tempAmount
              ),
      };
    }

    const editOrder = await patchCall(editCartUrl, editCartObj);
    if (editOrder.error) {
      this.loadErrorMessageAnimation(editOrder.error.message);
      if (cartList.error.statusCode === 401) {
        this.removeUserDetailAndRedirectToLogin();
        return;
      }
    } else {
      const tempOrderDetail = orderDetails;
      tempOrderDetail[index].quantity = tempQty;
      this.setState({ orderDetails: tempOrderDetail });
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_inquiry_updated_successfully")
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_order_updated_successfully")
        );
      }
    }
  };

  changeQty = (orderDetail, index, e) => {
    const { isInquiry } = this.props;
    // if (isInquiry) {
    //   return;
    // }

    let qty = e.target.value;
    if (qty !== "" && isFinite(qty)) {
      const { orderDetails } = this.state;
      if (orderDetail.quantity < parseInt(qty)) {
        this.setState({ isLoading: true }, () => {
          this.updateCartAPI(orderDetail, index, parseInt(qty), 1).then(() => {
            const tempOrderDetail = orderDetails;
            tempOrderDetail[index].quantity = parseInt(qty);
            this.setState({ orderDetails: tempOrderDetail, isLoading: false });
          });
        });
      } else if (orderDetail.quantity > parseInt(qty)) {
        this.setState({ isLoading: true }, () => {
          this.updateCartAPI(orderDetail, index, parseInt(qty), 0).then(() => {
            const tempOrderDetail = orderDetails;
            tempOrderDetail[index].quantity = parseInt(qty);
            this.setState({ orderDetails: tempOrderDetail, isLoading: false });
          });
        });
      }
    } else {
      const { orderDetails } = this.state;
      const tempOrderDetail = orderDetails;
      tempOrderDetail[index].quantity = "";
      this.setState({ orderDetails: tempOrderDetail });
    }
  };

  updateCartAPI = async (orderDetail, index, qty, operation) => {
    const {
      orderDetails,
      isMerchantGSTEnable,
      merchantInfo,
      currentCatelogueType,
    } = this.state;
    const { isInquiry } = this.props;
    const { cartList } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const total = this.getTotalofCart(cartList);
    const tempQty = qty;
    if (orderDetail.products.minimumorderquantity > tempQty) {
      return;
    }
    if (tempQty === 0) {
      return;
    }
    const tempAmount = orderDetail.amount * qty;
    const orderId = cartList[0].id;
    const editCartUrl = "orders/" + orderId;
    var editCartObj = {};
    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      editCartObj = {
        totalamount:
          operation === 0
            ? this.getGrandTotal(
                this.getTotalofCart(cartList) - tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(cartList) - tempAmount
                )
              )
            : this.getGrandTotal(
                this.getTotalofCart(cartList) + tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(cartList) + tempAmount
                )
              ),
        userId: id,
        inshoppingcart: 1,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        tax: {
          cgst: merchantInfo.CGST,
          sgst: merchantInfo.SGST,
          cgstPrice:
            operation === 0
              ? this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                )
              : this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
          sgstPrice:
            operation === 0
              ? this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                )
              : this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
        },
        baseprice:
          operation === 0
            ? this.getTotalofCart(cartList) - tempAmount
            : this.getTotalofCart(cartList) + tempAmount,
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(cartList) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(cartList) + tempAmount
              ),
      };
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      editCartObj = {
        totalamount:
          operation === 0
            ? this.getGrandTotal(
                this.getTotalofCart(cartList) - tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(cartList) - tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(cartList) - tempAmount
                )
              )
            : this.getGrandTotal(
                this.getTotalofCart(cartList) + tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(cartList) + tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(cartList) + tempAmount
                )
              ),
        userId: id,
        inshoppingcart: 1,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        baseprice:
          operation === 0
            ? this.getTotalofCart(cartList) - tempAmount
            : this.getTotalofCart(cartList) + tempAmount,
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(cartList) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(cartList) + tempAmount
              ),
      };
    } else {
      editCartObj = {
        totalamount:
          operation === 0
            ? total -
              tempAmount -
              this.getShippingCharges(
                this.getTotalofCart(cartList) - tempAmount
              )
            : total +
              tempAmount +
              this.getShippingCharges(
                this.getTotalofCart(cartList) + tempAmount
              ),
        userId: id,
        inshoppingcart: 1,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(cartList) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(cartList) + tempAmount
              ),
      };
    }

    const editOrder = await patchCall(editCartUrl, editCartObj);
    if (editOrder.error) {
      this.loadErrorMessageAnimation(editOrder.error.message);
      if (cartList.error.statusCode === 401) {
        this.removeUserDetailAndRedirectToLogin();
        return;
      }
    } else {
      const tempOrderDetail = orderDetails;
      tempOrderDetail[index].quantity = tempQty;
      this.setState({ orderDetails: tempOrderDetail });
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_inquiry_updated_successfully")
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_order_updated_successfully")
        );
      }
    }
  };

  getTotalofCart(cartList) {
    let total = 0;
    if (cartList.length > 0)
      for (let i = 0; i < cartList[0].orderdetail.length; i++) {
        total =
          total +
          cartList[0].orderdetail[i].amount *
            cartList[0].orderdetail[i].quantity;
      }
    return total;
  }

  getShippingPriceFromCart() {
    const { cartList } = this.state;
    let total = 0;
    if (cartList.length > 0)
      for (let i = 0; i < cartList.length; i++) {
        total = cartList[i].shippingprice;
      }
    return total;
  }

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharge) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }

    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }

    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharge;
    } else {
      grandTotal = total + igst + shippingCharge;
    }
    return grandTotal;
  };

  getShippingCharges = (total) => {
    const { shippingCharges, isPincodeDeliveryEnable } = this.state;
    var charge = 0;
    if (isPincodeDeliveryEnable) {
      return this.getShippingPriceFromCart();
    }
    if (shippingCharges.length > 0) {
      for (let index = 0; index < shippingCharges.length; index++) {
        if (
          shippingCharges[index].id === 3 &&
          shippingCharges[index].status === 1
        ) {
          for (
            let jindex = 0;
            jindex < shippingCharges[index].options.length;
            jindex++
          ) {
            if (
              !shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total &&
              shippingCharges[index].options[jindex].maxValue >= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
            if (
              shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
          }
        }
      }
    }
    return charge;
  };

  removeProductFromCart = async (orderDetail, index) => {
    const {
      cartList,
      merchantInfo,
      isMerchantGSTEnable,
      currentCatelogueType,
    } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const total = this.getTotalofCart(cartList);
    const tempAmount = orderDetail.amount * orderDetail.quantity;
    const orderId = cartList[0].id;
    const removeProductFromCartUrl = "orders/" + orderId;
    var removeProductFromCartObj = {};
    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      removeProductFromCartObj = {
        totalamount: this.getGrandTotal(
          this.getTotalofCart(cartList) - tempAmount,
          this.getCGSTFromTotal(this.getTotalofCart(cartList) - tempAmount),
          this.getSGSTFromTotal(this.getTotalofCart(cartList) - tempAmount),
          this.getIGSTFromTotal(this.getTotalofCart(cartList) - tempAmount),
          this.getShippingCharges(this.getTotalofCart(cartList) - tempAmount)
        ),
        userId: id,
        inshoppingcart: 1,
        orderdetails: [
          {
            id: orderDetail.id,
            deletedAt: new Date(),
          },
        ],
        tax: {
          cgst: merchantInfo.CGST,
          sgst: merchantInfo.SGST,
          cgstPrice: this.getCGSTFromTotal(
            this.getTotalofCart(cartList) - tempAmount
          ),

          sgstPrice: this.getSGSTFromTotal(
            this.getTotalofCart(cartList) - tempAmount
          ),
        },
        baseprice: this.getTotalofCart(cartList) - tempAmount,
        shippingprice: this.getShippingCharges(
          this.getTotalofCart(cartList) - tempAmount
        ),
      };
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      removeProductFromCartObj = {
        totalamount: this.getGrandTotal(
          this.getTotalofCart(cartList) - tempAmount,
          this.getCGSTFromTotal(this.getTotalofCart(cartList) - tempAmount),
          this.getSGSTFromTotal(this.getTotalofCart(cartList) - tempAmount),
          this.getIGSTFromTotal(this.getTotalofCart(cartList) - tempAmount),
          this.getShippingCharges(this.getTotalofCart(cartList) - tempAmount)
        ),
        userId: id,
        inshoppingcart: 1,
        orderdetails: [
          {
            id: orderDetail.id,
            deletedAt: new Date(),
          },
        ],
        baseprice: this.getTotalofCart(cartList) - tempAmount,
        shippingprice: this.getShippingCharges(
          this.getTotalofCart(cartList) - tempAmount
        ),
      };
    } else {
      removeProductFromCartObj = {
        totalamount:
          total -
          tempAmount -
          this.getShippingCharges(this.getTotalofCart(cartList) - tempAmount),
        userId: id,
        inshoppingcart: 1,
        orderdetails: [
          {
            id: orderDetail.id,
            deletedAt: new Date(),
          },
        ],
        shippingprice: this.getShippingCharges(
          this.getTotalofCart(cartList) - tempAmount
        ),
      };
    }

    const removeProduct = await patchCall(
      removeProductFromCartUrl,
      removeProductFromCartObj
    );
    if (removeProduct.error) {
      this.loadErrorMessageAnimation(removeProduct.error.message);
      if (cartList.error.statusCode === 401) {
        this.removeUserDetailAndRedirectToLogin();
        return;
      }
    } else {
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_product_has_been_removed_successfully")
          ? getValueFromLanguageObject(
              "str_product_has_been_removed_successfully"
            )
          : "Product has been removed successfully!"
      );
      this.props.counter();
      this.setState({ isLoading: true }, () => {
        this.initCartList().then(() => {
          this.setState({ isLoading: false });
        });
      });
    }
  };

  initPincodeDelivery = async () => {
    const { masterDetailsObj } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const pincodeDelivery = await getCall(
      getPincodeDeliveryUrl(masterDetailsObj),
      []
    );
    if (pincodeDelivery.error) {
      this.loadErrorMessageAnimation(pincodeDelivery.error.message);
    } else {
      if (pincodeDelivery.length > 0) {
        const status = pincodeDelivery[0].status;
        this.setState({ isPincodeDeliveryEnable: status === 1 ? true : false });
      }
    }
  };

  onClickOfCloseIcon = (orderDetail, index) => {
    const { isInquiry } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        const submitClick = (props) => {
          this.setState({ isLoading: true }, () => {
            this.removeProductFromCart(orderDetail, index).then(() => {
              this.setState({ isLoading: false });
            });
            onClose();
          });
        };
        return (
          <>
            <ConfirmAlertPopup
              title={
                getValueFromLanguageObject("str_title_remove_product")
                  ? getValueFromLanguageObject("str_title_remove_product")
                  : "Remove Product"
              }
              message={
                getValueFromLanguageObject("str__remove_product")
                  ? getValueFromLanguageObject("str__remove_product")
                  : "Are you sure you want to remove this product?"
              }
              animation={<DeleteAnim />}
              submit={
                getValueFromLanguageObject("str__remove_it")
                  ? getValueFromLanguageObject("str__remove_it")
                  : "Yes, Remove it!"
              }
              submitClick={submitClick}
              cancelClick={onClose}
              cancel={
                getValueFromLanguageObject("str_no")
                  ? getValueFromLanguageObject("str_no")
                  : "No"
              }
            />
          </>
        );
      },
    });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const { symbol, isInquiry } = this.props;
    const {
      orderDetails,
      cartList,
      isLoading,
      isMerchantGSTEnable,
      AppTitle,
      currentCatelogueType,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      masterDetailsObj,
    } = this.state;
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>
            {AppTitle} |{" "}
            {getValueFromLanguageObject("str_cart_list_page")
              ? getValueFromLanguageObject("str_cart_list_page")
              : "Cart List Page"}
          </title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={"Cart Page"} />

        {orderDetails.length > 0 ? (
          <section className="cart-section section-b-space custom-cart">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table cart-table table-responsive-xs">
                    <thead>
                      <tr className="table-head">
                        <th
                          scope="col"
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_image_label_cart"
                            ),
                          }}
                        >
                          {getValueFromLanguageObject("str_image")
                            ? getValueFromLanguageObject("str_image")
                            : "image"}
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_product_name_label_cart"
                            ),
                          }}
                        >
                          {getValueFromLanguageObject("str_product_name")
                            ? getValueFromLanguageObject("str_product_name")
                            : "product name"}
                        </th>

                        {!isInquiry && (
                          <th
                            scope="col"
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_price_label_cart"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_price")
                              ? getValueFromLanguageObject("str_price")
                              : "price"}
                          </th>
                        )}
                        <th
                          scope="col"
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_quantity_label_cart"
                            ),
                          }}
                        >
                          {getValueFromLanguageObject("str_quantity")
                            ? getValueFromLanguageObject("str_quantity")
                            : "quantity"}
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_action_label_cart"
                            ),
                          }}
                        >
                          {getValueFromLanguageObject("str_action")
                            ? getValueFromLanguageObject("str_action")
                            : "action"}
                        </th>
                        {!isInquiry && (
                          <th
                            scope="col"
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_total_label_cart"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_total")
                              ? getValueFromLanguageObject("str_total")
                              : "total"}
                          </th>
                        )}
                      </tr>
                    </thead>
                    {orderDetails.map((item, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <Link to={`/singleproduct/${item.products.id}`}>
                                <img
                                  src={
                                    item.products.productmedia.length > 0
                                      ? getImageUrl(
                                          item.products.productmedia[0]
                                            .productname,
                                          masterDetailsObj
                                        )
                                      : getPlaceHolderImage()
                                  }
                                  alt=""
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = getPlaceHolderImage();
                                  }}
                                />
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/singleproduct/${item.products.id}`}
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_product_name_cart"
                                  ),
                                }}
                              >
                                {item.products.name}
                              </Link>
                              <div className="mobile-cart-content">
                                <div className="col-xs-3">
                                  <div className="qty-box">
                                    <div className="input-group">
                                      <input
                                        type="number"
                                        name="quantity"
                                        // onChange={(e) => {
                                        //   this.changeQty(item, index, e);
                                        // }}
                                        onBlur={(e) => {
                                          this.changeQty(item, index, e);
                                        }}
                                        value={item.quantity}
                                        className="form-control input-number"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-3">
                                  <b
                                    className="td-color"
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_price_cart"
                                      ),
                                    }}
                                  >
                                    {symbol}
                                    {parseFloat(item.amount).toFixed(2)}
                                  </b>
                                </div>
                                <div className="col-xs-3">
                                  <h2 className="td-color">
                                    <div
                                      className="icon"
                                      onClick={() => {
                                        this.onClickOfCloseIcon(item, index);
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash d-block"
                                        style={{
                                          fontSize: "16px",
                                          marginTop: "7px",
                                        }}
                                      />
                                    </div>
                                  </h2>
                                </div>
                              </div>
                            </td>
                            {!isInquiry && (
                              <td>
                                <b
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_price_cart"
                                    ),
                                  }}
                                >
                                  {symbol}
                                  {parseFloat(item.amount).toFixed(2)}
                                </b>
                              </td>
                            )}

                            <td>
                              <div className="qty-box">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button
                                      type="button"
                                      className="btn quantity-left-minus"
                                      onClick={() => this.minusQty(item, index)}
                                      data-type="minus"
                                      data-field=""
                                    >
                                      <i className="fa fa-angle-left" />
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    name="quantity"
                                    value={item.quantity}
                                    // onChange={}
                                    onBlur={(e) => {
                                      this.changeQty(item, index, e);
                                    }}
                                    className="form-control input-number"
                                  />
                                  <span className="input-group-prepend">
                                    <button
                                      className="btn quantity-right-plus"
                                      onClick={() => this.plusQty(item, index)}
                                      data-type="plus"
                                    >
                                      <i className="fa fa-angle-right" />
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className="icon"
                                onClick={() => {
                                  this.onClickOfCloseIcon(item, index);
                                }}
                              >
                                <i className="fa fa-trash" />
                              </div>
                            </td>

                            {!isInquiry && (
                              <td>
                                <b
                                  className="td-color"
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_total_cart"
                                    ),
                                  }}
                                >
                                  {symbol}
                                  {parseFloat(
                                    item.quantity * item.amount
                                  ).toFixed(2)}
                                </b>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                  <div class="row">
                    <div class="col-md-6" />
                    <div class="col-md-6">
                      {!isInquiry && (
                        <div class="cart-total">
                          <table className="table cart-table table-responsive-md">
                            <tfoot>
                              <tr>
                                <td
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_total_price_label_cart"
                                    ),
                                  }}
                                >
                                  {getValueFromLanguageObject(
                                    "str_total_price_with_colun"
                                  )
                                    ? getValueFromLanguageObject(
                                        "str_total_price_with_colun"
                                      )
                                    : "Total Price :"}
                                </td>
                                <td>
                                  <b
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_total_price_cart"
                                      ),
                                    }}
                                  >
                                    {symbol}{" "}
                                    {parseFloat(
                                      this.getTotalofCart(cartList)
                                    ).toFixed(2)}
                                  </b>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_shipping_charges_label_cart"
                                    ),
                                  }}
                                >
                                  {"+ "}
                                  {getValueFromLanguageObject(
                                    "str_shipping_charges_with_colun"
                                  )
                                    ? getValueFromLanguageObject(
                                        "str_shipping_charges_with_colun"
                                      )
                                    : "Shipping Charges : "}
                                </td>
                                <td>
                                  <b
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_shipping_charges_cart"
                                      ),
                                    }}
                                  >
                                    {symbol}{" "}
                                    {parseFloat(
                                      this.getShippingCharges(
                                        this.getTotalofCart(cartList)
                                      )
                                    ).toFixed(2)}
                                  </b>
                                </td>
                              </tr>

                              {isMerchantGSTEnable &&
                                currentCatelogueType &&
                                currentCatelogueType.registerallow !==
                                  "Catalogue_Jewellary" &&
                                this.state.merchantInfo.countryname ===
                                  "India" && (
                                  <tr>
                                    <td>
                                      {`+ ${
                                        getValueFromLanguageObject(
                                          "str_cgst_with_colun"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_cgst_with_colun"
                                            )
                                          : "CGST"
                                      } (${this.state.merchantInfo.CGST}%) :`}
                                    </td>
                                    <td>
                                      <b>
                                        {`${symbol} ${parseFloat(
                                          this.getCGSTFromTotal(
                                            this.getTotalofCart(cartList)
                                          )
                                        ).toFixed(2)}`}
                                      </b>
                                    </td>
                                  </tr>
                                )}
                              {isMerchantGSTEnable &&
                                currentCatelogueType &&
                                currentCatelogueType.registerallow !==
                                  "Catalogue_Jewellary" &&
                                this.state.merchantInfo.countryname ===
                                  "India" && (
                                  <tr>
                                    <td>
                                      {`+ ${
                                        getValueFromLanguageObject(
                                          "str_sgst_with_colun"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_sgst_with_colun"
                                            )
                                          : "SGST"
                                      } (${this.state.merchantInfo.SGST}%) : `}
                                    </td>
                                    <td>
                                      <b>
                                        {`${symbol} ${parseFloat(
                                          this.getSGSTFromTotal(
                                            this.getTotalofCart(cartList)
                                          )
                                        ).toFixed(2)}`}
                                      </b>
                                    </td>
                                  </tr>
                                )}
                              {isMerchantGSTEnable &&
                                currentCatelogueType &&
                                currentCatelogueType.registerallow !==
                                  "Catalogue_Jewellary" &&
                                this.state.merchantInfo.countryname !==
                                  "India" && (
                                  <tr>
                                    <td>
                                      {`+ ${
                                        getValueFromLanguageObject(
                                          "str_igst_with_colun"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_igst_with_colun"
                                            )
                                          : "IGST "
                                      } (${this.state.merchantInfo.IGST}%) : `}
                                    </td>
                                    <td>
                                      <b>
                                        {`${symbol} ${parseFloat(
                                          this.getIGSTFromTotal(
                                            this.getTotalofCart(cartList)
                                          )
                                        ).toFixed(2)}`}
                                      </b>
                                    </td>
                                  </tr>
                                )}
                              <tr>
                                <td>
                                  {`- ${
                                    getValueFromLanguageObject(
                                      "str_discount_with_colun"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_discount_with_colun"
                                        )
                                      : "Discount :"
                                  }`}
                                </td>
                                <td>
                                  <b>
                                    {`${symbol} ${parseFloat(0).toFixed(2)}`}
                                  </b>
                                </td>
                              </tr>

                              <tr className="order_total">
                                <td>
                                  {getValueFromLanguageObject(
                                    "str_amount_payable_with_colun"
                                  )
                                    ? getValueFromLanguageObject(
                                        "str_amount_payable_with_colun"
                                      )
                                    : "Amount Payable :"}
                                </td>

                                <td>
                                  <b
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_amount_payable_cart"
                                      ),
                                    }}
                                  >
                                    {symbol}{" "}
                                    {parseFloat(
                                      this.getGrandTotal(
                                        this.getTotalofCart(cartList),
                                        this.getCGSTFromTotal(
                                          this.getTotalofCart(cartList)
                                        ),
                                        this.getSGSTFromTotal(
                                          this.getTotalofCart(cartList)
                                        ),
                                        this.getIGSTFromTotal(
                                          this.getTotalofCart(cartList)
                                        ),
                                        this.getShippingCharges(
                                          this.getTotalofCart(cartList)
                                        )
                                      ),
                                      2
                                    ).toFixed(2)}
                                  </b>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row cart-buttons">
                <div className="col-sm-6">
                  <Link
                    to={`/products`}
                    className="btn btn-solid"
                    style={{
                      color: getColorCodeBasedOnKey(
                        "text_continue_shopping_cart"
                      ),
                      backgroundImage: `linear-gradient(30deg, ${getColorCodeBasedOnKey(
                        "bg_continue_shopping_cart"
                      )} 50%, transparent 50%)`,
                    }}
                  >
                    {getValueFromLanguageObject("str_continue_shopping")
                      ? getValueFromLanguageObject("str_continue_shopping")
                      : "continue shopping"}
                  </Link>
                </div>
                <div className="col-sm-6">
                  <Link
                    to={`/checkout`}
                    className="btn btn-solid"
                    style={{
                      color: getColorCodeBasedOnKey("text_checkout_cart"),
                      backgroundImage: `linear-gradient(30deg, ${getColorCodeBasedOnKey(
                        "bg_checkout_cart"
                      )} 50%, transparent 50%)`,
                    }}
                  >
                    {getValueFromLanguageObject("str_proceed_to_checkout")
                      ? getValueFromLanguageObject("str_proceed_to_checkout")
                      : "Proceed to checkout"}
                  </Link>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <NoProductFoundCartAnim />
        )}
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
    isInquiryOrCart: isInquiryOrCart,
  }
)(cartComponent);
