import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import { getCall, postCall, patchCall } from "../../../api/post";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getMerchantInformationUrl,
  getPaymentDetailsUrl,
  getOrderStatusUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import { Link } from "react-router-dom";
import { getNotificationCounter } from "../../../actions";
import { NotificationManager } from "react-notifications";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../../store";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import {
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import RepeatOrderAnim from "../../../animation/AnimFiles/RepeatOrderAnim";
import ConfirmAlertPopup from "../../../Alert/ConfirmAlertPopup";
import { isInquiryOrCart } from "../../../actions";

const publicUrl = window.location.pathname.split("/")[1];
const client = process.env.CLIENT_NAME;

class OrderSummary extends Component {
  state = {
    orderId: this.props.match.params.id,
    orderSummary: {},
    isLoading: true,
    orderNotes: "",
    isEditOrderNotesInputVisible: false,
    isMerchantGSTEnable: false,
    merchantInfo: {},
    shareUrl: "",
    paymentDetails: [],
    currentCatelogueType: {},
    orderStatusList: [],
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
  };
  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      this.props.history.push(`/login`);
      return;
    }

    this.initMasterDetailsId().then(() => {
      this.initOrderSummary().then(() => {
        this.setState({ isLoading: false });
        this.initShareLink();
      });
      this.initCatelogueSettings();
      this.initPaymentDetails();
      this.initOrderStatus();
      this.initMerchantInfo();
      this.props.notificationCounter();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };
  initInquirySummary = async () => {
    const { inquiryId } = this.state;
    const inquirySummaryUrl = `orders/${inquiryId} `;
    const inquirySummary = await getCall(inquirySummaryUrl, []);

    if (inquirySummary.error) {
      this.loadErrorMessageAnimation(inquirySummary.error.message);
      if (inquirySummary.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ inquirySummary, orderNotes: inquirySummary.description });
    }
  };
  initOrderSummary = async () => {
    const { orderId } = this.state;
    const orderSummaryUrl = `orders/${orderId}?filter[include]=orderdetails&filter[include]=productmedia`;
    const orderSummary = await getCall(orderSummaryUrl, []);
    if (orderSummary.error) {
      this.loadErrorMessageAnimation(orderSummary.error.message);
      if (orderSummary.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    }
    this.setState({ orderSummary, orderNotes: orderSummary.description });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.setState({ isMerchantGSTEnable: false, merchantInfo: {} });
    } else {
      this.setState({
        merchantInfo,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initPaymentDetails = async () => {
    const { masterDetailsObj } = this.state;
    const paymentDetailsUrl = getPaymentDetailsUrl(masterDetailsObj);
    const paymentDetails = await getCall(paymentDetailsUrl, []);
    if (paymentDetails.error) {
      this.loadErrorMessageAnimation(paymentDetails.error.message);
    } else {
      this.setState({ paymentDetails });
    }
  };

  initShareLink = async () => {
    const { orderId } = this.state;
    const shareLinkUrl = "products/shortnewURL";
    const link = `${window.location.host}/${
      client ? client : publicUrl
    }/o/${orderId}`;
    const shareLinkObj = {
      url: link,
    };
    const shareLinkResponse = await postCall(shareLinkUrl, shareLinkObj);
    if (shareLinkResponse.error) {
      this.loadErrorMessageAnimation(shareLinkResponse.error.message);
    } else {
      this.setState({ shareUrl: shareLinkResponse.shortUrl });
    }
  };

  initOrderStatus = async () => {
    const { masterDetailsObj } = this.state;
    const orderStatusUrl = getOrderStatusUrl(masterDetailsObj);
    const orderStatusList = await getCall(orderStatusUrl, []);
    if (orderStatusList.error) {
      this.loadErrorMessageAnimation(orderStatusList.error.message);
      if (orderStatusList.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ orderStatusList });
    }
  };

  getTotalofCart(cartList) {
    let total = 0;
    if (cartList.orderdetail)
      for (let i = 0; i < cartList.orderdetail.length; i++) {
        total =
          total +
          cartList.orderdetail[i].amount * cartList.orderdetail[i].quantity;
      }
    return total;
  }

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharges) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharges;
      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharges;
      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharges;
    } else {
      grandTotal = total + igst + shippingCharges;
    }
    return grandTotal;
  };

  renderBillingAddress = () => {
    const { orderSummary } = this.state;
    if (orderSummary !== undefined && orderSummary.address !== undefined) {
      const address = JSON.parse(orderSummary.address);
      if (Object.keys(address).length == 0) {
        return;
      }
      if (address !== undefined) {
        let billingAddress = {};
        try {
          billingAddress = address.billingaddress;
        } catch (error) {}

        return (
          <ul className="order-detail">
            <li style={{ fontWeight: 550 }}> Name : {billingAddress.name}</li>
            <li> Mobile : {billingAddress.mobile}</li>
            {billingAddress.email && <li> Email : {billingAddress.email}</li>}
            {billingAddress.companyname && (
              <li> Company Name : {billingAddress.companyname}</li>
            )}
            {billingAddress.gstin && <li> GSTIN : {billingAddress.gstin}</li>}

            {billingAddress.address1 && (
              <li>
                Address : {billingAddress.address1},{billingAddress.address2},
                {billingAddress.landmark},
              </li>
            )}
            <li>
              {billingAddress.cityname}, {billingAddress.statename},{" "}
              {billingAddress.countryname} - {billingAddress.zipcode}
            </li>
          </ul>
        );
      }
    }
  };

  renderShippingAddress = () => {
    const { orderSummary } = this.state;
    if (orderSummary !== undefined && orderSummary.address !== undefined) {
      const address = JSON.parse(orderSummary.address);
      if (Object.keys(address).length == 0) {
        return;
      }
      if (address !== undefined) {
        let shippingaddress = {};
        try {
          shippingaddress = address.shippingaddress;
        } catch (error) {}
        return (
          <ul className="order-detail">
            <li style={{ fontWeight: 550 }}> Name : {shippingaddress.name}</li>
            <li> Mobile : {shippingaddress.mobile}</li>
            {shippingaddress.email && <li> Email : {shippingaddress.email}</li>}
            {shippingaddress.companyname && (
              <li> Company Name : {shippingaddress.companyname}</li>
            )}
            {shippingaddress.gstin && <li> GSTIN : {shippingaddress.gstin}</li>}

            {shippingaddress.address1 && (
              <li>
                Address : {shippingaddress.address1},{shippingaddress.address2},
                {shippingaddress.landmark},
              </li>
            )}
            <li>
              {shippingaddress.cityname}, {shippingaddress.statename},{" "}
              {shippingaddress.countryname} - {shippingaddress.zipcode}
            </li>
          </ul>
        );
      }
    }
  };

  onClickOfRepeatOrder = () => {
    const { isInquiry } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        const submitClick = (props) => {
          this.setState({ isLoading: true }, () => {
            this.repeatOrder().then(() => {
              this.setState({ isLoading: false });
              onClose();
            });
          });
        };
        return (
          <>
            {isInquiry ? (
              <ConfirmAlertPopup
                title={
                  getValueFromLanguageObject("str_repeat_inquiry")
                    ? getValueFromLanguageObject("str_repeat_inquiry")
                    : "Repeat Inquiry"
                }
                message={
                  getValueFromLanguageObject(
                    "str_are_you_sure_you_want_to_repeat_inquiry"
                  )
                    ? getValueFromLanguageObject(
                        "str_are_you_sure_you_want_to_repeat_inquiry"
                      )
                    : "Are you sure you want to repeat this Inquiry?"
                }
                animation={<RepeatOrderAnim />}
                submit={
                  getValueFromLanguageObject("str_yes_repeat")
                    ? getValueFromLanguageObject("str_yes_repeat")
                    : "Yes, Repeat!"
                }
                submitClick={submitClick}
                cancelClick={onClose}
                cancel={
                  getValueFromLanguageObject("str_no")
                    ? getValueFromLanguageObject("str_no")
                    : "No"
                }
              />
            ) : (
              <ConfirmAlertPopup
                title={
                  getValueFromLanguageObject("str_repeat_order")
                    ? getValueFromLanguageObject("str_repeat_order")
                    : "Repeat Order"
                }
                message={"Are you sure you want to repeat this order?"}
                animation={<RepeatOrderAnim />}
                submit={
                  getValueFromLanguageObject("str_yes_repeat")
                    ? getValueFromLanguageObject("str_yes_repeat")
                    : "Yes, Repeat!"
                }
                submitClick={submitClick}
                cancelClick={onClose}
                cancel={
                  getValueFromLanguageObject("str_no")
                    ? getValueFromLanguageObject("str_no")
                    : "No"
                }
              />
            )}
          </>
          // <div>
          //   <h3>
          //     {getValueFromLanguageObject("str_repeat_order")
          //       ? getValueFromLanguageObject("str_repeat_order")
          //       : "Repeat Order"}
          //   </h3>
          //   <center>
          //     <div className="anim-container">
          //       <RepeatOrderAnim />
          //     </div>
          //     <p>
          //       {getValueFromLanguageObject("str_repeat_order_desc")
          //         ? getValueFromLanguageObject("str_repeat_order_desc")
          //         : "Are you sure you want to repeat this order?"}
          //     </p>
          //   </center>
          //   <div className="align-button">
          //     <button
          //       className="btn btn-solid"
          //       onClick={() => {
          //         this.setState({ isLoading: true }, () => {
          //           this.repeatOrder().then(() => {
          //             this.setState({ isLoading: false });
          //             onClose();
          //           });
          //         });
          //       }}
          //     >
          //       {getValueFromLanguageObject("str_yes_repeat")
          //         ? getValueFromLanguageObject("str_yes_repeat")
          //         : "Yes, Repeat!"}
          //     </button>
          //     <div className="divider" />
          //     <button className="btn btn-solid" onClick={onClose}>
          //       {getValueFromLanguageObject("str_no")
          //         ? getValueFromLanguageObject("str_no")
          //         : "No"}
          //     </button>
          //   </div>
          // </div>
        );
      },
    });
  };

  repeatOrder = async () => {
    const { isInquiry } = this.props;
    const { orderId } = this.state;
    const repeatOrderUrl = `orders/repeatOrder`;
    const repeatOrderObj = { orderId: orderId };

    const repeatOrder = await postCall(repeatOrderUrl, repeatOrderObj);
    if (repeatOrder.error) {
      this.loadErrorMessageAnimation(repeatOrder.error.message);
      if (repeatOrder.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("str_your_inquiry_placed_successfully")
            ? getValueFromLanguageObject("str_your_inquiry_placed_successfully")
            : "Your inquiry placed successfully"
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("str_your_inquiry_placed_successfully")
            ? getValueFromLanguageObject("str_your_inquiry_placed_successfully")
            : "Your inquiry placed successfully"
        );
      }
    }
  };

  onClickOfEditOrderNotes = (description) => {
    const { orderSummary } = this.state;
    const { isInquiry } = this.props;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="mt-3">
            <center>
              <h3>UPDATE ORDER NOTES</h3>

              <input
                placeholder="Update Your Order Notes"
                className="form-control"
                type="text"
                defaultValue={orderSummary.description}
                onChange={(e) => {
                  orderSummary.description = e.target.value;
                  //this.setState({ orderSummary });
                }}
              />

              <li className="justify-content-end align-button">
                <button
                  className="btn btn-solid"
                  onClick={() => {
                    this.setState({ isLoading: true }, () => {
                      this.editOrderNotesApi().then(() => {
                        this.setState({ isLoading: false, orderSummary });
                      });
                    });
                    onClose();
                  }}
                >
                  {getValueFromLanguageObject("str_update")
                    ? getValueFromLanguageObject("str_update")
                    : "Update"}
                </button>
                <div className="divider" />
                <button
                  className="btn btn-solid align-button"
                  onClick={() => {
                    this.onClickOfEditOrderNotes();
                    onClose();
                  }}
                  style={{
                    color: getColorCodeBasedOnKey("text_cancel_order_detail"),
                    backgroundImage: `linear-gradient(30deg, ${getColorCodeBasedOnKey(
                      "bg_cancel_order_detail"
                    )} 50%, transparent 50%)`,
                  }}
                >
                  {getValueFromLanguageObject("str_cancel")
                    ? getValueFromLanguageObject("str_cancel")
                    : "Cancel"}
                </button>
              </li>
            </center>
          </div>
        );
      },
    });
  };

  // onClickOfEditOrderNotes = () => {
  //   const { isEditOrderNotesInputVisible } = this.state;
  //   this.setState({
  //     isEditOrderNotesInputVisible: !isEditOrderNotesInputVisible,
  //   });
  // };

  renderPriceBreakdownSJ = (other) => {
    const jwelleryData = JSON.parse(other);
    if (jwelleryData.jewelleryData) {
      return (
        <div className="col-12" style={{ textAlign: "end" }}>
          <h4>Price Breakdown</h4>
          <p>
            {jwelleryData.jewelleryData.name} :{" "}
            {jwelleryData.jewelleryData.weightintoprice}
          </p>
          {jwelleryData.jewelleryData.makingcharges &&
            jwelleryData.jewelleryData.makingcharges.map((makingcharge) => {
              return (
                <p>
                  {makingcharge.pricelable} : {makingcharge.amount}
                </p>
              );
            })}
        </div>
      );
    } else {
      return <div />;
    }
  };

  editOrderNotesApi = async () => {
    const { orderSummary, orderId, isEditOrderNotesInputVisible } = this.state;
    const editOrderNotesUrl = "orders";
    const editOrderObj = {
      id: orderId,
      description: orderSummary.description,
      created: new Date(),
      modified: new Date(),
    };
    const editOrderNotes = await patchCall(editOrderNotesUrl, editOrderObj);
    if (editOrderNotes.error) {
      this.loadErrorMessageAnimation(editOrderNotes.error.message);
      if (editOrderNotes.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
      this.setState({ orderSummary: orderSummary });
    } else {
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_order_notes_edited_sucessfully")
          ? getValueFromLanguageObject("str_order_notes_edited_sucessfully")
          : "Order notes edited sucessfully"
      );
      this.setState({
        orderSummary: orderSummary,
        isEditOrderNotesInputVisible: !isEditOrderNotesInputVisible,
      });
    }
  };

  renderEditIcon = () => {
    const { orderSummary, orderStatusList, orderId } = this.state;
    return (
      <>
        {orderStatusList.map((orderStatusItem) => {
          return (
            orderSummary &&
            orderStatusItem.id === orderSummary.orderstatus &&
            orderStatusItem.status === "PENDING" && (
              <div className="icon">
                <Link to={`/editorder/${orderId}`}>
                  <i className="fa fa-pencil" />
                </Link>
              </div>
            )
          );
        })}
      </>
    );
  };

  renderEditOrderNotesIcon = () => {
    const { orderSummary, orderStatusList } = this.state;
    return (
      <>
        {orderStatusList.map((orderStatusItem) => {
          return (
            orderSummary &&
            orderSummary.description &&
            orderStatusItem.id === orderSummary.orderstatus &&
            orderStatusItem.status === "PENDING" && (
              <div
                className="icon"
                onClick={() => this.onClickOfEditOrderNotes()}
              >
                <i className="fa fa-pencil" />
              </div>
            )
          );
        })}
      </>
    );
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      orderSummary,
      isLoading,
      isEditOrderNotesInputVisible,
      isMerchantGSTEnable,
      shareUrl,
      paymentDetails,
      currentCatelogueType,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      masterDetailsObj,
    } = this.state;
    const { symbol } = this.props;
    const { isInquiry } = this.props;

    return (
      <>
        <div>
          <section className="section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-order order-success-sec">
                    <h4
                      className="f22"
                      style={{
                        color: getColorCodeBasedOnKey(
                          "text_your_order_details_order_detail"
                        ),
                      }}
                    >
                      {isInquiry
                        ? getValueFromLanguageObject("str_your_inquiry_details")
                          ? getValueFromLanguageObject(
                              "str_your_inquiry_details"
                            )
                          : "your inquiry details"
                        : getValueFromLanguageObject("str_your_order_details")
                        ? getValueFromLanguageObject("str_your_order_details")
                        : "your order details"}

                      {this.renderEditIcon()}
                    </h4>

                    {orderSummary.orderdetail &&
                      orderSummary.orderdetail.map((item, index) => {
                        return (
                          <div className="row product-order-detail" key={index}>
                            <div className="col-12 col-sm-2">
                              <img
                                src={
                                  item.products.productmedia.length > 0
                                    ? getImageUrl(
                                        item.products.productmedia[0]
                                          .productname,
                                        masterDetailsObj
                                      )
                                    : getPlaceHolderImage()
                                }
                                alt=""
                                className="img-fluid"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = getPlaceHolderImage();
                                }}
                              />
                            </div>
                            <div className="col-12 col-sm-4 order_detail">
                              <div>
                                <h4
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_product_name_label_order_detail"
                                    ),
                                  }}
                                >
                                  {getValueFromLanguageObject(
                                    "str_product_name"
                                  )
                                    ? getValueFromLanguageObject(
                                        "str_product_name"
                                      )
                                    : "product name"}
                                </h4>
                                <h5
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_product_name_order_detail"
                                    ),
                                  }}
                                >
                                  {item.products.name}
                                </h5>
                              </div>
                            </div>
                            <div className="col-6 col-sm-3 order_detail">
                              <div>
                                <h4
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_quantity_label_order_detail"
                                    ),
                                  }}
                                >
                                  {getValueFromLanguageObject("str_quantity")
                                    ? getValueFromLanguageObject("str_quantity")
                                    : "quantity"}
                                </h4>
                                <h5
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_quantity_order_detail"
                                    ),
                                  }}
                                >
                                  {item.quantity}
                                </h5>
                              </div>
                            </div>
                            {!isInquiry && (
                              <div className="col-6 col-sm-3 order_detail">
                                <div>
                                  <h4
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_price_label_order_detail"
                                      ),
                                    }}
                                  >
                                    {getValueFromLanguageObject("str_price")
                                      ? getValueFromLanguageObject("str_price")
                                      : "price"}
                                  </h4>
                                  <h5
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_price_order_detail"
                                      ),
                                    }}
                                  >
                                    {symbol}
                                    {parseFloat(item.amount).toFixed(2)}
                                  </h5>
                                </div>
                              </div>
                            )}

                            {currentCatelogueType &&
                              currentCatelogueType.registerallow ===
                                "Catalogue_Jewellary" &&
                              item.products.other &&
                              item.products.other &&
                              this.renderPriceBreakdownSJ(item.products.other)}
                          </div>
                        );
                      })}
                    {!isInquiry && (
                      <div className="total-sec">
                        <div className="final-total">
                          <h4
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_total_label_order_detail"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_total")
                              ? getValueFromLanguageObject("str_total")
                              : "total"}
                            <span
                              style={{
                                color: getColorCodeBasedOnKey(
                                  "text_total_order_detail"
                                ),
                              }}
                            >
                              {symbol}{" "}
                              {parseFloat(
                                this.getTotalofCart(orderSummary)
                              ).toFixed(2)}
                            </span>
                          </h4>
                          <h4
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_total_label_order_detail"
                              ),
                            }}
                          >
                            {"+"}{" "}
                            {getValueFromLanguageObject("str_shipping_charges")
                              ? getValueFromLanguageObject(
                                  "str_shipping_charges"
                                )
                              : "Shipping Charges"}
                            <span
                              style={{
                                color: getColorCodeBasedOnKey(
                                  "text_total_order_detail"
                                ),
                              }}
                            >
                              {symbol}{" "}
                              {parseFloat(
                                orderSummary.shippingprice
                                  ? orderSummary.shippingprice
                                  : 0
                              ).toFixed(2)}
                            </span>
                          </h4>
                          {isMerchantGSTEnable &&
                            currentCatelogueType &&
                            currentCatelogueType.registerallow !==
                              "Catalogue_Jewellary" &&
                            this.state.merchantInfo.countryname === "India" && (
                              <h4
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_total_label_order_detail"
                                  ),
                                }}
                              >
                                {"+"}{" "}
                                {getValueFromLanguageObject("str_cgst")
                                  ? getValueFromLanguageObject("str_cgst")
                                  : "CGST"}
                                {"("}
                                {this.state.merchantInfo.CGST}
                                {"%"}
                                {")"}
                                <span
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_total_order_detail"
                                    ),
                                  }}
                                >
                                  {symbol}{" "}
                                  {parseFloat(
                                    this.getCGSTFromTotal(
                                      this.getTotalofCart(orderSummary)
                                    )
                                  ).toFixed(2)}
                                </span>
                              </h4>
                            )}
                          {isMerchantGSTEnable &&
                            currentCatelogueType &&
                            currentCatelogueType.registerallow !==
                              "Catalogue_Jewellary" &&
                            this.state.merchantInfo.countryname === "India" && (
                              <h4
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_total_label_order_detail"
                                  ),
                                }}
                              >
                                {"+"}{" "}
                                {getValueFromLanguageObject("str_sgst")
                                  ? getValueFromLanguageObject("str_sgst")
                                  : "SGST"}
                                {"("}
                                {this.state.merchantInfo.SGST}
                                {"%"}
                                {")"}
                                <span
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_total_order_detail"
                                    ),
                                  }}
                                >
                                  {symbol}{" "}
                                  {parseFloat(
                                    this.getSGSTFromTotal(
                                      this.getTotalofCart(orderSummary)
                                    )
                                  ).toFixed(2)}
                                </span>
                              </h4>
                            )}
                          {isMerchantGSTEnable &&
                            currentCatelogueType &&
                            currentCatelogueType.registerallow !==
                              "Catalogue_Jewellary" &&
                            this.state.merchantInfo.countryname !== "India" && (
                              <h4
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_total_label_order_detail"
                                  ),
                                }}
                              >
                                {"+ "}{" "}
                                {getValueFromLanguageObject("str_igst")
                                  ? getValueFromLanguageObject("str_igst")
                                  : "IGST"}{" "}
                                {"("}
                                {this.state.merchantInfo.IGST}
                                {"%"}
                                {")"}
                                <span
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_total_order_detail"
                                    ),
                                  }}
                                >
                                  {symbol}{" "}
                                  {parseFloat(
                                    this.getIGSTFromTotal(
                                      this.getTotalofCart(orderSummary)
                                    )
                                  ).toFixed(2)}
                                </span>
                              </h4>
                            )}
                          <h4
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_total_label_order_detail"
                              ),
                            }}
                          >
                            {"-"}{" "}
                            {getValueFromLanguageObject("str_discount")
                              ? getValueFromLanguageObject("str_discount")
                              : "Discount"}
                            <span
                              style={{
                                color: getColorCodeBasedOnKey(
                                  "text_total_order_detail"
                                ),
                              }}
                            >
                              {symbol}
                              {parseFloat(0).toFixed(2)}
                            </span>
                          </h4>
                          <h4
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_total_label_order_detail"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_amount_payable")
                              ? getValueFromLanguageObject("str_amount_payable")
                              : "Amount Payable"}
                            <span
                              style={{
                                color: getColorCodeBasedOnKey(
                                  "text_total_order_detail"
                                ),
                              }}
                            >
                              {symbol}{" "}
                              {parseFloat(
                                this.getGrandTotal(
                                  this.getTotalofCart(orderSummary),
                                  this.getCGSTFromTotal(
                                    this.getTotalofCart(orderSummary)
                                  ),
                                  this.getSGSTFromTotal(
                                    this.getTotalofCart(orderSummary)
                                  ),
                                  this.getIGSTFromTotal(
                                    this.getTotalofCart(orderSummary)
                                  ),
                                  orderSummary.shippingprice
                                )
                              ).toFixed(2)}
                            </span>
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row order-success-sec">
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                      <h4
                        className="f22"
                        style={{
                          color: getColorCodeBasedOnKey(
                            "text_summery_order_detail"
                          ),
                        }}
                      >
                        {getValueFromLanguageObject("str_summery")
                          ? getValueFromLanguageObject("str_summery")
                          : "summery"}
                      </h4>
                      <ul className="order-detail">
                        <li
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_order_id_order_detail"
                            ),
                          }}
                        >
                          {isInquiry
                            ? getValueFromLanguageObject("str_inquiry_id")
                              ? getValueFromLanguageObject("str_inquiry_id")
                              : "Inquiry ID"
                            : getValueFromLanguageObject("str_order_id")
                            ? getValueFromLanguageObject("str_order_id")
                            : "Order ID"}
                          : {orderSummary.orderno}
                        </li>
                        <li
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_order_date_order_detail"
                            ),
                          }}
                        >
                          {isInquiry
                            ? getValueFromLanguageObject("str_inquiry_date")
                              ? getValueFromLanguageObject("str_inquiry_date")
                              : "Inquiry Date"
                            : getValueFromLanguageObject("str_order_date")
                            ? getValueFromLanguageObject("str_order_date")
                            : "Order Date"}
                          :
                          <Moment format="DD-MMM-YYYY">
                            {orderSummary.date}
                          </Moment>
                        </li>
                        {!isInquiry && (
                          <li
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_delivery_date_order_detail"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_delivery_date")
                              ? getValueFromLanguageObject("str_delivery_date")
                              : "Delivery Date"}
                            :
                            <Moment format="DD-MMM-YYYY">
                              {orderSummary.deliverydate}
                            </Moment>
                          </li>
                        )}
                        {!isInquiry && (
                          <li
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_order_total_order_detail"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_order_total")
                              ? getValueFromLanguageObject("str_order_total")
                              : "Order Total"}
                            : {symbol}
                            {parseFloat(
                              this.getGrandTotal(
                                this.getTotalofCart(orderSummary),
                                this.getCGSTFromTotal(
                                  this.getTotalofCart(orderSummary)
                                ),
                                this.getSGSTFromTotal(
                                  this.getTotalofCart(orderSummary)
                                ),
                                this.getIGSTFromTotal(
                                  this.getTotalofCart(orderSummary)
                                ),
                                orderSummary.shippingprice
                              )
                            ).toFixed(2)}
                          </li>
                        )}
                        {/* <li
                        style={{
                          color: getColorCodeBasedOnKey(
                            "text_company_name_order_detail"
                          ),
                        }}
                      >
                        {getValueFromLanguageObject("str_company_name")
                          ? getValueFromLanguageObject("str_company_name")
                          : "Company Name"}
                        :
                        {orderSummary.orderuser
                          ? orderSummary.orderuser.companyname
                          : "-"}
                      </li> */}
                        {/* <li
                        style={{
                          color: getColorCodeBasedOnKey(
                            "text_gstin_order_detail"
                          ),
                        }}
                      >
                        {getValueFromLanguageObject("str_gstin")
                          ? getValueFromLanguageObject("str_gstin")
                          : "GST IN"}
                        : {orderSummary.gstin || "-"}
                      </li> */}
                        {!isInquiry && (
                          <li
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_order_notes_order_detail"
                              ),
                            }}
                          >
                            <b>
                              {getValueFromLanguageObject("str_order_notes")
                                ? getValueFromLanguageObject("str_order_notes")
                                : "Order Notes"}
                              :{this.renderEditOrderNotesIcon()}
                            </b>
                          </li>
                        )}
                        <li>{orderSummary.description || "-"}</li>
                        {/* {isEditOrderNotesInputVisible && (
                          <div className="mt-3">
                            <li>
                              <input
                                className="form-control"
                                type="text"
                                value={orderSummary.description}
                                onChange={(e) => {
                                  orderSummary.description = e.target.value;
                                  this.setState({ orderSummary });
                                }}
                              />
                            </li>
                            <li className="justify-content-end mt-3">
                              <button
                                className="btn btn-solid mr-2"
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_update_order_detail"
                                  ),
                                  backgroundImage: `linear-gradient(30deg, ${getColorCodeBasedOnKey(
                                    "bg_update_order_detail"
                                  )} 50%, transparent 50%)`,
                                }}
                                onClick={() => {
                                  this.setState({ isLoading: true }, () => {
                                    this.editOrderNotesApi().then(() => {
                                      this.setState({ isLoading: false });
                                    });
                                  });
                                }}
                              >
                                {getValueFromLanguageObject("str_update")
                                  ? getValueFromLanguageObject("str_update")
                                  : "Update"}
                              </button>
                              <button
                                className="btn btn-solid"
                                onClick={() => {
                                  this.onClickOfEditOrderNotes();
                                }}
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_cancel_order_detail"
                                  ),
                                  backgroundImage: `linear-gradient(30deg, ${getColorCodeBasedOnKey(
                                    "bg_cancel_order_detail"
                                  )} 50%, transparent 50%)`,
                                }}
                              >
                                {getValueFromLanguageObject("str_cancel")
                                  ? getValueFromLanguageObject("str_cancel")
                                  : "Cancel"}
                              </button>
                            </li>
                          </div>
                        )} */}
                      </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                      <h4
                        className="f22"
                        style={{
                          color: getColorCodeBasedOnKey(
                            "text_summery_order_detail"
                          ),
                        }}
                      >
                        {getValueFromLanguageObject("str_customer_details")
                          ? getValueFromLanguageObject("str_customer_details")
                          : "Customer Details"}
                      </h4>
                      <ul className="order-detail">
                        <li
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_order_id_order_detail"
                            ),
                          }}
                        >
                          {getValueFromLanguageObject("str_name")
                            ? getValueFromLanguageObject("str_name")
                            : "Name"}
                          :{" "}
                          {orderSummary.orderuser &&
                            orderSummary.orderuser.username}
                        </li>
                        <li
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_order_id_order_detail"
                            ),
                          }}
                        >
                          {getValueFromLanguageObject("str_mobile_number")
                            ? getValueFromLanguageObject("str_mobile_number")
                            : "Mobile"}
                          :{" "}
                          {orderSummary.orderuser &&
                            orderSummary.orderuser.cellnumber}
                        </li>
                        {orderSummary.orderuser &&
                          orderSummary.orderuser.cityname && (
                            <li
                              style={{
                                color: getColorCodeBasedOnKey(
                                  "text_order_id_order_detail"
                                ),
                              }}
                            >
                              {getValueFromLanguageObject("str_city")
                                ? getValueFromLanguageObject("str_city")
                                : "City"}
                              : {orderSummary.orderuser.cityname}
                            </li>
                          )}
                      </ul>
                    </div>
                    {paymentDetails &&
                      paymentDetails.length > 0 &&
                      paymentDetails[0].status === 1 &&
                      !isInquiry && (
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                          <h4
                            className="f22"
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_summery_order_detail"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_payment_details")
                              ? getValueFromLanguageObject(
                                  "str_payment_details"
                                )
                              : "Payment Details"}
                          </h4>
                          <ul className="order-detail">
                            <li
                              style={{
                                color: getColorCodeBasedOnKey(
                                  "text_order_id_order_detail"
                                ),
                              }}
                            >
                              {getValueFromLanguageObject("str_pay_via")
                                ? getValueFromLanguageObject("str_pay_via")
                                : "Pay Via"}
                              :{" "}
                              {orderSummary.paymentDetail &&
                              JSON.parse(orderSummary.paymentDetail).pay_via !==
                                "COD"
                                ? JSON.parse(orderSummary.paymentDetail).pay_via
                                : "Cash On Delivery(COD)"}
                            </li>
                            <li
                              style={{
                                color: getColorCodeBasedOnKey(
                                  "text_order_id_order_detail"
                                ),
                              }}
                            >
                              {getValueFromLanguageObject("str_payment_id")
                                ? getValueFromLanguageObject("str_payment_id")
                                : "Payment ID"}
                              :{" "}
                              {orderSummary.paymentDetail &&
                              JSON.parse(orderSummary.paymentDetail)
                                .transaction_no !== ""
                                ? JSON.parse(orderSummary.paymentDetail)
                                    .transaction_no
                                : "N/A"}
                            </li>
                          </ul>
                        </div>
                      )}
                    {!isInquiry && (
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <h4
                          className="f22"
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_shipping_address_label_order_detail"
                            ),
                          }}
                        >
                          {getValueFromLanguageObject("shipping_address")
                            ? getValueFromLanguageObject("shipping_address")
                            : "shipping address"}
                        </h4>
                        {this.renderBillingAddress()}
                      </div>
                    )}
                    {!isInquiry && (
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <h4
                          className="f22"
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_billing_address_label_order_detail"
                            ),
                          }}
                        >
                          {getValueFromLanguageObject("billing_address")
                            ? getValueFromLanguageObject("billing_address")
                            : "billing address"}
                        </h4>
                        {this.renderShippingAddress()}
                      </div>
                    )}

                    {/* <div className="col-sm-12 payment-mode">
                                        <h4>payment method</h4>
                                        <p>Pay on Delivery (Cash/Card). Cash on delivery (COD) available. Card/Net
                                            banking acceptance subject to device availability.</p>
                                    </div> */}
                    <div className="col-sm-12 mb-3">
                      <div
                        className="delivery-sec"
                        onClick={() => {
                          this.onClickOfRepeatOrder();
                        }}
                        style={{
                          backgroundColor: getColorCodeBasedOnKey(
                            "bg_repeat_order_order_detail"
                          ),
                        }}
                      >
                        {isInquiry ? (
                          <h5
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_buy_products_again_order_detail"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject(
                              "str_place_inquiry_again"
                            )
                              ? getValueFromLanguageObject(
                                  "str_place_inquiry_again"
                                )
                              : "Place Inquiry Again?"}
                          </h5>
                        ) : (
                          <h5
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_buy_products_again_order_detail"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject(
                              "str_buy_products_again"
                            )
                              ? getValueFromLanguageObject(
                                  "str_buy_products_again"
                                )
                              : "Buy products Again?"}
                          </h5>
                        )}
                        {isInquiry ? (
                          <h2
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_repeat_order_order_detail"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_re_inquiry")
                              ? getValueFromLanguageObject("str_re_inquiry")
                              : "Repeat Inquiry"}
                          </h2>
                        ) : (
                          <h2
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_repeat_order_order_detail"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_re_order")
                              ? getValueFromLanguageObject("str_re_order")
                              : "Repeat Order"}
                          </h2>
                        )}
                      </div>
                    </div>

                    {shareUrl !== "" && (
                      <div className="col-sm-12 mb-3">
                        <div className="border-product text-center">
                          <h6 className="product-title text-center">
                            Share This Order :
                          </h6>
                          <div className="product-icon">
                            <ul className="product-social">
                              <TelegramShareButton url={shareUrl}>
                                <TelegramIcon size={42} round />
                              </TelegramShareButton>
                              <WhatsappShareButton url={shareUrl}>
                                <WhatsappIcon size={42} round />
                              </WhatsappShareButton>
                              <LinkedinShareButton url={shareUrl}>
                                <LinkedinIcon size={42} round />
                              </LinkedinShareButton>

                              <EmailShareButton
                                url={shareUrl}
                                subject={"Order Link"}
                                body={""}
                              >
                                <EmailIcon size={42} round />
                              </EmailShareButton>

                              <div className="col-sm-12 mt-3">
                                <CopyToClipboard
                                  text={shareUrl}
                                  onCopy={() => {
                                    NotificationManager.success(
                                      "Order Link Copied to Clipboard!"
                                    );
                                  }}
                                >
                                  <button className="btn">
                                    Copy To Clipboard
                                  </button>
                                </CopyToClipboard>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    notificationCounter: getNotificationCounter,
    isInquiryOrCart: isInquiryOrCart,
  }
)(OrderSummary);
// export default OrderSummary
