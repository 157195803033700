import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductListItem from "./product-list-item";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
} from "../../../store";
import { postCall, getCall } from "../../../api/post";
import { getCommonCounter } from "../../../actions";
import {
  getPincodeDeliveryUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import AddToCartAnim from "../../../animation/AddToCartAnim";

class ProductListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 20,
      hasMoreItems: true,
      isPincodeDeliveryEnable: false,
      showErrorMessageModal: false,
      errorMessage: "",
      showSuccessMessageModal: false,
      successMessage: "",
      showAddToCartMessageModal: false,
      addToCartMessage: "",
      masterDetailsObj: {},
    };
  }

  componentWillMount() {
    this.initMasterDetailsId().then(() => {
      this.fetchMoreItems();
      this.initPincodeDelivery();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  fetchMoreItems = () => {
    const { productList } = this.props;
    if (this.state.limit > productList.length) {
      this.setState({ hasMoreItems: false });
      return;
    }
    this.props.handlePagination(productList.length);
  };

  addToCart = async (product, qty, isInquiry) => {
    try {
      this.props.handleLoader(true);
      const userDetails = loadUserDetailsFromLocalStorage();

      const addtocartUrl = isInquiry
        ? "orders/productInquiry"
        : "orders/addtocart";

      let addtocartObj = {
        orderstatus: 1,
        inshoppingcart: 1,
        userId: userDetails.id,
        totalamount: isInquiry ? 0 : product.price * qty,
        orderdetails: [
          {
            quantity: qty,
            productId: product.id,
          },
        ],
        cityId: userDetails.cityId,
        date: new Date(),
      };

      const addToCartResponse = await postCall(addtocartUrl, addtocartObj);
      this.props.handleLoader(false);
      if (addToCartResponse.error) {
        this.loadErrorMessageAnimation(addToCartResponse.error.message);
        if (addToCartResponse.error.statusCode === 401) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("apiToken");
          this.props.history.push(`/login`);
          return;
        }
      } else {
        if (isInquiry) {
          this.loadAddToCartMessageAnimation(
            getValueFromLanguageObject("str_add_to_inquiry_successfully")
          );
        } else {
          this.loadAddToCartMessageAnimation(
            getValueFromLanguageObject("str_add_to_cart_successfully")
          );
        }
      }
      this.props.counter();
    } catch (error) {
      this.props.handleLoader(false);
    }
  };

  initPincodeDelivery = async () => {
    const { masterDetailsObj } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const pincodeDelivery = await getCall(
      getPincodeDeliveryUrl(masterDetailsObj),
      []
    );
    if (pincodeDelivery.error) {
      this.loadErrorMessageAnimation(pincodeDelivery.error.message);
    } else {
      if (pincodeDelivery.length > 0) {
        const status = pincodeDelivery[0].status;
        this.setState({ isPincodeDeliveryEnable: status === 1 ? true : false });
      }
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  loadAddToCartMessageAnimation = (message) => {
    this.setState({
      addToCartMessage: message,
      showAddToCartMessageModal: true,
    });
  };

  render() {
    const { productList, symbol } = this.props;
    const {
      isPincodeDeliveryEnable,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      showAddToCartMessageModal,
      addToCartMessage,
    } = this.state;
    return (
      <>
        <div>
          <div className="product-wrapper-grid">
            <div className="container-fluid">
              {productList.length > 0 ? (
                <InfiniteScroll
                  dataLength={productList.length} //This is important field to render the next data
                  next={this.fetchMoreItems}
                  hasMore={this.state.hasMoreItems}
                  loader={<div className="loading-cls" />}
                  endMessage={
                    ""
                    // <p className="seen-cls seen-it-cls">
                    //     <b>Yay! You have seen it all</b>
                    // </p>
                  }
                >
                  <div className="row">
                    {productList
                      .slice(0, this.props.productList.length)
                      .map((product, index) => (
                        <div
                          className={`${
                            this.props.colSize === 3
                              ? "col-xl-3 col-md-6 col-grid-box"
                              : "col-lg-" + this.props.colSize
                          }`}
                          key={index}
                        >
                          <ProductListItem
                            product={product}
                            symbol={symbol}
                            onAddToCartClicked={this.addToCart}
                            isPincodeDeliveryEnable={isPincodeDeliveryEnable}
                            key={index}
                          />
                        </div>
                      ))}
                  </div>
                </InfiniteScroll>
              ) : (
                <div className="row">
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/empty-search.jpg`}
                      className="img-fluid mb-4"
                      alt=""
                    />
                    <h3>
                      Sorry! Couldn't find the product you were looking For!!!{" "}
                    </h3>
                    <p>
                      Please check if you have misspelt something or try
                      searching with other words.
                    </p>
                    <Link to={`/products`} className="btn btn-solid">
                      continue shopping
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}

        {showAddToCartMessageModal && (
          <AddToCartAnim
            show={showAddToCartMessageModal}
            message={addToCartMessage}
            onClose={() => {
              this.setState({ showAddToCartMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
});

export default connect(
  mapStateToProps,
  { counter: getCommonCounter }
)(ProductListing);
