import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../store";

class Breadcrumb extends Component {
  render() {
    const { title, parent } = this.props;
    return (
      <div className="breadcrumb-section" style={{ backgroundColor: getColorCodeBasedOnKey("bg_breadcrumb") }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="page-title"
                style={{ color: getColorCodeBasedOnKey("text_main_title") }}
              >
                <h2>{title}</h2>
              </div>
            </div>
            <div className="col-md-6">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb">
                  <li
                    className="breadcrumb-item"
                    style={{
                      color: getColorCodeBasedOnKey("text_relative_title"),
                    }}
                  >
                    <Link to={`/`}>
                      {getValueFromLanguageObject("str_home")
                        ? getValueFromLanguageObject("str_home")
                        : "Home"}
                    </Link>
                  </li>
                  {parent ? (
                    <li className="breadcrumb-item" aria-current="page">
                      {parent}
                    </li>
                  ) : (
                    ""
                  )}
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    style={{
                      color: getColorCodeBasedOnKey("text_actual_page_title"),
                    }}
                  >
                    {title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumb;
