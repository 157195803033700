import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import EllipsisText from "react-ellipsis-text";
import { getCall } from "../../../api/post";
import { connect } from "react-redux";
import {
  getImageUrl,
  getPlaceHolderImage,
  getMasterDetailsIdUrl,
} from "../../../services";
import { isInquiryOrCart } from "../../../actions";
import {
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
  loadUserDetailsFromLocalStorage,
} from "../../../store";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";

class ProductItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock:
        props.product.availablequantity > 0
          ? getValueFromLanguageObject("str_instock")
            ? getValueFromLanguageObject("str_instock")
            : "InStock"
          : getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock !",
      quantity:
        props.product.minimumorderquantity >= 1
          ? props.product.minimumorderquantity
          : 1,
      image: "",
      currentProduct: {},
      category: {},
      pincode: "",
      showErrorMessageModal: false,
      errorMessage: "",
      masterDetailsObj: {},
    };
  }

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initSingleCategry();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initSingleCategry = async () => {
    if (this.props.product.category.parentId !== null) {
      const singleCategoryUrl =
        "categories/" + this.props.product.category.parentId;
      const singleCategory = await getCall(singleCategoryUrl, []);
      if (singleCategory.error) {
        this.loadErrorMessageAnimation(singleCategory.error.message);
        if (singleCategory.error.statusCode === 401) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("apiToken");
          this.props.history.push(`/login`);
          return;
        }
      } else {
        this.setState({ category: singleCategory });
      }
    }
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }
  onOpenModal = (product) => {
    this.setState({ open: true });
    this.setState({ currentProduct: product });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  minusQty = () => {
    if (this.state.quantity > this.props.product.minimumorderquantity) {
      this.setState({
        stock: getValueFromLanguageObject("str_instock")
          ? getValueFromLanguageObject("str_instock")
          : "InStock",
      });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.availablequantity > this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({
        stock: getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock !",
      });
    }
  };
  changeQty = (e) => {
    try {
      this.setState({ quantity: parseInt(e.target.value) });
    } catch (error) {
      this.setState({ quantity: "" });
    }
  };

  addToCartBase = () => {
    const { pincode } = this.state;
    const { product, onAddToCartClicked, isInquiry } = this.props;
    onAddToCartClicked(product, this.state.quantity, isInquiry, pincode);
  };

  getCartListCounter = async () => {
    let cartCounter = 0;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return cartCounter;
    }
    const { id } = userDetails;
    const counterUrl = "commoncounters?filter[where][userId]=" + id;
    const commonCounter = await getCall(counterUrl, []).then();
    if (commonCounter.error) {
      this.loadErrorMessageAnimation(commonCounter.error.message);
    } else {
      if (commonCounter.length > 0) {
        cartCounter = commonCounter[0].cart;
      }
    }
    return cartCounter;
  };

  pincodeHandleSubmit = (event) => {
    this.setState({ pincode: event.target.value });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      isInquiry,
      showErrorMessageModal,
      errorMessage,
    } = this.props;

    const { currentProduct, category, masterDetailsObj } = this.state;

    return (
      <>
        <div className="product-box">
          <div className="img-wrapper">
            <div className="front">
              <Link to={`/singleproduct/${product.id}`}>
                <img
                  src={
                    product.productmedia.length > 0
                      ? getImageUrl(
                          product.productmedia[0].productname,
                          masterDetailsObj
                        )
                      : getPlaceHolderImage()
                  }
                  className="img-fluid"
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = getPlaceHolderImage();
                  }}
                />
              </Link>
            </div>
            <div className="cart-info cart-wrap">
              <button
                title={
                  isInquiry
                    ? getValueFromLanguageObject("str_add_to_inquiry")
                      ? getValueFromLanguageObject("str_add_to_inquiry")
                      : "Add To Inquiry"
                    : getValueFromLanguageObject("str_add_to_cart")
                    ? getValueFromLanguageObject("str_add_to_cart")
                    : "Add to cart"
                }
                onClick={async () => {
                  onAddToCartClicked(product, this.state.quantity, isInquiry);
                }}
              >
                <i className="fa fa-shopping-cart" aria-hidden="true" />
              </button>
              <a
                href
                data-toggle="modal"
                data-target="#quick-view"
                title="Quick View"
                onClick={() => this.onOpenModal(product)}
              >
                <i className="fa fa-search" aria-hidden="true" />
              </a>
            </div>
            <div className="details-product">
              <Link to={`/singleproduct/${product.id}`}>
                <h6>
                  <EllipsisText text={product.name} length={35} />
                </h6>
              </Link>
              {isInquiry ? (
                <div />
              ) : (
                <h4>
                  {symbol}
                  {parseFloat(product.price).toFixed(2)}
                </h4>
              )}
            </div>
          </div>

          {this.state.open && (
            <Modal open={this.state.open} center onClose={this.onCloseModal}>
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content quick-view-modal">
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-6  col-xs-12">
                        <div
                          className="quick-view-img"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "30px",
                          }}
                          // style={{ height: "400px" }}
                        >
                          <div className="quick-image-box">
                            <img
                              // style={{ height: "100%", width: "325px" }}
                              src={
                                currentProduct.productmedia.length > 0
                                  ? getImageUrl(
                                      currentProduct.productmedia[0]
                                        .productname,
                                      masterDetailsObj
                                    )
                                  : getPlaceHolderImage()
                              }
                              alt=""
                              className="img-fluid"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = getPlaceHolderImage();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 rtl-text">
                        <div className="product-right">
                          <div className="border-product">
                            <h2 className="product-title">
                              <EllipsisText
                                text={currentProduct.name}
                                length={35}
                              />
                            </h2>
                            {isInquiry ? (
                              <div />
                            ) : (
                              <h3>
                                {symbol}
                                {parseFloat(currentProduct.price).toFixed(2)}
                              </h3>
                            )}

                            <div className="border-product">
                              <h6 className="product-title">
                                {getValueFromLanguageObject("str_sub_category")
                                  ? getValueFromLanguageObject(
                                      "str_sub_category"
                                    )
                                  : "sub-category"}
                              </h6>
                              <p>{currentProduct.category.name}</p>
                            </div>
                            {category.name && (
                              <div className="border-product">
                                <h6 className="product-title">
                                  {getValueFromLanguageObject("str_category")
                                    ? getValueFromLanguageObject("str_category")
                                    : "category"}
                                </h6>
                                <p>{category.name}</p>
                              </div>
                            )}
                            <div className="product-description border-product">
                              {!isInquiry && (
                                <div className="instock-cls">
                                  {this.state.stock}
                                </div>
                              )}
                              <h6 className="product-title">
                                {getValueFromLanguageObject("str_quantity")
                                  ? getValueFromLanguageObject("str_quantity")
                                  : "quantity"}
                              </h6>

                              <div className="qty-box">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button
                                      type="button"
                                      className="btn quantity-left-minus"
                                      onClick={this.minusQty}
                                      data-type="minus"
                                      data-field=""
                                    >
                                      <i className="fa fa-angle-left" />
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    name="quantity"
                                    value={this.state.quantity}
                                    onChange={this.changeQty}
                                    className="form-control input-number"
                                  />
                                  <span className="input-group-prepend">
                                    <button
                                      type="button"
                                      className="btn quantity-right-plus"
                                      onClick={this.plusQty}
                                      data-type="plus"
                                      data-field=""
                                    >
                                      <i className="fa fa-angle-right" />
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="product-buttons">
                              <button
                                className="btn btn-solid"
                                onClick={async () => {
                                  this.onCloseModal();

                                  onAddToCartClicked(
                                    product,
                                    this.state.quantity,
                                    isInquiry
                                  );
                                }}
                              >
                                {isInquiry
                                  ? getValueFromLanguageObject(
                                      "str_add_to_inquiry"
                                    )
                                    ? getValueFromLanguageObject(
                                        "str_add_to_inquiry"
                                      )
                                    : "Add To Inquiry"
                                  : getValueFromLanguageObject(
                                      "str_add_to_cart"
                                    )
                                  ? getValueFromLanguageObject(
                                      "str_add_to_cart"
                                    )
                                  : "Add to cart"}
                              </button>
                              <Link
                                to={`/singleproduct/${currentProduct.id}`}
                                className="btn btn-solid"
                              >
                                {getValueFromLanguageObject("str_view_detail")
                                  ? getValueFromLanguageObject(
                                      "str_view_detail"
                                    )
                                  : "view detail"}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
  symbol: getCurrencySymbol(),
});

export default connect(
  mapStateToProps,
  { isInquiryOrCart: isInquiryOrCart }
)(ProductItem);

// export default ProductItem;
