import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumb from "../../common/breadcrumb";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../../store";
import { getCall, patchCall } from "../../../api/post";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getShippingOptionsUrl,
  getMerchantInformationUrl,
  getOrderStatusUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import {
  getNotificationCounter,
  getCommonCounter,
  isInquiryOrCart,
} from "../../../actions";

import { confirmAlert } from "react-confirm-alert";
import DeleteAnim from "../../../animation/AnimFiles/DeleteAnim";
import NoProductFoundEditOrderAnim from "../../../animation/AnimFiles/NoProductFoundEditOrderAnim";
import ConfirmAlertPopup from "../../../Alert/ConfirmAlertPopup";

class EditOrder extends Component {
  state = {
    singleOrder: {},
    orderDetails: [],
    orderId: this.props.match.params.id,
    isLoading: true,
    shippingCharges: [],
    isMerchantGSTEnable: false,
    merchantInfo: {},
    AppTitle: "",
    currentCatelogueType: {},
    orderStatusList: [],
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
  };
  // constructor(props) {
  //     super(props)
  // }

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      this.props.history.push(`/login`);
      return;
    }
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.initOrderStatus().then(() => {
        this.initSingleOrder().then(() => {
          this.setState({ isLoading: false });
        });
      });
      this.initCatelogueSettings();
      this.initMerchantInfo();
      this.initShippingCharges();
      this.initOrderStatus();
      this.props.isInquiryOrCart(masterDetailsObj);
      this.props.notificationCounter();
      this.props.counter();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initSingleOrder = async () => {
    const { isInquiry } = this.props;
    const { orderId, orderStatusList } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const singleOrderUrl = `orders/${orderId}?filter[include]=orderdetails&filter[include]=productmedia`;
    const singleOrder = await getCall(singleOrderUrl, []);
    if (singleOrder.error) {
      if (singleOrder.error.statusCode === 401) {
        this.loadErrorMessageAnimation(singleOrder.error.message);
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
      if (singleOrder.error.statusCode === 500) {
        isInquiry
          ? this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_oops_inquiry_is_not_available")
                ? getValueFromLanguageObject(
                    "str_oops_inquiry_is_not_available"
                  )
                : "Opps! inquiry is not available"
            )
          : this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_oops_order_is_not_available")
                ? getValueFromLanguageObject("str_oops_order_is_not_available")
                : "Opps! order is not available"
            );

        this.props.history.push("/orders");
        return;
      }
      this.loadErrorMessageAnimation(singleOrder.error.message);
    } else {
      this.setState({ singleOrder });
      if (singleOrder.orderdetail) {
        this.setState({ orderDetails: singleOrder.orderdetail });
      }

      if (orderStatusList && orderStatusList.length > 0) {
        let isFound = false;
        orderStatusList.forEach((orderStatusItem) => {
          if (
            singleOrder.orderstatus === orderStatusItem.id &&
            orderStatusItem.status !== "PENDING"
          ) {
            isFound = true;
            return;
          }
        });

        if (isFound) {
          isInquiry
            ? this.loadErrorMessageAnimation(
                getValueFromLanguageObject(
                  "str_you_are_not_allowed_to_edit_inquiry"
                )
                  ? getValueFromLanguageObject(
                      "str_you_are_not_allowed_to_edit_order"
                    )
                  : "you are not allowed to edit inquiry"
              )
            : this.loadErrorMessageAnimation(
                getValueFromLanguageObject(
                  "str_you_are_not_allowed_to_edit_order"
                )
                  ? getValueFromLanguageObject(
                      "str_you_are_not_allowed_to_edit_order"
                    )
                  : "you are not allowed to edit order"
              );

          setTimeout(() => {
            this.props.history.push("/orders");
          }, 500);
        }
      }
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initShippingCharges = async () => {
    const { masterDetailsObj } = this.state;
    const shippingChargesUrl = getShippingOptionsUrl(masterDetailsObj);
    const shippingCharges = await getCall(shippingChargesUrl, []);
    this.setState({ shippingCharges: shippingCharges });
  };

  initOrderStatus = async () => {
    const { masterDetailsObj } = this.state;
    const orderStatusUrl = getOrderStatusUrl(masterDetailsObj);
    const orderStatusList = await getCall(orderStatusUrl, []);
    if (orderStatusList.error) {
      this.loadErrorMessageAnimation(orderStatusList.error.message);
      if (orderStatusList.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ orderStatusList });
    }
  };

  minusQty = (orderDetail, index) => {
    this.setState({ isLoading: true }, () => {
      this.editOrderProduct(orderDetail, index, 0).then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  plusQty = (orderDetail, index) => {
    this.setState({ isLoading: true }, () => {
      this.editOrderProduct(orderDetail, index, 1).then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  editOrderProduct = async (orderDetail, index, operation) => {
    const {
      orderDetails,
      merchantInfo,
      isMerchantGSTEnable,
      currentCatelogueType,
    } = this.state;
    // operation == 0 --- Decrese Qty
    // operation == 1 --- Increase Qty
    const { singleOrder } = this.state;
    const { isInquiry } = this.props;

    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const total = this.getTotalofCart(singleOrder);
    const tempQty =
      operation === 0 ? orderDetail.quantity - 1 : orderDetail.quantity + 1;

    if (orderDetail.products.minimumorderquantity > tempQty) {
      return;
    }
    if (tempQty === 0) {
      return;
    }
    const tempAmount = orderDetail.amount;
    const orderId = singleOrder.id;
    const editCartUrl = "orders/" + orderId;
    var editCartObj = {};
    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      editCartObj = {
        totalamount:
          operation === 0
            ? this.getGrandTotal(
                this.getTotalofCart(singleOrder) - tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(singleOrder) - tempAmount
                )
              )
            : this.getGrandTotal(
                this.getTotalofCart(singleOrder) + tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(singleOrder) + tempAmount
                )
              ),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        tax: {
          cgst: merchantInfo.CGST,
          sgst: merchantInfo.SGST,
          cgstPrice:
            operation === 0
              ? this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                )
              : this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
          sgstPrice:
            operation === 0
              ? this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                )
              : this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
        },
        baseprice:
          operation === 0
            ? this.getTotalofCart(singleOrder) - tempAmount
            : this.getTotalofCart(singleOrder) + tempAmount,
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(singleOrder) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(singleOrder) + tempAmount
              ),
      };
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      editCartObj = {
        totalamount:
          operation === 0
            ? this.getGrandTotal(
                this.getTotalofCart(singleOrder) - tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(singleOrder) - tempAmount
                )
              )
            : this.getGrandTotal(
                this.getTotalofCart(singleOrder) + tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(singleOrder) + tempAmount
                )
              ),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        baseprice:
          operation === 0
            ? this.getTotalofCart(singleOrder) - tempAmount
            : this.getTotalofCart(singleOrder) + tempAmount,
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(singleOrder) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(singleOrder) + tempAmount
              ),
      };
    } else {
      editCartObj = {
        totalamount:
          operation === 0
            ? total -
              tempAmount -
              this.getShippingCharges(
                this.getTotalofCart(singleOrder) - tempAmount
              )
            : total +
              tempAmount +
              this.getShippingCharges(
                this.getTotalofCart(singleOrder) + tempAmount
              ),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(singleOrder) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(singleOrder) + tempAmount
              ),
      };
    }

    const editOrder = await patchCall(editCartUrl, editCartObj);
    if (editOrder.error) {
      this.loadErrorMessageAnimation(editOrder.error.message);
      if (editOrder.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_inquiry_updated_successfully")
            ? getValueFromLanguageObject(
                "msg_your_inquiry_updated_successfully"
              )
            : "Your inquiry updated successfully."
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_order_updated_successfully")
            ? getValueFromLanguageObject("msg_your_order_updated_successfully")
            : "Your order updated successfully."
        );
      }

      const tempOrderDetail = orderDetails;
      tempOrderDetail[index].quantity = tempQty;
      this.setState({ orderDetails: tempOrderDetail });
      this.props.notificationCounter();
    }
  };

  changeQty = (orderDetail, index, e) => {
    // const { isInquiry } = this.props;
    // if (isInquiry) {
    //   return;
    // }
    if (parseInt(e.target.value) < 1) {
      return;
    }
    let qty = e.target.value;
    if (qty !== "" && isFinite(qty)) {
      if (orderDetail.quantity < parseInt(qty)) {
        this.setState({ isLoading: false }, () => {
          this.updateOrderProduct(orderDetail, index, parseInt(qty), 1).then(
            () => {
              const { orderDetails } = this.state;
              const tempOrderDetail = orderDetails;
              tempOrderDetail[index].quantity = parseInt(qty);
              this.setState({
                orderDetails: tempOrderDetail,
                isLoading: false,
              });
            }
          );
        });
      } else if (orderDetail.quantity > parseInt(qty)) {
        this.setState({ isLoading: false }, () => {
          this.updateOrderProduct(orderDetail, index, parseInt(qty), 0).then(
            () => {
              const { orderDetails } = this.state;
              const tempOrderDetail = orderDetails;
              tempOrderDetail[index].quantity = parseInt(qty);
              this.setState({
                orderDetails: tempOrderDetail,
                isLoading: false,
              });
            }
          );
        });
      }
    } else {
      const { orderDetails } = this.state;
      const tempOrderDetail = orderDetails;
      tempOrderDetail[index].quantity = "";
      this.setState({ orderDetails: tempOrderDetail });
    }
  };

  updateOrderProduct = async (orderDetail, index, qty, operation) => {
    const {
      orderDetails,
      isMerchantGSTEnable,
      merchantInfo,
      currentCatelogueType,
    } = this.state;
    const { singleOrder } = this.state;
    const { isInquiry } = this.props;

    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const total = this.getTotalofCart(singleOrder);
    const tempQty = qty;

    if (orderDetail.products.minimumorderquantity > tempQty) {
      return;
    }
    if (tempQty === 0) {
      return;
    }
    const tempAmount = orderDetail.amount;
    const orderId = singleOrder.id;
    const editCartUrl = "orders/" + orderId;
    var editCartObj = {};
    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      editCartObj = {
        totalamount:
          operation === 0
            ? this.getGrandTotal(
                this.getTotalofCart(singleOrder) - tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(singleOrder) - tempAmount
                )
              )
            : this.getGrandTotal(
                this.getTotalofCart(singleOrder) + tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(singleOrder) + tempAmount
                )
              ),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        tax: {
          cgst: merchantInfo.CGST,
          sgst: merchantInfo.SGST,
          cgstPrice:
            operation === 0
              ? this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                )
              : this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
          sgstPrice:
            operation === 0
              ? this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                )
              : this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
        },
        baseprice:
          operation === 0
            ? this.getTotalofCart(singleOrder) - tempAmount
            : this.getTotalofCart(singleOrder) + tempAmount,
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(singleOrder) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(singleOrder) + tempAmount
              ),
      };
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      editCartObj = {
        totalamount:
          operation === 0
            ? this.getGrandTotal(
                this.getTotalofCart(singleOrder) - tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(singleOrder) - tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(singleOrder) - tempAmount
                )
              )
            : this.getGrandTotal(
                this.getTotalofCart(singleOrder) + tempAmount,
                this.getCGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getSGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getIGSTFromTotal(
                  this.getTotalofCart(singleOrder) + tempAmount
                ),
                this.getShippingCharges(
                  this.getTotalofCart(singleOrder) + tempAmount
                )
              ),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        baseprice:
          operation === 0
            ? this.getTotalofCart(singleOrder) - tempAmount
            : this.getTotalofCart(singleOrder) + tempAmount,
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(singleOrder) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(singleOrder) + tempAmount
              ),
      };
    } else {
      editCartObj = {
        totalamount:
          operation === 0
            ? total -
              tempAmount -
              this.getShippingCharges(
                this.getTotalofCart(singleOrder) - tempAmount
              )
            : total +
              tempAmount +
              this.getShippingCharges(
                this.getTotalofCart(singleOrder) + tempAmount
              ),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        shippingprice:
          operation === 0
            ? this.getShippingCharges(
                this.getTotalofCart(singleOrder) - tempAmount
              )
            : this.getShippingCharges(
                this.getTotalofCart(singleOrder) + tempAmount
              ),
      };
    }

    const editOrder = await patchCall(editCartUrl, editCartObj);
    if (editOrder.error) {
      this.loadErrorMessageAnimation(editOrder.error.message);
      if (editOrder.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_inquiry_updated_successfully")
            ? getValueFromLanguageObject(
                "msg_your_inquiry_updated_successfully"
              )
            : "Your inquiry updated successfully"
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_order_updated_successfully")
            ? getValueFromLanguageObject("msg_your_order_updated_successfully")
            : "Your order updated successfully."
        );
      }

      const tempOrderDetail = orderDetails;
      tempOrderDetail[index].quantity = tempQty;
      this.setState({ orderDetails: tempOrderDetail });
      this.props.notificationCounter();
    }
  };

  getTotalofCart(singleOrder) {
    let total = 0;
    for (let i = 0; i < singleOrder.orderdetail.length; i++) {
      total =
        total +
        singleOrder.orderdetail[i].amount * singleOrder.orderdetail[i].quantity;
    }
    return total;
  }

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharge) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharge;
    } else {
      grandTotal = total + igst + shippingCharge;
    }
    return grandTotal;
  };

  getShippingCharges = (total) => {
    const { shippingCharges } = this.state;
    var charge = 0;
    if (shippingCharges.length > 0) {
      for (let index = 0; index < shippingCharges.length; index++) {
        if (
          shippingCharges[index].id === 3 &&
          shippingCharges[index].status === 1
        ) {
          for (
            let jindex = 0;
            jindex < shippingCharges[index].options.length;
            jindex++
          ) {
            if (
              !shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total &&
              shippingCharges[index].options[jindex].maxValue >= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
            if (
              shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
          }
        }
      }
    }
    return charge;
  };

  onClickOfDeleteIcon = (item, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        const submitClick = (props) => {
          this.setState({ isLoading: true }, () => {
            this.removeProductFromOrder(item, index).then(() => {
              this.setState({ isLoading: false });
            });
            onClose();
          });
        };
        return (
          <>
            <ConfirmAlertPopup
              title={
                getValueFromLanguageObject("str_title_remove_product")
                  ? getValueFromLanguageObject("str_title_product")
                  : "Remove Product"
              }
              message={
                getValueFromLanguageObject("str_remove_product")
                  ? getValueFromLanguageObject("str_remove_product")
                  : "Are you sure you want to remove this product?"
              }
              animation={<DeleteAnim />}
              submit={
                getValueFromLanguageObject("str_yes_remove_it")
                  ? getValueFromLanguageObject("str_yes_remove_it")
                  : "Yes, Remove it!"
              }
              submitClick={submitClick}
              cancelClick={onClose}
              cancel={
                getValueFromLanguageObject("str_no")
                  ? getValueFromLanguageObject("str_no")
                  : "No"
              }
            />
          </>
        );
      },
    });
  };

  removeProductFromOrder = async (orderDetail, index) => {
    const {
      orderDetails,
      isMerchantGSTEnable,
      merchantInfo,
      currentCatelogueType,
    } = this.state;
    const { isInquiry } = this.props;
    const { singleOrder } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const total = this.getTotalofCart(singleOrder);
    const tempAmount = orderDetail.amount * orderDetail.quantity;
    const orderId = singleOrder.id;
    const removeProductFromOrderUrl = "orders/" + orderId;
    var removeProductFromOrderObj = {};
    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      removeProductFromOrderObj = {
        totalamount: this.getGrandTotal(
          this.getTotalofCart(singleOrder) - tempAmount,
          this.getCGSTFromTotal(this.getTotalofCart(singleOrder) - tempAmount),
          this.getSGSTFromTotal(this.getTotalofCart(singleOrder) - tempAmount),
          this.getIGSTFromTotal(this.getTotalofCart(singleOrder) - tempAmount),
          this.getShippingCharges(this.getTotalofCart(singleOrder) - tempAmount)
        ),

        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            deletedAt: new Date(),
          },
        ],
        tax: {
          cgst: merchantInfo.CGST,
          sgst: merchantInfo.SGST,
          cgstPrice: this.getCGSTFromTotal(
            this.getTotalofCart(singleOrder) - tempAmount
          ),

          sgstPrice: this.getSGSTFromTotal(
            this.getTotalofCart(singleOrder) - tempAmount
          ),
        },
        baseprice: this.getTotalofCart(singleOrder) - tempAmount,
        shippingprice: this.getShippingCharges(
          this.getTotalofCart(singleOrder) - tempAmount
        ),
      };
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      removeProductFromOrderObj = {
        totalamount: this.getGrandTotal(
          this.getTotalofCart(singleOrder) - tempAmount,
          this.getCGSTFromTotal(this.getTotalofCart(singleOrder) - tempAmount),
          this.getSGSTFromTotal(this.getTotalofCart(singleOrder) - tempAmount),
          this.getIGSTFromTotal(this.getTotalofCart(singleOrder) - tempAmount),
          this.getShippingCharges(this.getTotalofCart(singleOrder) - tempAmount)
        ),

        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            deletedAt: new Date(),
          },
        ],
        baseprice: this.getTotalofCart(singleOrder) - tempAmount,
        shippingprice: this.getShippingCharges(
          this.getTotalofCart(singleOrder) - tempAmount
        ),
      };
    } else {
      removeProductFromOrderObj = {
        totalamount:
          total -
          tempAmount -
          this.getShippingCharges(
            this.getTotalofCart(singleOrder) - tempAmount
          ),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            deletedAt: new Date(),
          },
        ],
        shippingprice: this.getShippingCharges(
          this.getTotalofCart(singleOrder) - tempAmount
        ),
      };
    }

    const removeProduct = await patchCall(
      removeProductFromOrderUrl,
      removeProductFromOrderObj
    );
    if (removeProduct.error) {
      this.loadErrorMessageAnimation(removeProduct.error.message);
      if (singleOrder.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_inquiry_updated_successfully")
            ? getValueFromLanguageObject(
                "msg_your_inquiry_updated_successfully"
              )
            : "Your inquiry updated successfully."
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_order_updated_successfully")
            ? getValueFromLanguageObject("msg_your_order_updated_successfully")
            : "Your order updated successfully."
        );
      }

      if (orderDetails.length === 1) {
        this.setState({ orderDetails: [] });
        return;
      }
      this.setState({ isLoading: true }, () => {
        this.initSingleOrder().then(() => {
          this.setState({ isLoading: false });
        });
      });

      this.props.notificationCounter();
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const { symbol } = this.props;
    const { isInquiry } = this.props;

    const {
      orderDetails,
      singleOrder,
      isLoading,
      isMerchantGSTEnable,
      AppTitle,
      currentCatelogueType,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      masterDetailsObj,
    } = this.state;
    return (
      <>
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>
              {AppTitle} |{" "}
              {isInquiry
                ? getValueFromLanguageObject("str_edit_inquiry_page")
                  ? getValueFromLanguageObject("str_edit_inquiry_page")
                  : "Edit Inquiry Page"
                : getValueFromLanguageObject("str_edit_order_page")
                ? getValueFromLanguageObject("str_edit_order_page")
                : "Edit Order Page"}
            </title>
            <meta
              name="description"
              content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
            />
          </Helmet>
          {/*SEO Support End */}
          {isInquiry ? (
            <Breadcrumb
              title={
                getValueFromLanguageObject("str_edit_inquiry")
                  ? getValueFromLanguageObject("str_edit_inquiry")
                  : "Edit Inquiry"
              }
            />
          ) : (
            <Breadcrumb
              title={
                getValueFromLanguageObject("str_edit_order")
                  ? getValueFromLanguageObject("str_edit_order")
                  : "Edit Order"
              }
            />
          )}

          {orderDetails.length > 0 ? (
            <section className="cart-section section-b-space">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <table className="table cart-table table-responsive-xs">
                      <thead>
                        <tr className="table-head">
                          <th
                            scope="col"
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_heading_label_edit_order"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_image")
                              ? getValueFromLanguageObject("str_image")
                              : "image"}
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_heading_label_edit_order"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_product_name")
                              ? getValueFromLanguageObject("str_product_name")
                              : "product name"}
                          </th>
                          {!isInquiry && (
                            <th
                              scope="col"
                              style={{
                                color: getColorCodeBasedOnKey(
                                  "text_heading_label_edit_order"
                                ),
                              }}
                            >
                              {getValueFromLanguageObject("str_price")
                                ? getValueFromLanguageObject("str_price")
                                : "price"}
                            </th>
                          )}

                          <th
                            scope="col"
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_heading_label_edit_order"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_quantity")
                              ? getValueFromLanguageObject("str_quantity")
                              : "quantity"}
                          </th>
                          <th
                            scope="col"
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_heading_label_edit_order"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("str_action")
                              ? getValueFromLanguageObject("str_action")
                              : "action"}
                          </th>
                          {!isInquiry && (
                            <th
                              scope="col"
                              style={{
                                color: getColorCodeBasedOnKey(
                                  "text_heading_label_edit_order"
                                ),
                              }}
                            >
                              {getValueFromLanguageObject("str_total")
                                ? getValueFromLanguageObject("str_total")
                                : "total"}
                            </th>
                          )}
                        </tr>
                      </thead>
                      {orderDetails.map((item, index) => {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td>
                                <Link to={`/singleproduct/${item.products.id}`}>
                                  <img
                                    src={
                                      item.products.productmedia.length > 0
                                        ? getImageUrl(
                                            item.products.productmedia[0]
                                              .productname,
                                            masterDetailsObj
                                          )
                                        : getPlaceHolderImage()
                                    }
                                    alt=""
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = getPlaceHolderImage();
                                    }}
                                  />
                                </Link>
                              </td>
                              <td
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_product_name_edit_order"
                                  ),
                                }}
                              >
                                <Link to={`/singleproduct/${item.products.id}`}>
                                  {item.products.name}
                                </Link>
                                <div className="mobile-cart-content row">
                                  <div className="col-xs-3">
                                    <div className="qty-box">
                                      <div className="input-group">
                                        <input
                                          type="number"
                                          name="quantity"
                                          onChange={(e) => {
                                            this.changeQty(item, index, e);
                                          }}
                                          value={item.quantity}
                                          className="form-control input-number"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {!isInquiry && (
                                    <div className="col-xs-3">
                                      <b
                                        className="td-color"
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_price_edit_order"
                                          ),
                                        }}
                                      >
                                        {symbol}
                                        {parseFloat(item.amount).toFixed(2)}
                                      </b>
                                    </div>
                                  )}

                                  <div className="col-xs-3">
                                    <b className="td-color">
                                      <div
                                        className="icon"
                                        onClick={() => {
                                          this.onClickOfDeleteIcon(item, index);
                                        }}
                                      >
                                        <i className="fa fa-trash" />
                                      </div>
                                    </b>
                                  </div>
                                </div>
                              </td>
                              {!isInquiry && (
                                <td>
                                  <b
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_price_edit_order"
                                      ),
                                    }}
                                  >
                                    {symbol}
                                    {parseFloat(item.amount).toFixed(2)}
                                  </b>
                                </td>
                              )}

                              <td>
                                <div className="qty-box">
                                  <div className="input-group">
                                    <span className="input-group-prepend">
                                      <button
                                        type="button"
                                        className="btn quantity-left-minus"
                                        onClick={() =>
                                          this.minusQty(item, index)
                                        }
                                        data-type="minus"
                                        data-field=""
                                      >
                                        <i className="fa fa-angle-left" />
                                      </button>
                                    </span>
                                    <input
                                      type="number"
                                      name="quantity"
                                      value={item.quantity}
                                      onChange={(e) =>
                                        this.changeQty(item, index, e)
                                      }
                                      className="form-control input-number"
                                    />
                                    <span className="input-group-prepend">
                                      <button
                                        className="btn quantity-right-plus"
                                        onClick={() =>
                                          this.plusQty(item, index)
                                        }
                                        data-type="plus"
                                      >
                                        <i className="fa fa-angle-right" />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="icon"
                                  onClick={() => {
                                    this.onClickOfDeleteIcon(item, index);
                                  }}
                                >
                                  <i className="fa fa-trash" />
                                </div>
                              </td>
                              <td>
                                {!isInquiry && (
                                  <b
                                    className="td-color"
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_total_edit_order"
                                      ),
                                    }}
                                  >
                                    {symbol}
                                    {item.quantity *
                                      parseFloat(item.amount).toFixed(2)}
                                  </b>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                    <div className="row">
                      <div class="col-md-6" />
                      <div className="col-md-6">
                        {!isInquiry && (
                          <div className="cart-total">
                            <table className="table cart-table table-responsive-md">
                              <tfoot>
                                <tr>
                                  <td
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_total_price_label_edit_order"
                                      ),
                                    }}
                                  >
                                    {getValueFromLanguageObject(
                                      "str_total_price_with_colun"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_total_price_with_colun"
                                        )
                                      : "total price :"}
                                  </td>
                                  <td>
                                    <b
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_total_price_edit_order"
                                        ),
                                      }}
                                    >
                                      {symbol}{" "}
                                      {parseFloat(
                                        this.getTotalofCart(singleOrder)
                                      ).toFixed(2)}
                                    </b>
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_shipping_charges_label_cart"
                                      ),
                                    }}
                                  >
                                    {"+ "}
                                    {getValueFromLanguageObject(
                                      "str_shipping_charges_with_colun"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_shipping_charges_with_colun"
                                        )
                                      : "Shipping Charges : "}
                                  </td>
                                  <td>
                                    <b
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_shipping_charges_cart"
                                        ),
                                      }}
                                    >
                                      {symbol}{" "}
                                      {parseFloat(
                                        this.getShippingCharges(
                                          this.getTotalofCart(singleOrder)
                                        )
                                      ).toFixed(2)}
                                    </b>
                                  </td>
                                </tr>

                                {isMerchantGSTEnable &&
                                  currentCatelogueType &&
                                  currentCatelogueType.registerallow !==
                                    "Catalogue_Jewellary" &&
                                  this.state.merchantInfo.countryname ===
                                    "India" && (
                                    <tr>
                                      <td
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_cgst_label_cart"
                                          ),
                                        }}
                                      >
                                        {"+ "}
                                        {getValueFromLanguageObject(
                                          "str_cgst_with_colun"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_cgst_with_colun"
                                            )
                                          : "CGST "}
                                        {"("}
                                        {this.state.merchantInfo.CGST}
                                        {"%) :"}
                                      </td>
                                      <td>
                                        <b
                                          style={{
                                            color: getColorCodeBasedOnKey(
                                              "text_cgst_cart"
                                            ),
                                          }}
                                        >
                                          {symbol}{" "}
                                          {parseFloat(
                                            this.getCGSTFromTotal(
                                              this.getTotalofCart(singleOrder)
                                            )
                                          ).toFixed(2)}
                                        </b>
                                      </td>
                                    </tr>
                                  )}

                                {isMerchantGSTEnable &&
                                  currentCatelogueType &&
                                  currentCatelogueType.registerallow !==
                                    "Catalogue_Jewellary" &&
                                  this.state.merchantInfo.countryname ===
                                    "India" && (
                                    <tr>
                                      <td
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_sgst_label_cart"
                                          ),
                                        }}
                                      >
                                        {"+ "}
                                        {getValueFromLanguageObject(
                                          "str_sgst_with_colun"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_sgst_with_colun"
                                            )
                                          : "SGST "}{" "}
                                        {"("}
                                        {this.state.merchantInfo.SGST}
                                        {"%) : "}
                                      </td>
                                      <td>
                                        <b
                                          style={{
                                            color: getColorCodeBasedOnKey(
                                              "text_sgst_cart"
                                            ),
                                          }}
                                        >
                                          {symbol}{" "}
                                          {parseFloat(
                                            this.getSGSTFromTotal(
                                              this.getTotalofCart(singleOrder)
                                            )
                                          ).toFixed(2)}
                                        </b>
                                      </td>
                                    </tr>
                                  )}

                                {isMerchantGSTEnable &&
                                  currentCatelogueType &&
                                  currentCatelogueType.registerallow !==
                                    "Catalogue_Jewellary" &&
                                  this.state.merchantInfo.countryname !==
                                    "India" && (
                                    <tr>
                                      <td
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_igst_label_cart"
                                          ),
                                        }}
                                      >
                                        <b>
                                          {symbol}{" "}
                                          {parseFloat(
                                            this.getIGSTFromTotal(
                                              this.getTotalofCart(singleOrder)
                                            )
                                          ).toFixed(2)}
                                        </b>
                                      </td>
                                    </tr>
                                  )}

                                <tr>
                                  <td
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_discount_label_cart"
                                      ),
                                    }}
                                  >
                                    <b>
                                      {symbol}
                                      {parseFloat(0).toFixed(2)}
                                    </b>
                                  </td>
                                </tr>
                                <tfoot>
                                  <tr className="order_total">
                                    <td
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_amount_payable_label_cart"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_amount_payable_with_colun"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_amount_payable_with_colun"
                                          )
                                        : "Amount Payable :"}
                                    </td>
                                    <td>
                                      <b
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_amount_payable_cart"
                                          ),
                                        }}
                                      >
                                        {symbol}{" "}
                                        {isNaN
                                          ? 0
                                          : parseFloat(
                                              this.getGrandTotal(
                                                this.getTotalofCart(
                                                  singleOrder
                                                ),
                                                this.getCGSTFromTotal(
                                                  this.getTotalofCart(
                                                    singleOrder
                                                  )
                                                ),
                                                this.getSGSTFromTotal(
                                                  this.getTotalofCart(
                                                    singleOrder
                                                  )
                                                ),
                                                this.getIGSTFromTotal(
                                                  this.getTotalofCart(
                                                    singleOrder
                                                  )
                                                ),
                                                this.getShippingCharges(
                                                  this.getTotalofCart(
                                                    singleOrder
                                                  )
                                                )
                                              )
                                            ).toFixed(2)}
                                      </b>
                                    </td>
                                  </tr>
                                </tfoot>
                              </tfoot>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <NoProductFoundEditOrderAnim />
          )}
          {isLoading && <MainLoader />}
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    notificationCounter: getNotificationCounter,
    counter: getCommonCounter,
    isInquiryOrCart: isInquiryOrCart,
  }
)(EditOrder);
