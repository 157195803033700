import { connect } from "react-redux";
import React, { Component } from "react";
import Iframe from "react-iframe";
import Breadcrumb from "../../common/breadcrumb";
import {
  getValueFromLanguageObject,
} from "../../../store";
import { getCall } from "../../../api/post";
import { getAboutUsUrl, getMasterDetailsIdUrl } from "../../../services";


class AboutUs extends Component {
  state = { iFrameHeight: 0, iframeRef: null, text: "", masterDetailsObj: {} };

  componentDidMount() {
    // this.initAboutUs();
    this.initMasterDetailsId();
  }

  // initAboutUs = async () => {
  //   const userDetails = loadUserDetailsFromLocalStorage();
  //   if (userDetails === undefined) {
  //     this.props.history.push(`/login`);
  //   }
  //   const aboutUsUrl = getAboutUsUrl();
  //   const aboutUs = await getCall(aboutUsUrl, []);
  //   if (aboutUs.error) {
  //     if (aboutUs.error.statusCode === 401) {
  //       this.removeUserDetailAndRedirectToLogin();
  //       return;
  //     }
  //   } else {
  //     this.setState({ text: aboutUs.text });
  //   }
  // };
  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  render() {
    const { masterDetailsObj } = this.state;
    return (
      <div>
        <Breadcrumb
          title={
            getValueFromLanguageObject("str_about_us")
              ? getValueFromLanguageObject("str_about_us")
              : "About Us"
          }
        />
        <div className="container">
          <Iframe
            url={getAboutUsUrl(masterDetailsObj)}
            position="relative"
            width="100%"
            frameBorder="0"
            height="640"
            styles={{ height: "100%" }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(AboutUs);
