import { COMMON_COUNTER, NOTIFICATION_COUNTER } from "../constants/ActionTypes";
const initialState = {
  cartCounter: 0,
  notificationCounter: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMON_COUNTER:
      return { ...state, cartCounter: action.cartCounter };

    case NOTIFICATION_COUNTER:
      return { ...state, notificationCounter: action.notificationCounter };
    default:
      return state;
  }
};
export default cartReducer;
