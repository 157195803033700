import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import EllipsisText from "react-ellipsis-text";
import { getCall } from "../../../api/post";
import { connect } from "react-redux";
import {
  getImageUrl,
  getPlaceHolderImage,
  getMasterDetailsIdUrl,
} from "../../../services";
import { isInquiryOrCart } from "../../../actions";
import {
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
  loadUserDetailsFromLocalStorage,
} from "../../../store";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";

const imageContainer = {
  height: "100%",
  width: "100%",
  objectFit: "cover",
  border: "1px solid #D3D3D3",
};

class ProductListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock: getValueFromLanguageObject("str_instock")
        ? getValueFromLanguageObject("str_instock")
        : "InStock",
      quantity:
        props.product.minimumorderquantity >= 1
          ? props.product.minimumorderquantity
          : 1,
      image: "",
      category: {},
      pincode: "",
      showErrorMessageModal: false,
      errorMessage: "",
      masterDetailsObj: {},
    };
  }

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initSingleCategry();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > this.props.product.minimumorderquantity) {
      this.setState({
        stock: getValueFromLanguageObject("str_instock")
          ? getValueFromLanguageObject("str_instock")
          : "InStock",
      });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.availablequantity > this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({
        stock: getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock!",
      });
    }
  };

  changeQty = (e) => {
    try {
      this.setState({ quantity: parseInt(e.target.value) });
    } catch (error) {
      this.setState({ quantity: "" });
    }
  };

  initSingleCategry = async () => {
    if (this.props.product.category.parentId !== null) {
      const singleCategoryUrl =
        "categories/" + this.props.product.category.parentId;
      const singleCategory = await getCall(singleCategoryUrl, []);
      if (singleCategory.error) {
        this.loadErrorMessageAnimation(singleCategory.error.message);
        if (singleCategory.error.statusCode === 401) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("apiToken");
          this.props.history.push(`/login`);
          return;
        }
      } else {
        this.setState({ category: singleCategory });
      }
    }
  };

  getCartListCounter = async () => {
    let cartCounter = 0;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return cartCounter;
    }
    const { id } = userDetails;
    const counterUrl = "commoncounters?filter[where][userId]=" + id;
    const commonCounter = await getCall(counterUrl, []).then();
    if (commonCounter.error) {
      this.loadErrorMessageAnimation(commonCounter.error.message);
    } else {
      if (commonCounter.length > 0) {
        cartCounter = commonCounter[0].cart;
      }
    }
    return cartCounter;
  };

  pincodeHandleSubmit = (event) => {
    this.setState({ pincode: event.target.value });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const { product, symbol, onAddToCartClicked, isInquiry } = this.props;
    const {
      open,
      category,
      showErrorMessageModal,
      errorMessage,
      masterDetailsObj,
    } = this.state;

    let RatingStars = [];
    for (var i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i} />);
    }

    return (
      <>
        <div className="product-box">
          <div className="img-wrapper">
            <div className="front">
              <Link to={`/singleproduct/${product.id}`}>
                <div style={{ height: "250px" }}>
                  <img
                    style={imageContainer}
                    src={
                      product.productmedia.length > 0
                        ? getImageUrl(
                            product.productmedia[0].productname,
                            masterDetailsObj
                          )
                        : getPlaceHolderImage()
                    }
                    // className="img-fluid"
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = getPlaceHolderImage();
                    }}
                  />
                </div>
              </Link>
            </div>
            <div className="cart-info cart-wrap">
              <button
                title={
                  isInquiry
                    ? getValueFromLanguageObject("str_add_to_inquiry")
                      ? getValueFromLanguageObject("str_add_to_inquiry")
                      : "Add To Inquiry"
                    : getValueFromLanguageObject("str_add_to_cart")
                    ? getValueFromLanguageObject("str_add_to_cart")
                    : "Add to cart"
                }
                onClick={() => {
                  onAddToCartClicked(product, this.state.quantity, isInquiry);
                }}
              >
                <i className="fa fa-shopping-cart" aria-hidden="true" />
              </button>
              <a
                href
                data-toggle="modal"
                data-target="#quick-view"
                title="Quick View"
                onClick={this.onOpenModal}
              >
                <i className="fa fa-search" aria-hidden="true" />
              </a>
            </div>
          </div>
          <div className="product-detail">
            <div>
              <Link to={`/singleproduct/${product.id}`}>
                <h6
                  style={{
                    color: getColorCodeBasedOnKey("text_product_name_products"),
                  }}
                >
                  <EllipsisText text={product.name} length={35} />
                </h6>
              </Link>
              <h6>
                <EllipsisText text={product.category.name} length={20} />
              </h6>
              {isInquiry ? (
                <div />
              ) : (
                <h4>
                  {symbol}
                  {parseFloat(product.price).toFixed(2)}
                </h4>
              )}
            </div>
            <Modal
              open={open}
              onClose={this.onCloseModal}
              center
              styles={{ closeButton: { background: "#cfd4da" } }}
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content quick-view-modal">
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-6  col-xs-12">
                        <div
                          className="quick-view-img"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "30px",
                          }}
                        >
                          <div className="quick-image-box">
                            <img
                              src={
                                product.productmedia.length > 0
                                  ? getImageUrl(
                                      product.productmedia[0].productname,
                                      masterDetailsObj
                                    )
                                  : getPlaceHolderImage()
                              }
                              alt=""
                              className="img-fluid"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = getPlaceHolderImage();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 rtl-text">
                        <div className="product-right">
                          <h2>
                            <EllipsisText text={product.name} length={35} />
                          </h2>
                          {!isInquiry && (
                            <h3>
                              {symbol}
                              {parseFloat(product.price).toFixed(2)}
                            </h3>
                          )}

                          <div className="border-product">
                            <h6 className="product-title">
                              {getValueFromLanguageObject("str_sub_category")
                                ? getValueFromLanguageObject("str_sub_category")
                                : "sub-category"}
                            </h6>
                            <p>{product.category.name}</p>
                          </div>
                          {category && category.name && (
                            <div className="border-product">
                              <h6 className="product-title">
                                {getValueFromLanguageObject("str_category")
                                  ? getValueFromLanguageObject("str_category")
                                  : "category"}
                              </h6>
                              <p>{category.name}</p>
                            </div>
                          )}
                          {!isInquiry && (
                            <div className="product-description border-product">
                              <h6 className="product-title">
                                {symbol}
                                {parseFloat(product.price).toFixed(2)}
                              </h6>
                            </div>
                          )}
                          <div className="product-description border-product">
                            <h6 className="product-title">
                              {getValueFromLanguageObject("str_quantity")
                                ? getValueFromLanguageObject("str_quantity")
                                : "quantity"}
                            </h6>
                            <div className="qty-box">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <button
                                    type="button"
                                    className="btn quantity-left-minus"
                                    onClick={this.minusQty}
                                    data-type="minus"
                                    data-field=""
                                  >
                                    <i className="fa fa-angle-left" />
                                  </button>
                                </span>
                                <input
                                  type="number"
                                  name="quantity"
                                  value={this.state.quantity}
                                  onChange={this.changeQty}
                                  className="form-control input-number"
                                />
                                <span className="input-group-prepend">
                                  <button
                                    type="button"
                                    className="btn quantity-right-plus"
                                    onClick={this.plusQty}
                                    data-type="plus"
                                    data-field=""
                                  >
                                    <i className="fa fa-angle-right" />
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="product-buttons">
                            <button
                              className="btn btn-solid"
                              onClick={async () => {
                                this.onCloseModal();

                                onAddToCartClicked(
                                  product,
                                  this.state.quantity,
                                  isInquiry
                                );
                              }}
                            >
                              {isInquiry
                                ? getValueFromLanguageObject(
                                    "str_add_to_inquiry"
                                  )
                                  ? getValueFromLanguageObject(
                                      "str_add_to_inquiry"
                                    )
                                  : "Add to Inquiry"
                                : getValueFromLanguageObject("str_add_to_cart")
                                ? getValueFromLanguageObject("str_add_to_cart")
                                : "Add to Cart"}
                            </button>
                            <Link
                              to={`/singleproduct/${product.id}`}
                              className="btn btn-solid"
                            >
                              {getValueFromLanguageObject("str_view_detail")
                                ? getValueFromLanguageObject("str_view_detail")
                                : "view detail"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          {showErrorMessageModal && (
            <ErrorMessageAnimationModal
              show={showErrorMessageModal}
              message={errorMessage}
              onClose={() => {
                this.setState({ showErrorMessageModal: false });
              }}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
  symbol: getCurrencySymbol(),
});

export default connect(
  mapStateToProps,
  { isInquiryOrCart: isInquiryOrCart }
)(ProductListItem);
// export default ProductListItem;
