// Get Trending Tag wise Collection
export const getTrendingTagCollection = (products, type, tag) => {
  const items = products.filter((product) => {
    return product.category === type && product.tags.includes(tag);
  });
  return items.slice(0, 8);
};

// Get Trending Collection
export const getTrendingCollection = (products, type) => {
  const items = products.filter((product) => {
    return product.category === type;
  });
  return items.slice(0, 8);
};

// Get Special 5 Collection
export const getSpecialCollection = (products, type) => {
  const items = products.filter((product) => {
    return product.category === type;
  });
  return items.slice(0, 5);
};

// Get TOP Collection
export const getTopCollection = (products) => {
  const items = products.filter((product) => {
    return product.rating > 4;
  });
  return items.slice(0, 8);
};

// Get New Products
export const getNewProducts = (products, type) => {
  const items = products.filter((product) => {
    return product.new === true && product.category === type;
  });

  return items.slice(0, 8);
};

// Get Related Items
export const getRelatedItems = (products, type) => {
  const items = products.filter((product) => {
    return product.category === type;
  });

  return items.slice(0, 4);
};

export const getImageUrl = (path, masterDetailsObj) => {
  let url = "";
  if (masterDetailsObj && !masterDetailsObj.codename) {
    return url;
  }
  const token = localStorage.getItem("apiToken");
  url = `${process.env.SERVER_URL}/api/containers/productmedia-${
    masterDetailsObj.codename
  }/download/${path}?access_token=${token}`;
  return url;
};

export const getProfileImageUrl = (path, masterDetailsObj) => {
  let url = "";
  if (masterDetailsObj && !masterDetailsObj.codename) {
    return url;
  }
  const token = localStorage.getItem("apiToken");
  url = `${process.env.SERVER_URL}/api/containers/profilepic${
    masterDetailsObj.codename
  }/download/${path}?access_token=${token}`;
  return url;
};

export const getCitiesUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `cities?filter[where][masterdetailId]=${masterDetailsObj.id}`;
  }
};
export const getSignupOptionsUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `settings?filter[where][registerallow]=Signup_Options&filter[where][masterdetailId]=${
      masterDetailsObj.id
    }`;
  }
};

export const getPrivacyPolicyUrl = (masterDetailsObj) => {
  // return `settings/12?filter[where][masterdetailId]=${masterDetailsObj.id}`;
  if (masterDetailsObj && masterDetailsObj.id) {
    return `https://managebizon.sufalam.live/privacypolicy/${
      masterDetailsObj.id
    }`;
  }
};

export const getAboutUsUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `https://managebizon.sufalam.live/aboutuswebview/${
      masterDetailsObj.id
    }`;
  }
};

export const getCatalogueJewellaryUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `settings?filter[where][registerallow]=Catalogue_Jewellary&filter[where][masterdetailId]=${
      masterDetailsObj.id
    }`;
  }
};

export const getGroupWiseGSTConfigurationUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `settings?filter[where][registerallow]=Group_Wise_GST_Configuration&filter[where][masterdetailId]=${
      masterDetailsObj.id
    }`;
  }
};

export const getOrderViaWhatsAppUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `settings?filter[where][registerallow]=ORDER_VIA_WHATSAPP&filter[where][masterdetailId]=${
      masterDetailsObj.id
    }`;
  }
};

export const getSettingUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `settings?filter[where][masterdetailId]=${masterDetailsObj.id}`;
  }
};

export const getOrderStatusUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `orderstatuses?filter[where][masterdetailId]=${masterDetailsObj.id}`;
  }
};

export const getOfferBannerUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `settings/getOfferBanner?filter[where][masterdetailId]=${
      masterDetailsObj.id
    }`;
  }
};

export const getShippingOptionsUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `settings?filter[where][registerallow]=Shipping_Options&filter[where][masterdetailId]=${
      masterDetailsObj.id
    }`;
  }
};

export const getMerchantInformationUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `settings?filter[where][registerallow]=Merchant_Information&filter[where][masterdetailId]=${
      masterDetailsObj.id
    }`;
  }
};

export const getPaymentDetailsUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `settings?filter[where][registerallow]=Payment_Details&filter[where][masterdetailId]=${
      masterDetailsObj.id
    }`;
  }
};

export const getPincodeDeliveryUrl = (masterDetailsObj) => {
  if (masterDetailsObj && masterDetailsObj.id) {
    return `settings?filter[where][registerallow]=PINCODE_DELIVERY&filter[where][masterdetailId]=${
      masterDetailsObj.id
    }`;
  }
};

export const getPlaceHolderImage = () => {
  let url =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAANlBMVEXf39+goKDi4uKdnZ2np6e4uLjU1NSamprOzs7KysrExMTj4+PBwcHc3NyoqKiioqKwsLC0tLSjj0KtAAADSklEQVR4nO2b2WKqMBBAA0EjAkL//2fLIMuAigsXeh3OebAVQiWnM9kkrihcITih0HRvhzPqZ/Nb+6IuHA6P3riW27/XnR7KTI/oGxofvHfLo3PdBcM9uQnXgzeHYZcQBzgAAAAAAACAeZg34kDAAQAAaOgXcCDgAAcCDgCgg/YABwIOcCDgAAcCDnAg4AAHAg5wIOAABwIOAABAQ7+AAwEHOBBwgAMBBzgAAIAx9As4EFZ3EC9n7VtcnfywmG+P1TgJfiHh+NeVWEic+GghNhwsCILagTfhoPq8LdjCwdrtjTjwp+zTLiHzVhykH3dvMQ6sOIiuDorj2zhrDs7hXUpnzsHb44QkxsHFsIMy8sFH4akTiQO3hYO1uePA+0Ndq+Jc+nkNjYPIqINj21X+7MaBnzjoFbjsMiuhywUTc6aRA3/I+pNFeOYgtulA1Si77MpB3DtQ4+b4MJcMdh1E2kG+TwdejUj+kzjYfIzkU3WyVFUukwcODI4Tkz4Z4lSFgf85edMOnB4fdJ1jfNSVDmk2WX417CDyVZHVDYREgY6DOizCXhzUUyZf5edDOfqv+yqbrsNvNmf6gzhoqjyZL/mQumkgbDZf+CMHE8prlxmPuoadOWhSYRoIF+O5MCVcl55HgWBsHck9jYO2tB48b+ZgbV5cT6zaMcNRJYOh+cIrDvoBdKaSwZyDeMaB96EvrpJhPw588FU6tEpqaWknDuqhUi1AfxtZTxr81g42+871jgMfqtRNHrlSyWBovvDAQSMgu71gSAZjDtyNA58UdwQ43TMYcnB/zpQ8vCCftgdGHTxWoJJhMwdrczcX6kR4fEWfDKYdzEWBmjxadjCvoA6EVoK5dSQ1RnqioJdgbg0lzrvnlp8qaNJBClpz4E55yyufmEnps61ckNpcNyK8uBtBijXFbTn47HIbDhY9p2rneeX89DFWHNRzRMV4j0q43eaijwUb/cLifSw4sLCWVpXJQspvd8D+xu/g2/dP/gtwgAMBBzgQcIADAQc4EHCAAwEHOBBwgAMAABhDv4ADAQc4EHCAAwEHOACAAdoDHAg4wIGAAxwIOMCBgAMcAMAA7QEOBBzgQMABDgQcAAAAAADAI5gv4AAAen4BZVMuzK5mga8AAAAASUVORK5CYII=";
  return url;
};

export const getPlaceHolderBanner = () => {
  let url = `${process.env.PUBLIC_URL}/assets/images/placeholder_banner.png`;
  return url;
};

export const getMasterDetailsIdUrl = (instanceName) => {
  let domain =
    // window.location.host === "localhost:3001"
    //   ? "nutland.sufalam.live"
    //   : 
      window.location.host;
  return `masterdetails/getMasterDetailsFromURL?filter[where][getDetailsFromDomain]=${domain}&filter[where][getDetailsFromURL]=${
    instanceName ? instanceName : domain
  }`;
};

// export const getMasterDetailUUID = () => {
//   const publicUrl = window.location.pathname.split("/")[1];
//   if (publicUrl === "cg") {
//     return process.env.MASTER_DETAIL_ID_CG;
//   }
//   if (publicUrl === "sf") {
//     return process.env.MASTER_DETAIL_ID_SF;
//   }
//   if (publicUrl === "sj") {
//     return process.env.MASTER_DETAIL_ID_SJ;
//   }
//   if (publicUrl === "kj") {
//     return process.env.MASTER_DETAIL_ID_KJ;
//   }
//   if (publicUrl === "panam") {
//     return process.env.MASTER_DETAIL_ID_PANAM;
//   }
//   if (publicUrl === "nextview") {
//     return process.env.MASTER_DETAIL_ID_NEXTVIEW;
//   }
//   if (publicUrl === "nutland") {
//     return process.env.MASTER_DETAIL_ID_NUTLAND;
//   }
//   if (publicUrl === "jewel") {
//     return process.env.MASTER_DETAIL_ID_JEWEL;
//   }
//   if (publicUrl === "fmcg") {
//     return process.env.MASTER_DETAIL_ID_FMCG;
//   }
//   if (publicUrl === "electronic") {
//     return process.env.MASTER_DETAIL_ID_ELECTRONIC;
//   }
//   if (publicUrl === "pabrai") {
//     return process.env.MASTER_DETAIL_ID_PABRAI;
//   }
//   if (publicUrl === "sufalam") {
//     return process.env.MASTER_DETAIL_ID_SUFALAM;
//   }
// };

// export const getCodeOfInstance = () => {
//   const publicUrl = window.location.pathname.split("/")[1];
//   if (publicUrl === "cg") {
//     return process.env.CODE_CG;
//   }
//   if (publicUrl === "sf") {
//     return process.env.CODE_SF;
//   }
//   if (publicUrl === "sj") {
//     return process.env.CODE_SJ;
//   }
//   if (publicUrl === "kj") {
//     return process.env.CODE_KJ;
//   }
//   if (publicUrl === "panam") {
//     return process.env.CODE_PANAM;
//   }
//   if (publicUrl === "nextview") {
//     return process.env.CODE_NEXTVIEW;
//   }
//   if (publicUrl === "nutland") {
//     return process.env.CODE_NUTLAND;
//   }
//   if (publicUrl === "jewel") {
//     return process.env.CODE_JEWEL;
//   }
//   if (publicUrl === "fmcg") {
//     return process.env.CODE_FMCG;
//   }
//   if (publicUrl === "electronic") {
//     return process.env.CODE_ELECTRONIC;
//   }
//   if (publicUrl === "pabrai") {
//     return process.env.CODE_PABRAI;
//   }
//   if (publicUrl === "sufalam") {
//     return process.env.CODE_SUFALAM;
//   }
// };

// Get Best Seller
export const getBestSeller = (products) => {
  const items = products.filter((product) => {
    return product.sale === true;
  });

  return items.slice(0, 8);
};

// Get Mens Wear
export const getMensWear = (products) => {
  const items = products.filter((product) => {
    return product.category === "men";
  });

  return items.slice(0, 8);
};

// Get Womens Wear
export const getWomensWear = (products) => {
  const items = products.filter((product) => {
    return product.category === "women";
  });

  return items.slice(0, 8);
};

// Get Single Product
export const getSingleItem = (products, id) => {
  const items = products.find((element) => {
    return element.id === id;
  });
  return items;
};

// Get Feature Products
export const getFeatureImages = (products, type) => {
  const items = products.filter((product) => {
    return product.type === type;
  });
  return items;
};
