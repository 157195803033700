import { IS_REGISTER_ALLOW, IS_INQUIRY } from "../constants/ActionTypes";
const initialState = {
  isRegisterAllow: false,
  isInquiry: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_REGISTER_ALLOW:
      return { ...state, isRegisterAllow: action.isRegisterAllow };
      
    case IS_INQUIRY:
      return { ...state, isInquiry: action.isInquiry };

    default:
      return state;
  }
};
export default settingsReducer;
