import React, { Component } from "react";
import { connect } from "react-redux";
import { getNotificationCounter } from "../../../actions";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../common/breadcrumb";
import {
  getCall,
  postCall,
  postCallWithMedia,
  patchCall,
} from "../../../api/post";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store";
import Avatar from "react-avatar";
import ImageUploader from "react-images-upload";
import {
  getProfileImageUrl,
  getMerchantInformationUrl,
  getCitiesUrl,
  getSignupOptionsUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";

const cityDropDownStyle = {
  fontSize: "12px",
  padding: "17px 25px",
  height: "54px",
  marginBottom: "50px",
};

const publicUrl = window.location.pathname.split("/")[1];
const client = process.env.CLIENT_NAME;

class MyProfile extends Component {
  state = {
    cellNumber: "",
    cities: [],
    userName: "",
    companyName: "",
    cityId: "",
    emailId: "",
    userDetails: {},
    hoverStyle: { height: "500px" },
    isHover: true,
    isLoading: true,
    gstin: "",
    AppTitle: "",
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
  };

  componentDidMount = () => {
    this.initMasterDetailsId().then(() => {
      this.initCities();
      this.initSignUpOptions();
      this.initMerchantInfo();
      this.initUserDetails().then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initCities = async () => {
    const { masterDetailsObj } = this.state;
    const cities = await getCall(getCitiesUrl(masterDetailsObj), []);
    this.setState({ cities: cities });
  };

  initUserDetails = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      this.props.history.push(`/login`);
      return;
    }
    const userDetailUrl = `users/${userDetails.id}`;
    const userDetailsResponse = await getCall(userDetailUrl, []);

    if (userDetailsResponse.error) {
      this.loadErrorMessageAnimation(userDetailsResponse.error.message);
      if (userDetailsResponse.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({
        userDetails: userDetailsResponse,
        userName: userDetailsResponse.username,
        cellNumber: userDetailsResponse.cellnumber,
        emailId: userDetailsResponse.email,
        companyName: userDetailsResponse.companyname,
        cityId: userDetailsResponse.cityId,
        gstin: userDetailsResponse.gstin,
      });
    }
  };

  initSignUpOptions = async () => {
    const { masterDetailsObj } = this.state;
    const signupOptions = await getCall(
      getSignupOptionsUrl(masterDetailsObj),
      []
    );
    if (signupOptions.error) {
    } else {
      if (signupOptions.length > 0) {
        this.setState({ signupOptions: JSON.parse(signupOptions[0].text) });
      }
    }
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  handleChangeOfUserName = (event) => {
    const regex = /^[a-zA-Z _]*$/;
    if (!regex.test(event.target.value)) {
      return;
    }
    this.setState({ userName: event.target.value });
  };

  handleChangeOfCellNumber = (event) => {
    const number = event.target.value.trim();

    if (!isNaN(number)) {
      this.setState({ cellNumber: number });
    } else {
      return;
    }
  };

  handleChangeOfCompanyName = (event) => {
    this.setState({ companyName: event.target.value });
  };
  handleChangeOfEmailId = (event) => {
    this.setState({ emailId: event.target.value });
  };

  handleChangeOfCity = (event) => {
    this.setState({ cityId: event.target.value });
  };

  handleChangeOfGSTIN = (event) => {
    this.setState({ gstin: event.target.value });
  };

  onClickOfSaveChanges = () => {
    const {
      userName,
      cellNumber,
      emailId,
      companyName,
      cityId,
      gstin,
      signupOptions,
    } = this.state;

    if (userName.trim().length < 3 || userName.trim().length === 0) {
      this.loadErrorMessageAnimation("Please Enter Valid User Name");
      return;
    }

    if (
      signupOptions[0].field_name === "Name" &&
      signupOptions[0].mandatory === 1 &&
      signupOptions[0].visible === 1 &&
      userName &&
      (userName.trim().length < 3 || userName.trim().length === 0)
    ) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("str_please_enter_valid_name")
          ? getValueFromLanguageObject("str_please_enter_valid_name")
          : "Please Enter Valid Name."
      );
      return;
    }

    if (
      signupOptions[5].field_name === "Mobile Number" &&
      signupOptions[5].mandatory === 1 &&
      signupOptions[5].visible === 1 &&
      cellNumber &&
      cellNumber.trim().length !== 10
    ) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("msg_enter_mobile_number_incorrect")
          ? getValueFromLanguageObject("msg_enter_mobile_number_incorrect")
          : "Please enter correct mobile number."
      );
      return;
    }

    if (emailId && emailId.trim().length > 0) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(emailId)) {
        this.loadErrorMessageAnimation(
          getValueFromLanguageObject("str_please_enter_valid_email_address")
            ? getValueFromLanguageObject("str_please_enter_valid_email_address")
            : "Please Enter Valid Email Address"
        );
        return;
      }
    }

    if (
      signupOptions[4].field_name === "Company Name" &&
      signupOptions[4].mandatory === 1 &&
      signupOptions[4].visible === 1 &&
      companyName &&
      companyName.trim().length === 0
    ) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("msg_enter_company_name_incorrect")
          ? getValueFromLanguageObject("msg_enter_company_name_incorrect")
          : "Please enter company name."
      );
      return;
    }

    if (cityId && cityId.trim().length === 0) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("str_please_select_city")
          ? getValueFromLanguageObject("str_please_select_city")
          : "Please Select City."
      );
      return;
    }

    if (
      (signupOptions[1].field_name === "Email" &&
        signupOptions[1].mandatory === 1 &&
        signupOptions[1].visible === 1) ||
      (emailId && emailId.trim().length > 0)
    ) {
      const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (!re.test(emailId)) {
        this.loadErrorMessageAnimation(
          getValueFromLanguageObject("str_please_enter_valid_email_address")
            ? getValueFromLanguageObject("str_please_enter_valid_email_address")
            : "Please Enter Valid Email Address."
        );
        return;
      }
    }

    if (
      (signupOptions[2].field_name === "GSTIN" &&
        signupOptions[2].mandatory === 1 &&
        signupOptions[2].visible === 1) ||
      (gstin && gstin.trim().length > 0)
    ) {
      const re = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
      if (!re.test(gstin)) {
        this.loadErrorMessageAnimation(
          getValueFromLanguageObject("str_please_enter_valid_gst_number")
            ? getValueFromLanguageObject("str_please_enter_valid_gst_number")
            : "Please Enter Valid GST Number."
        );
        return;
      }
    }

    this.editProfileInformation();
  };

  editProfileInformation = () => {
    try {
      this.setState({ isLoading: true }, async () => {
        const userDetails = loadUserDetailsFromLocalStorage();
        if (userDetails === undefined) {
          return;
        }
        const editProfileUrl = "users/" + userDetails.id;
        const editProfileObj = {
          username: this.state.userName,
          cellnumber: this.state.cellNumber,
          email: this.state.emailId ? this.state.emailId.toLowerCase() : "",
          companyname: this.state.companyName,
          cityId: this.state.cityId,
          gstin: this.state.gstin,
        };
        const editProfile = await patchCall(editProfileUrl, editProfileObj);
        this.setState({ isLoading: false });
        if (editProfile.error) {
          this.loadErrorMessageAnimation(editProfile.error.message);
          if (editProfile.error.statusCode === 401) {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("apiToken");
            this.props.history.push(`/login`);
            return;
          }
        } else {
          this.loadSuccessMessageAnimation(
            getValueFromLanguageObject(
              "str_profile_information_updated_successfully"
            )
              ? getValueFromLanguageObject(
                  "str_profile_information_updated_successfully"
                )
              : "Profile Information updated successfully"
          );
          this.setState({ isLoading: true }, () => {
            this.initUserDetails().then(() => {
              this.setState({ isLoading: false });
            });
          });
        }
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  toggleEnterHover = () => {
    this.setState({ isHover: true });
  };

  toggleLeaveHover = () => {
    this.setState({ isHover: true });
  };

  onClickOfEditProfile = async (picture) => {
    try {
      this.setState({ isLoading: true }, async () => {
        const uploadImageUrl = `containers/profilepic-${
          client ? client : publicUrl
        }/upload`;
        const uploadImage = await postCallWithMedia(uploadImageUrl, picture[0]);
        this.setState({ isLoading: false });
        if (uploadImage.error) {
          this.loadErrorMessageAnimation(uploadImage.error.message);
        } else {
          if (
            uploadImage &&
            uploadImage.result &&
            uploadImage.result.files &&
            uploadImage.result.files.file &&
            uploadImage.result.files.file.length > 0 &&
            uploadImage.result.files.file[0].name
          ) {
            const userDetails = loadUserDetailsFromLocalStorage();
            if (userDetails === undefined) {
              return;
            }
            const updateUserDetailUrl = `users/${userDetails.id}`;
            const updateUserDetailObj = {
              id: userDetails.id,
              profilepic: uploadImage.result.files.file[0].name,
            };
            const updateUserDetail = await patchCall(
              updateUserDetailUrl,
              updateUserDetailObj
            );
            if (updateUserDetail.error) {
              this.loadErrorMessageAnimation(updateUserDetail.error.message);
            } else {
              this.loadSuccessMessageAnimation(
                getValueFromLanguageObject(
                  "str_profile_picture_is_updated_successfully"
                )
                  ? getValueFromLanguageObject(
                      "str_profile_picture_is_updated_successfully"
                    )
                  : "Profile Picture is updated successfully"
              );
              this.setState({ isLoading: true }, () => {
                this.initUserDetails().then(() => {
                  this.setState({ isLoading: false });
                });
              });
            }
          }
        }
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  renderSaveChanges = () => {
    const {
      userDetails,
      userName,
      cellNumber,
      emailId,
      companyName,
      cityId,
      gstin,
    } = this.state;
    if (
      (userDetails && userDetails.username !== userName) ||
      userDetails.cellnumber !== cellNumber ||
      userDetails.email !== emailId ||
      userDetails.companyname !== companyName ||
      userDetails.cityId !== cityId ||
      userDetails.gstin !== gstin
    ) {
      return (
        <>
          {/* <div className="row"> */}
          {/* <div className="col-6" /> */}
          <div
            onClick={() => {
              this.onClickOfSaveChanges();
            }}
            className="btn btn-solid pull-right save-button"
            // style={{
            //   color: getColorCodeBasedOnKey("text_save_changes_my_profile"),
            //   backgroundImage: `linear-gradient(30deg, ${getColorCodeBasedOnKey(
            //     "bg_save_changes_my_profile"
            //   )} 50%, transparent 50%)`,
            //   float: "right",
            //   marginBottom: "50px",
            // }}
          >
            {getValueFromLanguageObject("str_save_changes")
              ? getValueFromLanguageObject("str_save_changes")
              : "Save Changes"}
          </div>
          {/* </div> */}
        </>
      );
    } else {
      return;
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      cities,
      userDetails,
      hoverStyle,
      isHover,
      isLoading,
      AppTitle,
      signupOptions,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      masterDetailsObj,
    } = this.state;
    return (
      <>
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>
              {AppTitle} |
              {getValueFromLanguageObject("str_my_profile_page")
                ? getValueFromLanguageObject("str_my_profile_page")
                : "My Profile Page"}
            </title>
            <meta
              name="description"
              content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
            />
          </Helmet>
          {/*SEO Support End */}

          <Breadcrumb title={"My Profile"} />

          <section
            className="login-page section-b-space"
            style={{
              border: `1px solid ${getColorCodeBasedOnKey(
                "text_heading_label_my_profile"
              )}`,
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                {/* <div className="col-lg-6">
                <h3
                  style={{
                    color: getColorCodeBasedOnKey(
                      "text_profile_label_my_profile"
                    ),
                  }}
                >
                  Profile
                </h3>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="theme-card">
                      <div
                        onMouseEnter={this.toggleEnterHover}
                        onMouseLeave={this.toggleLeaveHover}
                        style={hoverStyle}
                      >
                        <Avatar
                          style={{
                            left: "10%",
                            top: "10%",
                            position: "absolute",
                          }}
                          size="430"
                          name={
                            userDetails.profilepic ? "" : userDetails.username
                          }
                          round={true}
                          src={
                            userDetails.profilepic
                              ? getProfileImageUrl(userDetails.profilepic)
                              : ""
                          }
                        />
                      </div>
                      <ImageUploader
                        style={{ height: "50px" }}
                        buttonText="Upload Profile Image"
                        onChange={this.onClickOfEditProfile}
                        imgExtension={[".jpg", ".png"]}
                        maxFileSize={5242880}
                        singleImage
                        withIcon={false}
                        withLabel={false}
                        buttonStyles={{ padding: "5% 20%", fontSize: "15px" }}
                        fileContainerStyle={{
                          padding: "0",
                          boxShadow: "none",
                          borderRadius: 0,
                          background: "none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div> */}

                <div className="col-lg-6 col-md-4">
                  <h3
                    style={{
                      color: getColorCodeBasedOnKey(
                        "text_information_label_my_profile"
                      ),
                    }}
                  >
                    {getValueFromLanguageObject("str_personal_detail")
                      ? getValueFromLanguageObject("str_personal_detail")
                      : "PERSONAL DETAIL"}
                  </h3>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="theme-card">
                        <form className="theme-form">
                          <div className="form-group">
                            <div style={{ textAlign: "center" }}>
                              <Avatar
                                size="200"
                                name={
                                  userDetails.profilepic
                                    ? ""
                                    : userDetails.username
                                }
                                round={true}
                                src={
                                  userDetails.profilepic
                                    ? getProfileImageUrl(
                                        userDetails.profilepic,
                                        masterDetailsObj
                                      )
                                    : ""
                                }
                              />

                              <ImageUploader
                                buttonText="Upload Profile Image"
                                onChange={this.onClickOfEditProfile}
                                imgExtension={[".jpg", ".png"]}
                                maxFileSize={5242880}
                                singleImage
                                withIcon={false}
                                withLabel={false}
                                buttonStyles={{
                                  fontSize: "14px",
                                  padding: "10px",
                                }}
                                fileContainerStyle={{
                                  padding: "0",
                                  boxShadow: "none",
                                  borderRadius: 0,
                                  background: "none",
                                }}
                              />
                            </div>

                            {signupOptions &&
                              signupOptions.length > 0 &&
                              signupOptions[0].visible === 1 && (
                                <div>
                                  <label
                                    htmlFor="Name"
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_name_label_my_profile"
                                      ),
                                    }}
                                  >
                                    {getValueFromLanguageObject("str_name")
                                      ? getValueFromLanguageObject("str_name")
                                      : "Name"}
                                  </label>
                                  <input
                                    id="username"
                                    type="text"
                                    onChange={this.handleChangeOfUserName}
                                    className="form-control"
                                    value={this.state.userName}
                                    placeholder={
                                      getValueFromLanguageObject(
                                        "str_enter_your_name"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_enter_your_name"
                                          )
                                        : "Enter Your Name"
                                    }
                                    required={true}
                                    maxLength="30"
                                    minLength="2"
                                  />
                                </div>
                              )}
                            {signupOptions &&
                              signupOptions.length > 0 &&
                              signupOptions[5].visible === 1 && (
                                <div>
                                  <label
                                    htmlFor="cellnumber"
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_cell_number_label_my_profile"
                                      ),
                                    }}
                                  >
                                    {getValueFromLanguageObject("mobile_number")
                                      ? getValueFromLanguageObject(
                                          "mobile_number"
                                        )
                                      : "Mobile number"}
                                  </label>
                                  <input
                                    id="cellnumber"
                                    type="tel"
                                    onChange={this.handleChangeOfCellNumber}
                                    className="form-control"
                                    value={this.state.cellNumber}
                                    placeholder={
                                      getValueFromLanguageObject(
                                        "str_enter_mobile_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_enter_mobile_number"
                                          )
                                        : "Enter Mobile Number"
                                    }
                                    required={true}
                                    maxLength="10"
                                    pattern="[0-9]{10}"
                                  />
                                </div>
                              )}
                            {signupOptions &&
                              signupOptions.length > 0 &&
                              signupOptions[1].visible === 1 && (
                                <div>
                                  <label
                                    htmlFor="email"
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_email_label_my_profile"
                                      ),
                                    }}
                                  >
                                    {getValueFromLanguageObject(
                                      "str_email_address"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_email_address"
                                        )
                                      : "Email Address"}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="emailId"
                                    placeholder={
                                      getValueFromLanguageObject(
                                        "str_enter_email_address"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_enter_email_address"
                                          )
                                        : "Enter Email Address"
                                    }
                                    value={this.state.emailId}
                                    minLength="5"
                                    pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
                                    maxLength="64"
                                    onChange={this.handleChangeOfEmailId}
                                  />
                                </div>
                              )}

                            {signupOptions &&
                              signupOptions.length > 0 &&
                              signupOptions[2].visible === 1 && (
                                <div>
                                  <label
                                    htmlFor="gstin"
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_gstin_signup"
                                      ),
                                    }}
                                  >
                                    {getValueFromLanguageObject("str_gstin")
                                      ? getValueFromLanguageObject("str_gstin")
                                      : "GSTIN"}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="gstin"
                                    placeholder={
                                      getValueFromLanguageObject(
                                        "str_enter_gstin"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_enter_gstin"
                                          )
                                        : "Enter GSTIN"
                                    }
                                    required={
                                      signupOptions[2].mandatory === 1
                                        ? true
                                        : false
                                    }
                                    value={this.state.gstin}
                                    onChange={this.handleChangeOfGSTIN}
                                  />
                                </div>
                              )}

                            {signupOptions &&
                              signupOptions.length > 0 &&
                              signupOptions[3].visible === 1 && (
                                <div>
                                  <label
                                    htmlFor="companyname"
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_company_name_label_my_profile"
                                      ),
                                    }}
                                  >
                                    {getValueFromLanguageObject(
                                      "str_company_name"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_company_name"
                                        )
                                      : "Company Name"}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="companyname"
                                    placeholder={
                                      getValueFromLanguageObject(
                                        "str_enter_company_name"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_enter_company_name"
                                          )
                                        : "Enter Company Name"
                                    }
                                    required=""
                                    value={this.state.companyName}
                                    minLength="2"
                                    maxLength="30"
                                    onChange={this.handleChangeOfCompanyName}
                                  />
                                </div>
                              )}

                            {signupOptions &&
                              signupOptions.length > 0 &&
                              signupOptions[4].visible === 1 && (
                                <div>
                                  <label
                                    htmlFor="city"
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_city_label_my_profile"
                                      ),
                                    }}
                                  >
                                    {getValueFromLanguageObject("str_city")
                                      ? getValueFromLanguageObject("str_city")
                                      : "City"}
                                  </label>
                                  <select
                                    style={cityDropDownStyle}
                                    className="form-control custom-city-profile custom-city"
                                    name="city"
                                    required={true}
                                    value={this.state.cityId}
                                    onChange={this.handleChangeOfCity}
                                  >
                                    <option
                                      value={""}
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_select_city_label_my_profile"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_select_city"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_select_city"
                                          )
                                        : "Select City"}
                                    </option>
                                    {cities.map((city, index) => (
                                      <option
                                        key={index}
                                        value={city.id}
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_cities_label_my_profile"
                                          ),
                                        }}
                                      >
                                        {city.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            {this.renderSaveChanges()}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isLoading && <MainLoader />}
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {
    notificationCounter: getNotificationCounter,
  }
)(MyProfile);
