import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { SlideUpDown } from "../../../services/script";
import LogoImage from "../header/logo";
import { getCall } from "../../../api/post";
import {
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store";
import {
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import { Link } from "react-router-dom";

class MainFooter extends Component {
  state = {
    contactUs: {},
    showErrorMessageModal: false,
    errorMessage: "",
    masterDetailsObj: {},
  };

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      this.initContactUs();
      var contentwidth = window.innerWidth;
      if (contentwidth < 750) {
        SlideUpDown("footer-title");
      } else {
        var elems = document.querySelectorAll(".footer-title");
        [].forEach.call(elems, function(elemt) {
          let el = elemt.nextElementSibling;
          el.style = "display: block";
        });
      }
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initContactUs = async () => {
    const { masterDetailsObj } = this.state;
    const contantusUrl = getMerchantInformationUrl(masterDetailsObj);
    const contactUs = await getCall(contantusUrl, []);
    if (contactUs.error) {
      this.loadErrorMessageAnimation(contactUs.error.message);
      if (contactUs.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        // this.props.history.push("/login");
        return;
      }
    } else {
      this.setState({ contactUs });
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const { contactUs, showErrorMessageModal, errorMessage } = this.state;
    return (
      <>
        <footer className="footer-light">
          <div className="light-layout">
            <div className="container">
              <section className="small-section border-section border-top-0">
                <div className="row" />
              </section>
            </div>
          </div>
          <section className="section-b-space light-layout pt-5 pb-5">
            <div className="container">
              <div className="row footer-theme partition-f">
                <div className="col-md-3">
                  <div className="footer-contant">
                    <div className="footer-logo">
                      <LogoImage />
                    </div>
                    <div className="footer-social mt-0">
                      <ul>
                        {/* <li>
                        <Link to={"https://www.facebook.com/"}>
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </Link>
                      </li> */}
                        {/* <li>
                        <Link to={"https://plus.google.com/"}>
                          <i className="fa fa-google-plus" aria-hidden="true" />
                        </Link>
                      </li> */}
                        {/* <li>
                        <Link to={"https://twitter.com"}>
                          <i className="fa fa-twitter" aria-hidden="true" />
                        </Link>
                      </li> */}
                        {/* <li>
                        <Link to={"https://instagram.com"}>
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </Link>
                      </li> */}
                        {/* <li>
                        <Link to={"https://rss.com/"}>
                          <i className="fa fa-rss" aria-hidden="true" />
                        </Link>
                      </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="sub-title">
                    <div className="footer-title" />
                    <div className="footer-contant">
                      <ul className="contact-list">
                        {contactUs.address1 && (
                          <li
                            onClick={() => {
                              window.open(
                                `https://www.google.com.sa/maps/search/${
                                  contactUs.address1
                                },${
                                  contactUs.address2 ? contactUs.address2 : ""
                                }?hl=en`
                              );
                            }}
                          >
                            <a href>
                              <i className="fa fa-map-marker" />
                              {contactUs.address1},{contactUs.address2}
                            </a>
                          </li>
                        )}
                        {contactUs.cellnumber && (
                          <li>
                            <a href={`tel:${contactUs.cellnumber}`}>
                              <i className="fa fa-phone" />{" "}
                              {getValueFromLanguageObject("str_call_us")
                                ? getValueFromLanguageObject("str_call_us")
                                : "Call Us"}
                              : {contactUs.cellnumber}
                            </a>
                          </li>
                        )}

                        {contactUs.email && (
                          <li>
                            <a href={`mailto:${contactUs.email}`}>
                              <i className="fa fa-envelope" />{" "}
                              {getValueFromLanguageObject("str_email_address")
                                ? getValueFromLanguageObject(
                                    "str_email_address"
                                  )
                                : "Email Address"}{" "}
                              : {contactUs.email}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="sub-footer ">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-md-6 col-sm-12">
                  <div className="footer-end">
                    <p>
                      <i className="fa fa-copyright" aria-hidden="true" /> @2021
                      Sufalamtech Pvt Ltd |{" "}
                      <Link className="text-primary" to={`/privacypolicy`}>
                        Privacy Policy
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12">
                  <div className="payment-card-bottom">
                    <ul>
                      <li>
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/visa.png`}
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/mastercard.png`}
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/paypal.png`}
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/american-express.png`}
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/discover.png`}
                          alt=""
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

export default withRouter(MainFooter);
