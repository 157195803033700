const env = process.env.NODE_ENV;

if (env === "production") {
  require("isomorphic-fetch");
  require("es6-promise").polyfill();
}


const SERVER_URL = `${process.env.SERVER_URL}/api/`;

export const postCall = (url, data = {}) =>
  new Promise((resolve) => {
    fetch(`${SERVER_URL}${url}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        version: "1.0",
        Authorization: localStorage.getItem("apiToken"),
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.status === 204 ? {} : res.json();
      })
      .then((json) => resolve(json));
  });

export const patchCall = (url, data = {}) =>
  new Promise((resolve) => {
    fetch(`${SERVER_URL}${url}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        version: "1.0",
        Authorization: localStorage.getItem("apiToken"),
      },
      credentials: "include",
      method: "PATCH",
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.status === 204 ? {} : res.json();
      })
      .then((json) => resolve(json));
  });

export const getCall = (url, data = []) =>
  new Promise((resolve) => {
    fetch(`${SERVER_URL}${url}`, {
      headers: {
        Accept: "application/json",
        version: "1.0",
        Authorization: localStorage.getItem("apiToken"),
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.status === 204 ? {} : res.json())
      .then((json) => resolve(json));
  });

export const postCallWithMedia = (url, data = {}) =>
  new Promise((resolve) => {
    const formData = new FormData();
    // data.forEach((file) => {
    //   formData.append("files[]", file);
    // });
    formData.append("file", data);

    // Object.keys(data).forEach((d) => {
    //   formData.append(d, data[d]);
    // });

    fetch(`${SERVER_URL}${url}`, {
      headers: {
        Accept: "application/json",
        version: "1.0",
        Authorization: localStorage.getItem("apiToken"),
      },
      credentials: "include",
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.status === 204 ? {} : res.json();
      })
      .then((json) => resolve(json));
  });

// export const postAuth = (url, data = {}) =>
//   new Promise(resolve => {
//     if (url.indexOf("undefined") === -1) {
//       fetch(authUrl(url), {
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json"
//         },
//         credentials: "include",
//         method: "POST",
//         body: JSON.stringify(data)
//       })
//         .then(res => res.json())
//         .then(json => resolve(json));
//     }
//   });

// export const postAuthWithMedia = (url, data = {}) =>
//   new Promise((resolve) => {
//     const formData = new FormData();
//     data.mediaList.forEach((file) => {
//       formData.append("files[]", file);
//     });

//     Object.keys(data.data).forEach((d) => {
//       formData.append(d, data.data[d]);
//     });

//     fetch(authUrl(url), {
//       headers: {
//         Accept: "application/json",
//       },
//       credentials: "include",
//       method: "POST",
//       body: formData,
//     })
//       .then((res) => res.json())
//       .then((json) => resolve(json));
//   });
