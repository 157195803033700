import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";
import "./index.scss";

// Import custom components
import store, { getCurrentLanguage } from "./store";
import translations from "./constants/translations";
import {
  getCommonCounter,
  getNotificationCounter,
  isUserAllowedToRegister,
  isInquiryOrCart,
} from "./actions";

import MainLoader from "./animation/MainLoader";
import Routes from "./routes";
import { getMasterDetailsIdUrl } from "./services";
import { getCall } from "./api/post";

class Root extends React.Component {
  state = { colorCode: false, isLoading: false, masterDetailsObj: {} };

  componentDidMount() {
    this.initMasterDetailsId();
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  render() {
    const { isLoading, masterDetailsObj } = this.state;
    store.dispatch(
      getCommonCounter(),
      getNotificationCounter(),
      isUserAllowedToRegister(masterDetailsObj),
      isInquiryOrCart(masterDetailsObj)
    );

    return (
      <>
        {!isLoading && (
          <Provider store={store}>
            <IntlProvider
              translations={translations}
              locale={getCurrentLanguage()}
            >
              <Routes />
            </IntlProvider>
          </Provider>
        )}
      </>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
