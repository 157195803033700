import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../store";
import { getCall, patchCall, postCall } from "../../api/post";
import { payment } from "../../api/payment";
import { getNotificationCounter, isInquiryOrCart } from "../../actions";
import { CheckoutProvider, Checkout } from "paytm-blink-checkout-react";
import { paytmConfigure } from "./paytm_config";
import { GooglePayConfigure } from "./goople_pay_config";
import PaypalExpressBtn from "react-paypal-express-checkout";
import GooglePayButton from "@google-pay/button-react";
import {
  getSettingUrl,
  getShippingOptionsUrl,
  getMerchantInformationUrl,
  getPaymentDetailsUrl,
  getOrderViaWhatsAppUrl,
  getGroupWiseGSTConfigurationUrl,
  getPincodeDeliveryUrl,
  getMasterDetailsIdUrl,
} from "../../services";
import AddBillingAddressModal from "./AddBillingAddressModal";
import EditBillingAddressModal from "./EditBillingAddressModal";
import MainLoader from "../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../animation/SuccessMessageAnimationModal";
const roundTo = require("round-to");

const SSR_SIMULATE = "SSR Simulate : ";

class checkOut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartList: [],
      isLoading: true,
      merchantInfo: {},
      isMerchantGSTEnable: false,
      shippingCharges: [],
      paymentDetails: [],
      paymentMethod: "COD",
      groupWiseGSTINConfig: [],
      paytmConfig: {},
      showCheckout: false,
      openInPopup: true,
      checkoutJsInstance: null,
      textAreaValue: "",
      isPlaceOrderButtonDisable: false,
      payUMoneyConfig: {},
      payPalConfig: {},
      googlePayConfig: {},
      isPaymentDone: false,
      AppTitle: "",
      currentCatelogueType: {},
      isPincodeDeliveryEnable: false,
      userDetails: {},
      showErrorMessageModal: false,
      errorMessage: "",
      showSuccessMessageModal: false,
      successMessage: "",
      masterDetailsObj: {},
    };
  }

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      this.props.history.push(`/login`);
      return;
    }

    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.initCartList().then(() => {
        this.setState({ isLoading: false });
      });
      this.initMerchantInfo();
      this.initOrderViaWhatsApp();
      this.initShippingCharges();
      this.initPaymentDetails();
      this.initGroupWiseGSTINConfig();
      this.initCatelogueSettings();
      this.initPincodeDelivery();
      this.initUserDetails();
      this.props.notificationCounter();
      this.props.isInquiryOrCart(masterDetailsObj);
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initCartList = async () => {
    const { isInquiry } = this.props;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const cartListUrl = `orders?filter[where][userId]=${id}&filter[where][inshoppingcart]=${1}&filter[order]=created`;

    const cartList = await getCall(cartListUrl, []);
    if (cartList.error) {
      this.loadErrorMessageAnimation(cartList.error.message);
      if (cartList.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      if (cartList.length === 0) {
        this.props.history.push("/");
        return;
      }
      this.setState({ cartList });
    }
  };

  initPaymentDetails = async () => {
    const { masterDetailsObj } = this.state;
    const paymentDetailsUrl = getPaymentDetailsUrl(masterDetailsObj);
    const paymentDetails = await getCall(paymentDetailsUrl, []);
    if (paymentDetails.error) {
      this.loadErrorMessageAnimation(paymentDetails.error.message);
    } else {
      this.setState({ paymentDetails });
    }
  };

  initShippingCharges = async () => {
    const { masterDetailsObj } = this.state;
    const shippingChargesUrl = getShippingOptionsUrl(masterDetailsObj);
    const shippingCharges = await getCall(shippingChargesUrl, []);
    this.setState({ shippingCharges: shippingCharges });
  };

  // initCountryList = async () => {
  //   const countryUrl = `states?getCountries=getCountries`;
  //   const countryList = await getCall(countryUrl, []);
  //   if (countryList.error) {
  //     if (countryList.error.statusCode === 401) {
  //       localStorage.removeItem("userDetails");
  //       localStorage.removeItem("apiToken");
  //       this.props.history.push(`/login`);
  //       return;
  //     }
  //   } else {
  //     this.setState({ countryList: countryList.data });
  //   }
  // };

  // initShippingCountryList = async () => {
  //   const countryUrl = `states?getCountries=getCountries`;
  //   const countryList = await getCall(countryUrl, []);
  //   if (countryList.error) {
  //     if (countryList.error.statusCode === 401) {
  //       localStorage.removeItem("userDetails");
  //       localStorage.removeItem("apiToken");
  //       this.props.history.push(`/login`);
  //       return;
  //     }
  //   } else {
  //     this.setState({ shippingCountryList: countryList.data });
  //   }
  // };

  // initStateList = async () => {
  //   const stateUrl =
  //     `states?filter[where][parentId]=` + this.state.billingCountry;
  //   const stateList = await getCall(stateUrl, []);
  //   if (stateList.error) {
  //     if (stateList.error.statusCode === 401) {
  //       localStorage.removeItem("userDetails");
  //       localStorage.removeItem("apiToken");
  //       this.props.history.push(`/login`);
  //       return;
  //     }
  //   } else {
  //     this.setState({ stateList: stateList.data });
  //   }
  // };

  // initShippingStateList = async () => {
  //   const stateUrl =
  //     `states?filter[where][parentId]=` + this.state.shippingCountry;
  //   const stateList = await getCall(stateUrl, []);
  //   if (stateList.error) {
  //     if (stateList.error.statusCode === 401) {
  //       localStorage.removeItem("userDetails");
  //       localStorage.removeItem("apiToken");
  //       this.props.history.push(`/login`);
  //       return;
  //     }
  //   } else {
  //     this.setState({ shippingStateList: stateList.data });
  //   }
  // };

  // initCityList = async () => {
  //   const cityUrl = `cities?filter[where][stateId]=` + this.state.billingState;
  //   const cityList = await getCall(cityUrl, []);
  //   if (cityList.error) {
  //     if (cityList.error.statusCode === 401) {
  //       localStorage.removeItem("userDetails");
  //       localStorage.removeItem("apiToken");
  //       this.props.history.push(`/login`);
  //       return;
  //     }
  //   } else {
  //     this.setState({ cityList: cityList.data });
  //   }
  // };

  // initShippingCityList = async () => {
  //   const cityUrl = `cities?filter[where][stateId]=` + this.state.shippingState;
  //   const cityList = await getCall(cityUrl, []);
  //   if (cityList.error) {
  //     if (cityList.error.statusCode === 401) {
  //       localStorage.removeItem("userDetails");
  //       localStorage.removeItem("apiToken");
  //       this.props.history.push(`/login`);
  //       return;
  //     }
  //   } else {
  //     this.setState({ shippingCityList: cityList.data });
  //   }
  // };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initOrderViaWhatsApp = async () => {
    const { masterDetailsObj } = this.state;
    const orderViaWhatsappUrl = getOrderViaWhatsAppUrl(masterDetailsObj);
    const orderViaWhatsappResponse = await getCall(orderViaWhatsappUrl, []);
    if (orderViaWhatsappResponse.error) {
      this.setState({ orderViaWhatsapp: {} });
    } else {
      if (orderViaWhatsappResponse.length > 0) {
        this.setState({
          orderViaWhatsapp: orderViaWhatsappResponse[0],
        });
      } else {
        this.setState({ orderViaWhatsapp: {} });
      }
    }
  };

  initUserDetails = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      this.props.history.push(`/login`);
      return;
    }
    const userDetailUrl = `users/${userDetails.id}`;
    const userDetailsResponse = await getCall(userDetailUrl, []);

    if (userDetailsResponse.error) {
      this.loadErrorMessageAnimation(userDetailsResponse.error.message);
      if (userDetailsResponse.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ userDetails: userDetailsResponse });
    }
  };

  // preFillUpBillingDetailForm = async () => {
  //   const userDetails = loadUserDetailsFromLocalStorage();

  //   if (userDetails === undefined) {
  //     return;
  //   }

  //   const { billingaddress, shippingaddress } = userDetails;
  //   const billingAddress = JSON.parse(billingaddress);
  //   const shippingAddress = JSON.parse(shippingaddress);
  //   if (billingAddress) {
  //     const {
  //       name,
  //       mobile,
  //       email,
  //       companyname,
  //       gstin,
  //       address1,
  //       address2,
  //       landmark,
  //       cityId,
  //       stateId,
  //       countryId,
  //       zipcode,
  //     } = billingAddress;
  //     this.setState({
  //       billingName: name,
  //       billingMobileNumber: mobile,
  //       billingEmailAddress: email,
  //       billingCompanyName: companyname,
  //       billingGSTIN: gstin,
  //       billingAddressLine1: address1,
  //       billingAddressLine2: address2,
  //       billingLandmark: landmark,
  //       billingPincode: zipcode,
  //     });
  //     this.initCountryList().then(() => {
  //       this.setState({ billingCountry: countryId }, () => {
  //         this.initStateList().then(() => {
  //           this.setState({ billingState: stateId }, () => {
  //             this.initCityList().then(() => {
  //               this.setState({ billingCity: cityId });
  //             });
  //           });
  //         });
  //       });
  //     });
  //   }

  //   if (shippingAddress) {
  //     const {
  //       name,
  //       mobile,
  //       email,
  //       companyname,
  //       gstin,
  //       address1,
  //       address2,
  //       landmark,
  //       cityId,
  //       stateId,
  //       countryId,
  //       zipcode,
  //     } = shippingAddress;
  //     this.setState({
  //       shippingName: name,
  //       shippingMobileNumber: mobile,
  //       shippingEmailAddress: email,
  //       shippingCompanyName: companyname,
  //       shippingGSTIN: gstin,
  //       shippingAddressLine1: address1,
  //       shippingAddressLine2: address2,
  //       shippingLandmark: landmark,
  //       shippingPincode: zipcode,
  //     });
  //     this.initShippingCountryList().then(() => {
  //       this.setState({ shippingCountry: countryId }, () => {
  //         this.initShippingStateList().then(() => {
  //           this.setState({ shippingState: stateId }, () => {
  //             this.initShippingCityList().then(() => {
  //               this.setState({ shippingCity: cityId });
  //             });
  //           });
  //         });
  //       });
  //     });
  //   }
  // };

  initGroupWiseGSTINConfig = async () => {
    const { masterDetailsObj } = this.state;
    const groupWiseGSTINConfigUrl = getGroupWiseGSTConfigurationUrl(
      masterDetailsObj
    );
    const groupWiseGSTINConfig = await getCall(groupWiseGSTINConfigUrl, []);
    if (groupWiseGSTINConfig.error) {
      this.loadErrorMessageAnimation(groupWiseGSTINConfig.error.message);
    } else {
      if (
        groupWiseGSTINConfig &&
        groupWiseGSTINConfig.length > 0 &&
        groupWiseGSTINConfig[0].status === 1
      ) {
        this.setState({
          groupWiseGSTINConfig: JSON.parse(groupWiseGSTINConfig[0].text),
        });
      }
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initPincodeDelivery = async () => {
    const { masterDetailsObj } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const pincodeDelivery = await getCall(
      getPincodeDeliveryUrl(masterDetailsObj),
      []
    );
    if (pincodeDelivery.error) {
      this.loadErrorMessageAnimation(pincodeDelivery.error.message);
    } else {
      if (pincodeDelivery.length > 0) {
        const status = pincodeDelivery[0].status;
        this.setState({ isPincodeDeliveryEnable: status === 1 ? true : false });
      }
    }
  };

  // onChangeOfShippingCheckbox = (event) => {
  //   const {
  //     billingName,
  //     billingMobileNumber,
  //     billingEmailAddress,
  //     billingCompanyName,
  //     billingGSTIN,
  //     billingAddressLine1,
  //     billingAddressLine2,
  //     billingLandmark,
  //     billingCountry,
  //     billingState,
  //     billingCity,
  //     billingPincode,
  //   } = this.state;
  //   const isChecked = event.target.checked;
  //   this.setState({ chkShippingAddress: event.target.checked }, () => {
  //     if (isChecked) {
  //       this.setState({
  //         shippingName: billingName,
  //         shippingMobileNumber: billingMobileNumber,
  //         shippingEmailAddress: billingEmailAddress,
  //         shippingCompanyName: billingCompanyName,
  //         shippingGSTIN: billingGSTIN,
  //         shippingAddressLine1: billingAddressLine1,
  //         shippingAddressLine2: billingAddressLine2,
  //         shippingLandmark: billingLandmark,
  //         shippingPincode: billingPincode,
  //       });
  //       this.initShippingCountryList().then(() => {
  //         this.setState({ shippingCountry: billingCountry }, () => {
  //           this.initShippingStateList().then(() => {
  //             this.setState({ shippingState: billingState }, () => {
  //               this.initShippingCityList().then(() => {
  //                 this.setState({ shippingCity: billingCity });
  //               });
  //             });
  //           });
  //         });
  //       });
  //     }
  //   });
  // };

  // onChangeOfBillingName = (event) => {
  //   this.setState({ billingName: event.target.value });
  // };

  // onChangeOfBillingMobileNumber = (event) => {
  //   this.setState({ billingMobileNumber: event.target.value });
  // };
  // onChangeOfBillingEmailAddress = (event) => {
  //   this.setState({ billingEmailAddress: event.target.value });
  // };
  // onChangeOfBillingCompanyName = (event) => {
  //   this.setState({ billingCompanyName: event.target.value });
  // };

  // onChangeOfBillingGSTIN = (event) => {
  //   this.setState({ billingGSTIN: event.target.value });
  // };

  // onChangeOfBillingAddressLine1 = (event) => {
  //   this.setState({ billingAddressLine1: event.target.value });
  // };

  // onChangeOfBillingAddressLine2 = (event) => {
  //   this.setState({ billingAddressLine2: event.target.value });
  // };

  // onChangeOfBillingLandmark = (event) => {
  //   this.setState({ billingLandmark: event.target.value });
  // };

  // onChangeOfBillingCountry = (event) => {
  //   this.setState({ billingCountry: event.target.value }, () => {
  //     this.initStateList();
  //   });
  // };

  // onChangeOfBillingState = (event) => {
  //   this.setState({ billingState: event.target.value }, () => {
  //     this.initCityList();
  //   });
  // };

  // onChangeOfBillingCity = (event) => {
  //   this.setState({ billingCity: event.target.value });
  // };

  // onChangeOfBillingPincode = (event) => {
  //   this.setState({ billingPincode: event.target.value });
  // };

  // onChangeOfShippingName = (event) => {
  //   this.setState({ shippingName: event.target.value });
  // };

  // onChangeOfShippingMobileNumber = (event) => {
  //   this.setState({ shippingMobileNumber: event.target.value });
  // };
  // onChangeOfShippingEmailAddress = (event) => {
  //   this.setState({ shippingEmailAddress: event.target.value });
  // };
  // onChangeOfShippingCompanyName = (event) => {
  //   this.setState({ shippingCompanyName: event.target.value });
  // };

  // onChangeOfShippingGSTIN = (event) => {
  //   this.setState({ shippingGSTIN: event.target.value });
  // };

  // onChangeOfShippingAddressLine1 = (event) => {
  //   this.setState({ shippingAddressLine1: event.target.value });
  // };

  // onChangeOfShippingAddressLine2 = (event) => {
  //   this.setState({ shippingAddressLine2: event.target.value });
  // };

  // onChangeOfShippingLandmark = (event) => {
  //   this.setState({ shippingLandmark: event.target.value });
  // };

  // onChangeOfShippingCountry = (event) => {
  //   this.setState({ shippingCountry: event.target.value }, () => {
  //     this.initShippingStateList();
  //   });
  // };

  // onChangeOfShippingState = (event) => {
  //   this.setState({ shippingState: event.target.value }, () => {
  //     this.initShippingCityList();
  //   });
  // };

  // onChangeOfShippingCity = (event) => {
  //   this.setState({ shippingCity: event.target.value });
  // };

  // onChangeOfShippingPincode = (event) => {
  //   this.setState({ shippingPincode: event.target.value });
  // };

  onChangeOfOrderNotes = (event) => {
    this.setState({ orderNotes: event.target.value });
  };

  MakePaytmPayment = () => {
    this.loadCheckoutScript();
  };

  MakePayUMoneyPayment = () => {
    this.loadPayUMoneyBoltCheckout();
  };

  getPayPalPaymentAfterDetails = async () => {
    const { cartList, paymentResponse, paymentMethod } = this.state;
    const updatePaymentTransactionIdUrl = `orders/${this.getOrderId(cartList)}`;
    let transactionNumber = "";
    if (paymentMethod === "Paypal") {
      transactionNumber = paymentResponse.paymentID;
    }

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: transactionNumber,
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      // orderId : ""
    };
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    if (updatePaymentTransactionId.error) {
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ isLoading: true });
      this.placeAnOrder().then(() => {
        this.setState({ isLoading: false });
      });
    }
  };

  getGooglePayPaymentAfterDetails = async () => {
    const { cartList, paymentResponse, paymentMethod } = this.state;
    const updatePaymentTransactionIdUrl = `orders/${this.getOrderId(cartList)}`;
    let transactionNumber = "";
    if (paymentMethod === "GooglePay") {
      transactionNumber =
        paymentResponse.paymentMethodData.tokenizationData.token;
    }

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: transactionNumber,
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      // orderId : ""
    };
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    if (updatePaymentTransactionId.error) {
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ isLoading: true });
      this.placeAnOrder().then(() => {
        this.setState({ isLoading: false });
      });
    }
  };

  onClickOfPlaceOrder = () => {
    const { currentCatelogueType, userDetails } = this.state;
    const { billingaddress, shippingaddress } = userDetails;
    const billingAddress = JSON.parse(billingaddress)
      ? JSON.parse(billingaddress)
      : null;
    const shippingAddress = JSON.parse(shippingaddress)
      ? JSON.parse(shippingaddress)
      : null;

    const { groupWiseGSTINConfig } = this.state;
    let found = false;
    if (
      userDetails &&
      userDetails.groupId !== null &&
      userDetails.groupId !== undefined
    ) {
      const temp = groupWiseGSTINConfig.map((groupId) => {
        if (userDetails.groupId === groupId) {
          found = true;
        }
        return groupId;
      });

      if (
        found &&
        currentCatelogueType &&
        currentCatelogueType.registerallow === "Catalogue_FMCG"
      ) {
        if (
          billingAddress.companyname === undefined ||
          billingAddress.companyname === "" ||
          billingAddress.companyname.trim().length === 0
        ) {
          this.loadErrorMessageAnimation(
            "Company Name is required in billing address, Please add!"
          );
          return;
        }
        if (
          billingAddress.gstin === undefined ||
          billingAddress.gstin === "" ||
          billingAddress.gstin.trim().length === 0
        ) {
          this.loadErrorMessageAnimation(
            "GST Number is required in billing address, Please add!"
          );
          return;
        }
        if (
          shippingAddress.companyname === undefined ||
          shippingAddress.companyname === "" ||
          shippingAddress.companyname.trim().length === 0
        ) {
          this.loadErrorMessageAnimation(
            "Company Name is required in shipping address, Please add!"
          );
          return;
        }
        if (
          shippingAddress.gstin === undefined ||
          shippingAddress.gstin === "" ||
          shippingAddress.gstin.trim().length === 0
        ) {
          this.loadErrorMessageAnimation(
            "Company Name is required in shipping address, Please add!"
          );
          return;
        }
      }
    }
    this.paymnetDetailStatus();
  };

  // onClickOfPlaceOrder = (isOrderViaWhatsapp) => {
  //   const { currentCatelogueType } = this.state;
  //   // isOrderViaWhatsapp = 0 -> Click on Place Order
  //   // isOrderViaWhatsapp = 1 -> Click on Order Via WhatsApp

  //   const userDetails = loadUserDetailsFromLocalStorage();
  //   if (userDetails === undefined) {
  //     this.props.history.push(`/login`);
  //     return;
  //   }

  //   if (!this.state.chkShippingAddress) {
  //     const {
  //       billingName,
  //       billingMobileNumber,
  //       billingEmailAddress,
  //       billingCompanyName,
  //       billingGSTIN,
  //       billingAddressLine1,
  //       billingAddressLine2,
  //       billingLandmark,
  //       billingCountry,
  //       billingState,
  //       billingCity,
  //       billingPincode,
  //       groupWiseGSTINConfig,
  //     } = this.state;
  //     let found = false;
  //     if (
  //       userDetails &&
  //       userDetails.groupId !== null &&
  //       userDetails.groupId !== undefined
  //     ) {
  //       const temp = groupWiseGSTINConfig.forEach((groupId) => {
  //         if (userDetails.groupId === groupId) {
  //           found = true;
  //         }
  //         return groupId;
  //       });
  //     }
  //     if (
  //       found &&
  //       currentCatelogueType &&
  //       currentCatelogueType.registerallow === "Catalogue_FMCG"
  //     ) {
  //       if (
  //         billingCompanyName === undefined ||
  //         billingCompanyName === "" ||
  //         billingCompanyName.trim().length === 0
  //       ) {
  //         return;
  //       }
  //       if (
  //         billingGSTIN === undefined ||
  //         billingGSTIN === "" ||
  //         billingGSTIN.trim().length === 0
  //       ) {
  //         return;
  //       }
  //     }

  //     this.setState(
  //       {
  //         shippingName: billingName,
  //         shippingMobileNumber: billingMobileNumber,
  //         shippingEmailAddress: billingEmailAddress,
  //         shippingCompanyName: billingCompanyName,
  //         shippingGSTIN: billingGSTIN,
  //         shippingAddressLine1: billingAddressLine1,
  //         shippingAddressLine2: billingAddressLine2,
  //         shippingLandmark: billingLandmark,
  //         shippingPincode: billingPincode,
  //       },
  //       () => {
  //         this.initShippingCountryList().then(() => {
  //           this.setState(
  //             { shippingCountry: this.state.billingCountry },
  //             () => {
  //               this.initShippingStateList().then(() => {
  //                 this.setState(
  //                   { shippingState: this.state.billingState },
  //                   () => {
  //                     this.initShippingCityList().then(() => {
  //                       this.setState(
  //                         { shippingCity: this.state.billingCity },
  //                         () => {
  //                           if (this.validator.allValid()) {
  //                             // update user detail
  //                             this.updateUserDetails(isOrderViaWhatsapp);
  //                           } else {
  //                             this.validator.showMessages();
  //                             this.forceUpdate();
  //                           }
  //                         }
  //                       );
  //                     });
  //                   }
  //                 );
  //               });
  //             }
  //           );
  //         });
  //       }
  //     );
  //   } else {
  //     if (this.validator.allValid()) {
  //       // update user detail
  //       const {
  //         billingCompanyName,
  //         billingGSTIN,
  //         shippingCompanyName,
  //         shippingGSTIN,
  //         groupWiseGSTINConfig,
  //       } = this.state;
  //       let found = false;
  //       if (
  //         userDetails &&
  //         userDetails.groupId !== null &&
  //         userDetails.groupId !== undefined
  //       ) {
  //         const temp = groupWiseGSTINConfig.map((groupId) => {
  //           if (userDetails.groupId === groupId) {
  //             found = true;
  //           }
  //           return groupId;
  //         });

  //         if (
  //           found &&
  //           currentCatelogueType &&
  //           currentCatelogueType.registerallow === "Catalogue_FMCG"
  //         ) {
  //           if (
  //             billingCompanyName === undefined ||
  //             billingCompanyName === "" ||
  //             billingCompanyName.trim().length === 0
  //           ) {
  //             return;
  //           }
  //           if (
  //             billingGSTIN === undefined ||
  //             billingGSTIN === "" ||
  //             billingGSTIN.trim().length === 0
  //           ) {
  //             return;
  //           }
  //           if (
  //             shippingCompanyName === undefined ||
  //             shippingCompanyName === "" ||
  //             shippingCompanyName.trim().length === 0
  //           ) {
  //             return;
  //           }
  //           if (
  //             shippingGSTIN === undefined ||
  //             shippingGSTIN === "" ||
  //             shippingGSTIN.trim().length === 0
  //           ) {
  //             return;
  //           }
  //         }
  //       }
  //       this.updateUserDetails(isOrderViaWhatsapp);
  //     } else {
  //       this.validator.showMessages();
  //       this.forceUpdate();
  //     }
  //   }
  // };

  // updateUserDetails = async (isOrderViaWhatsapp) => {
  //   const {
  //     billingName,
  //     billingMobileNumber,
  //     billingEmailAddress,
  //     billingCompanyName,
  //     billingGSTIN,
  //     billingAddressLine1,
  //     billingAddressLine2,
  //     billingLandmark,
  //     billingCountry,
  //     billingState,
  //     billingCity,
  //     billingPincode,
  //     shippingName,
  //     shippingMobileNumber,
  //     shippingEmailAddress,
  //     shippingCompanyName,
  //     shippingGSTIN,
  //     shippingAddressLine1,
  //     shippingAddressLine2,
  //     shippingLandmark,
  //     shippingCountry,
  //     shippingState,
  //     shippingCity,
  //     shippingPincode,
  //     AppTitle,
  //   } = this.state;
  //   const userDetails = loadUserDetailsFromLocalStorage();
  //   if (userDetails === undefined) {
  //     return;
  //   }
  //   const { id } = userDetails;
  //   const userDetailsUrl = "users/" + id;
  //   const userDetailsObj = {
  //     shippingaddress: {
  //       name: shippingName,
  //       mobile: shippingMobileNumber,
  //       email: shippingEmailAddress,
  //       companyname: shippingCompanyName,
  //       gstin: shippingGSTIN,
  //       address1: shippingAddressLine1,
  //       address2: shippingAddressLine2,
  //       landmark: shippingLandmark,
  //       countryId: shippingCountry,
  //       stateId: shippingState,
  //       cityId: shippingCity,
  //       zipcode: shippingPincode,
  //     },
  //     billingaddress: {
  //       name: billingName,
  //       mobile: billingMobileNumber,
  //       email: billingEmailAddress,
  //       companyname: billingCompanyName,
  //       gstin: billingGSTIN,
  //       address1: billingAddressLine1,
  //       address2: billingAddressLine2,
  //       landmark: billingLandmark,
  //       countryId: billingCountry,
  //       stateId: billingState,
  //       cityId: billingCity,
  //       zipcode: billingPincode,
  //     },
  //   };
  //   this.setState({ isLoading: true });
  //   const updateUserDetails = await patchCall(userDetailsUrl, userDetailsObj);
  //   this.setState({ isLoading: false });
  //   if (updateUserDetails.error) {
  //     if (updateUserDetails.error.statusCode === 401) {
  //       localStorage.removeItem("userDetails");
  //       localStorage.removeItem("apiToken");
  //       this.props.history.push(`/login`);
  //       return;
  //     }
  //   } else {
  //     saveUserDetailsToLocalStorage(updateUserDetails);
  //     this.paymnetDetailStatus(isOrderViaWhatsapp);
  //   }
  // };

  onClickOfOrderViaWhatsApp = () => {
    const { userDetails, cartList, AppTitle, orderViaWhatsapp } = this.state;
    const { symbol, isInquiry } = this.props;

    let CustomerDetails = `${userDetails.username},\n${
      userDetails.cellnumber
    },`;

    if (!isInquiry) {
      const { billingaddress, shippingaddress } = userDetails;
      const billingaddressObj = billingaddress
        ? JSON.parse(billingaddress)
        : null;
      const shippingaddressObj = shippingaddress
        ? JSON.parse(shippingaddress)
        : null;

      var productList = "";
      if (cartList && cartList.length > 0) {
        cartList[0].orderdetail.map((d, index) => {
          return (productList = productList.concat(
            ` ${d.products.name} : (${symbol}${parseFloat(d.amount).toFixed(
              2
            )} × ${d.quantity})\n `
          ));
        });
      }

      let TotalAmount = this.getGrandTotal(
        this.getTotalofCart(cartList),
        this.getCGSTFromTotal(this.getTotalofCart(cartList)),
        this.getSGSTFromTotal(this.getTotalofCart(cartList)),
        this.getIGSTFromTotal(this.getTotalofCart(cartList)),
        this.getShippingCharges(this.getTotalofCart(cartList))
      );

      let billingAddressText = "";
      let shippingAddressText = "";

      if (billingaddressObj.name) {
        billingAddressText = billingAddressText.concat(
          `\nname : ${billingaddressObj.name}\n`
        );
      }

      if (billingaddressObj.mobile) {
        billingAddressText = billingAddressText.concat(
          `mobile : ${billingaddressObj.mobile}\n`
        );
      }

      if (billingaddressObj.email) {
        billingAddressText = billingAddressText.concat(
          `email : ${billingaddressObj.email}\n`
        );
      }

      if (billingaddressObj.companyname) {
        billingAddressText = billingAddressText.concat(
          `companyname : ${billingaddressObj.companyname}\n`
        );
      }

      if (billingaddressObj.gstin) {
        billingAddressText = billingAddressText.concat(
          `gstin : ${billingaddressObj.gstin}\n`
        );
      }

      if (billingaddressObj.address1) {
        billingAddressText = billingAddressText.concat(
          `address1 : ${billingaddressObj.address1}\n`
        );
      }

      if (billingaddressObj.address2) {
        billingAddressText = billingAddressText.concat(
          `address2 : ${billingaddressObj.address2}\n`
        );
      }

      if (billingaddressObj.landmark) {
        billingAddressText = billingAddressText.concat(
          `landmark : ${billingaddressObj.landmark}\n`
        );
      }

      if (billingaddressObj.cityname) {
        billingAddressText = billingAddressText.concat(
          `city : ${billingaddressObj.cityname}\n`
        );
      }
      if (billingaddressObj.statename) {
        billingAddressText = billingAddressText.concat(
          `state : ${billingaddressObj.statename}\n`
        );
      }
      if (billingaddressObj.countryname) {
        billingAddressText = billingAddressText.concat(
          `country : ${billingaddressObj.countryname}\n`
        );
      }

      if (billingaddressObj.zipcode) {
        billingAddressText = billingAddressText.concat(
          `zipcode : ${billingaddressObj.zipcode}`
        );
      }

      if (shippingaddressObj.name) {
        shippingAddressText = shippingAddressText.concat(
          `\nname : ${shippingaddressObj.name}\n`
        );
      }

      if (shippingaddressObj.mobile) {
        shippingAddressText = shippingAddressText.concat(
          `mobile : ${shippingaddressObj.mobile}\n`
        );
      }

      if (shippingaddressObj.email) {
        shippingAddressText = shippingAddressText.concat(
          `email : ${shippingaddressObj.email}\n`
        );
      }

      if (shippingaddressObj.companyname) {
        shippingAddressText = shippingAddressText.concat(
          `companyname : ${shippingaddressObj.companyname}\n`
        );
      }

      if (shippingaddressObj.gstin) {
        shippingAddressText = shippingAddressText.concat(
          `gstin : ${shippingaddressObj.gstin}\n`
        );
      }

      if (shippingaddressObj.address1) {
        shippingAddressText = shippingAddressText.concat(
          `address1 : ${shippingaddressObj.address1}\n`
        );
      }

      if (shippingaddressObj.address2) {
        shippingAddressText = shippingAddressText.concat(
          `address2 : ${shippingaddressObj.address2}\n`
        );
      }

      if (shippingaddressObj.landmark) {
        shippingAddressText = shippingAddressText.concat(
          `landmark : ${shippingaddressObj.landmark}\n`
        );
      }

      if (shippingaddressObj.cityname) {
        shippingAddressText = shippingAddressText.concat(
          `city : ${shippingaddressObj.cityname}\n`
        );
      }
      if (shippingaddressObj.statename) {
        shippingAddressText = shippingAddressText.concat(
          `state : ${shippingaddressObj.statename}\n`
        );
      }
      if (shippingaddressObj.countryname) {
        shippingAddressText = shippingAddressText.concat(
          `country : ${shippingaddressObj.countryname}\n`
        );
      }

      if (shippingaddressObj.zipcode) {
        shippingAddressText = shippingAddressText.concat(
          `zipcode : ${shippingaddressObj.zipcode}`
        );
      }

      let orderNotesText = "";
      if (this.state.orderNotes) {
        orderNotesText = `Order Notes : ${this.state.orderNotes}`;
      }

      let text = `New order - ${AppTitle}\nOrderId : ${this.getOrderId(
        cartList
      )}\nItems : ${productList} \nAmount Payable : ${TotalAmount}\n\nCustomer Details : \n${CustomerDetails} \nBilling Address : ${billingAddressText} \n\nShipping Address : ${shippingAddressText} \n\n${orderNotesText}`;
      const mobileNumber = JSON.parse(orderViaWhatsapp.text).cellnumber;
      var whatsAppLink = `https://api.whatsapp.com/send?phone=91${mobileNumber}&text=${encodeURI(
        text
      )}`;
      window.open(whatsAppLink);
      return;
    } else {
      var productList = "";
      if (cartList && cartList.length > 0) {
        cartList[0].orderdetail.map((d, index) => {
          return (productList = productList.concat(
            ` ${d.products.name} - Qty : ${d.quantity}\n `
          ));
        });
      }

      let text = `New Inquiry - ${AppTitle}\nInquiryId : ${this.getOrderId(
        cartList
      )}\nItems : ${productList} \nCustomer Details : \n${CustomerDetails}`;

      const mobileNumber = JSON.parse(orderViaWhatsapp.text).cellnumber;
      var whatsAppLink = `https://api.whatsapp.com/send?phone=91${mobileNumber}&text=${encodeURI(
        text
      )}`;
      window.open(whatsAppLink);
      return;
    }
  };

  paymnetDetailStatus = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { paymentMethod, paymentDetails } = this.state;

    if (
      paymentDetails &&
      paymentDetails.length > 0 &&
      paymentDetails[0].status === 0
    ) {
      this.setState({ isLoading: true });
      this.placeAnOrder().then(() => {
        this.setState({ isLoading: false });
      });
    } else {
      if (paymentMethod === "COD") {
        this.initUpdatePaymentTransactionId();
      } else if (paymentMethod === "RazorPay") {
        this.initRazorPayPayment();
      } else if (paymentMethod === "Paytm") {
        this.MakePaytmPayment();
      } else if (paymentMethod === "PayU") {
        this.MakePayUMoneyPayment();
      } else if (paymentMethod === "Paypal") {
        this.getPayPalPaymentAfterDetails();
      } else if (paymentMethod === "GooglePay") {
        this.getGooglePayPaymentAfterDetails();
      }
    }
  };

  renderLogoDynamic = async () => {
    const { masterDetailsObj } = this.state;
    let path = `${process.env.SERVER_URL}`;
    const logoUrl = getMerchantInformationUrl(masterDetailsObj);
    const logoResponse = await getCall(logoUrl, []);
    if (logoResponse.error) {
      return;
    } else {
      path = `${path}/api/containers/profilepic-${
        masterDetailsObj.codename
      }/download/${logoResponse.profilepic}`;
      return path;
    }
  };

  initUpdatePaymentTransactionId = async () => {
    const { cartList, paymentResponse, paymentMethod } = this.state;
    const updatePaymentTransactionIdUrl = `orders/${this.getOrderId(cartList)}`;
    let transactionNumber = "";
    if (paymentMethod === "RazorPay") {
      transactionNumber = paymentResponse.razorpay_payment_id;
    }
    if (paymentMethod === "PayU") {
      transactionNumber = paymentResponse.encryptedPaymentId;
    }

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: transactionNumber,
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      order_id: paymentMethod === "PayU" ? paymentResponse.txnid : "",
    };
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    if (updatePaymentTransactionId.error) {
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ isLoading: true });
      this.placeAnOrder().then(() => {
        this.setState({ isLoading: false });
      });
    }
  };

  initRazorPayPayment = async () => {
    const {
      isMerchantGSTEnable,
      cartList,
      AppTitle,
      currentCatelogueType,
      paymentDetails,
      masterDetailsObj,
    } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    const logo = this.renderLogoDynamic(masterDetailsObj);
    var paymentAmount = 0;
    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      paymentAmount = this.getGrandTotal(
        this.getTotalofCart(cartList),
        this.getCGSTFromTotal(this.getTotalofCart(cartList)),
        this.getSGSTFromTotal(this.getTotalofCart(cartList)),
        this.getIGSTFromTotal(this.getTotalofCart(cartList)),
        this.getShippingCharges(this.getTotalofCart(cartList))
      );
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      paymentAmount = this.getGrandTotal(
        this.getTotalofCart(cartList),
        this.getCGSTFromTotal(this.getTotalofCart(cartList)),
        this.getSGSTFromTotal(this.getTotalofCart(cartList)),
        this.getIGSTFromTotal(this.getTotalofCart(cartList)),
        this.getShippingCharges(this.getTotalofCart(cartList))
      );
    } else {
      paymentAmount =
        this.getTotalofCart(cartList) +
        this.getShippingCharges(this.getTotalofCart(cartList));
    }

    if (paymentDetails && paymentDetails.length > 0 && paymentDetails[0]) {
      const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
        (paymentMethods) => {
          return paymentMethods.name === "RazorPay";
        }
      );
      const mId = paytmPaymentMethodDetails[0].config.key_id;
      const paymentResponse = await payment({
        key: mId,
        clientName: AppTitle,
        clientLogo: logo,
        amount: paymentAmount,
        mobile: userDetails.cellnumber,
        email: userDetails.email,
        name: userDetails.username,
        currency: "INR",
        orderId: this.getOrderId(cartList),
      });
      this.setState({ paymentResponse }, () => {
        this.initUpdatePaymentTransactionId();
      });
    }
  };

  initPaytmPayment = async () => {
    const { cartList } = this.state;
    const orderId = this.getOrderId(cartList);

    const initPaytmPaymentUrl = "orders/genratepaytmchecksum";
    let initPaytmPaymentObj = {
      orderId: orderId,
      amount: this.getGrandTotal(
        this.getTotalofCart(cartList),
        this.getCGSTFromTotal(this.getTotalofCart(cartList)),
        this.getSGSTFromTotal(this.getTotalofCart(cartList)),
        this.getIGSTFromTotal(this.getTotalofCart(cartList)),
        this.getShippingCharges(this.getTotalofCart(cartList))
      ),
      callbackUrl: window.location.href,
    };
    this.setState({ isPlaceOrderButtonDisable: true }, async () => {
      const initPaytmPaymentResponse = await postCall(
        initPaytmPaymentUrl,
        initPaytmPaymentObj
      );
      if (
        initPaytmPaymentResponse &&
        initPaytmPaymentResponse.body &&
        initPaytmPaymentResponse.body.txnToken
      ) {
        const { cartList, paymentDetails } = this.state;
        this.setState({ isPlaceOrderButtonDisable: false });
        if (paymentDetails && paymentDetails.length > 0 && paymentDetails[0]) {
          const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
            (paymentMethods) => {
              return paymentMethods.name === "Paytm";
            }
          );
          const mId = paytmPaymentMethodDetails[0].config.PaytmMerchantID;
          const logo = this.renderLogoDynamic();
          let obj = {
            mid: "" + mId,
            orderId: orderId,
            amount: this.getGrandTotal(
              this.getTotalofCart(cartList),
              this.getCGSTFromTotal(this.getTotalofCart(cartList)),
              this.getSGSTFromTotal(this.getTotalofCart(cartList)),
              this.getIGSTFromTotal(this.getTotalofCart(cartList)),
              this.getShippingCharges(this.getTotalofCart(cartList))
            ),
            token: initPaytmPaymentResponse.body.txnToken,
            merchantName:
              paytmPaymentMethodDetails[0].config.paymentMerchantName,
            logo: logo,
            callbackUrl: window.location.href,
          };
          const paytmC = this.appendHandler(paytmConfigure(obj));
          this.setState({ paytmConfig: paytmC });
        }
      } else if (
        initPaytmPaymentResponse &&
        initPaytmPaymentResponse.body &&
        initPaytmPaymentResponse.body.resultInfo
      ) {
        this.loadErrorMessageAnimation(
          initPaytmPaymentResponse.body.resultInfo.resultMsg
        );
      }
    });
  };

  initPaypalPayment = () => {
    const { cartList, paymentDetails } = this.state;

    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "Paypal";
      }
    );

    let obj = {
      env: "prduction", // set this to prduction
      currency: "INR",
      total: this.getGrandTotal(
        this.getTotalofCart(cartList),
        this.getCGSTFromTotal(this.getTotalofCart(cartList)),
        this.getSGSTFromTotal(this.getTotalofCart(cartList)),
        this.getIGSTFromTotal(this.getTotalofCart(cartList)),
        this.getShippingCharges(this.getTotalofCart(cartList))
      ),
      client: {
        sandbox: paytmPaymentMethodDetails[0].config.PaypalClientToken,
        production: paytmPaymentMethodDetails[0].config.PaypalClientToken,
      },
    };
    this.setState({ payPalConfig: obj });
  };

  onSuccessOfPaypalPayment = (payment) => {
    // Congratulation, it came here means everything's fine!
    console.log("The payment was succeeded!", payment);
    if (payment.paid) {
      this.loadSuccessMessageAnimation(
        "Payment is successfully completed, Please Place order"
      );
      this.setState({
        paymentResponse: payment,
        isPlaceOrderButtonDisable: false,
        isPaymentDone: true,
      });
    }

    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
  };

  onCancelOfPaypalPayment = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log("The payment was cancelled!", data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  };

  onErrorOfPaypalPayment = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    console.log("Error!", err);
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  };

  makeGooglePayPayment = (payment) => {
    this.loadSuccessMessageAnimation(
      "Payment is successfully completed, Please Place order"
    );
    this.setState({
      paymentResponse: payment,
      isPlaceOrderButtonDisable: false,
      isPaymentDone: true,
    });
  };

  initPayUMoneyPayment = async () => {
    try {
      const { cartList, paymentDetails } = this.state;
      const orderId = this.getOrderId(cartList);

      const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
        (paymentMethods) => {
          return paymentMethods.name === "PayU";
        }
      );
      const key = paytmPaymentMethodDetails[0].config.PayUMerchantKey;

      var pd = {
        key: key,
        txnid: orderId,
        amount: this.getGrandTotal(
          this.getTotalofCart(cartList),
          this.getCGSTFromTotal(this.getTotalofCart(cartList)),
          this.getSGSTFromTotal(this.getTotalofCart(cartList)),
          this.getIGSTFromTotal(this.getTotalofCart(cartList)),
          this.getShippingCharges(this.getTotalofCart(cartList))
        ),
        firstname: this.state.billingName,
        email: this.state.billingEmailAddress,
        phone: this.state.billingMobileNumber,
        productinfo: this.getProductIds(cartList),
        surl: `/${process.env.PUBLIC_URL}/checkout`,
        furl: `/${process.env.PUBLIC_URL}/checkout`,
        hash: "",
      };

      let data = {
        txnId: pd.txnid,
        email: pd.email,
        amount: pd.amount,
        productinfo: pd.productinfo,
        firstname: pd.firstname,
      };

      this.setState({ isLoading: true }, async () => {
        const payUMoneyHash = await postCall(
          "orders/genratepayumoneyhash",
          data
        );
        this.setState({ isLoading: false });
        if (payUMoneyHash && payUMoneyHash.error) {
          this.loadErrorMessageAnimation(payUMoneyHash.error.message);
          this.setState({
            paymentMethod: "COD",
            isPlaceOrderButtonDisable: false,
          });
        } else {
          pd.hash = payUMoneyHash.hash;
          this.setState({ payUMoneyConfig: pd });
        }
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  initGooglePayPayment = () => {
    const { cartList, paymentDetails } = this.state;

    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "GooglePay";
      }
    );

    let obj = {
      merchantId: paytmPaymentMethodDetails[0].config.MERCHANT_ID,
      merchantName: paytmPaymentMethodDetails[0].config.MERCHANT_NAME,
      amount: this.getGrandTotal(
        this.getTotalofCart(cartList),
        this.getCGSTFromTotal(this.getTotalofCart(cartList)),
        this.getSGSTFromTotal(this.getTotalofCart(cartList)),
        this.getIGSTFromTotal(this.getTotalofCart(cartList)),
        this.getShippingCharges(this.getTotalofCart(cartList))
      ),
    };

    const gPayConfig = GooglePayConfigure(obj);
    this.setState({ googlePayConfig: gPayConfig });
  };

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharge) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharge;
    } else {
      grandTotal = total + igst + shippingCharge;
    }
    return grandTotal;
  };

  getShippingCharges = (total) => {
    const { shippingCharges, isPincodeDeliveryEnable } = this.state;
    var charge = 0;
    if (isPincodeDeliveryEnable) {
      return this.getShippingPriceFromCart();
    }
    if (shippingCharges.length > 0) {
      for (let index = 0; index < shippingCharges.length; index++) {
        if (
          shippingCharges[index].id === 3 &&
          shippingCharges[index].status === 1
        ) {
          for (
            let jindex = 0;
            jindex < shippingCharges[index].options.length;
            jindex++
          ) {
            if (
              !shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total &&
              shippingCharges[index].options[jindex].maxValue >= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
            if (
              shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
          }
        }
      }
    }
    return charge;
  };
  placeAnInquiry = async () => {
    const placeAninquiryUrl = "orders/placeOrder";
    var placeAnInquiryObj = {};
    const { cartList } = this.state;
    const user = loadUserDetailsFromLocalStorage();
    if (user === undefined) {
      localStorage.removeItem("userDetails");
      localStorage.removeItem("apiToken");
      this.props.history.push(`/login`);
      return;
    }
    const { id } = user;
    placeAnInquiryObj = {
      orderId: this.getOrderId(cartList),
      userId: id,
      inshoppingcart: 2,
    };
    const placeInquiry = await patchCall(placeAninquiryUrl, placeAnInquiryObj);
    if (placeInquiry.error) {
      this.loadErrorMessageAnimation(placeInquiry.error.message);
      if (placeInquiry.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_your_inquiry_placed_successfully")
          ? getValueFromLanguageObject("str_your_inquiry_placed_successfully")
          : "Your inquiry Placed Successfully!"
      );
      setTimeout(() => {
        this.props.history.push(`/orderdetails/${this.getOrderId(cartList)}`);
      }, 750);
    }
  };

  placeAnOrder = async () => {
    const { isInquiry } = this.props;
    const { isMerchantGSTEnable, merchantInfo, userDetails } = this.state;
    const shippingAddress = JSON.parse(userDetails.shippingaddress);
    const billingAddress = JSON.parse(userDetails.billingaddress);

    const user = loadUserDetailsFromLocalStorage();
    if (user === undefined) {
      localStorage.removeItem("userDetails");
      localStorage.removeItem("apiToken");
      this.props.history.push(`/login`);
      return;
    }
    const { id } = user;
    const { cartList, currentCatelogueType } = this.state;
    const placeOrderUrl = "orders/placeOrder";
    var placeOrderObj = {};

    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      placeOrderObj = {
        orderId: this.getOrderId(cartList),
        totalamount: this.getGrandTotal(
          this.getTotalofCart(cartList),
          this.getCGSTFromTotal(this.getTotalofCart(cartList)),
          this.getSGSTFromTotal(this.getTotalofCart(cartList)),
          this.getIGSTFromTotal(this.getTotalofCart(cartList)),
          this.getShippingCharges(this.getTotalofCart(cartList))
        ),
        userId: id,
        inshoppingcart: 0,
        description: this.state.orderNotes,
        shippingaddress: shippingAddress,
        billingaddress: billingAddress,
        tax: {
          cgst: merchantInfo.CGST,
          sgst: merchantInfo.SGST,
          cgstPrice: this.getCGSTFromTotal(this.getTotalofCart(cartList)),
          sgstPrice: this.getSGSTFromTotal(this.getTotalofCart(cartList)),
        },
        baseprice: this.getTotalofCart(cartList),
        shippingprice: this.getShippingCharges(this.getTotalofCart(cartList)),
      };
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      placeOrderObj = {
        orderId: this.getOrderId(cartList),
        totalamount: this.getGrandTotal(
          this.getTotalofCart(cartList),
          this.getCGSTFromTotal(this.getTotalofCart(cartList)),
          this.getSGSTFromTotal(this.getTotalofCart(cartList)),
          this.getIGSTFromTotal(this.getTotalofCart(cartList)),
          this.getShippingCharges(this.getTotalofCart(cartList))
        ),
        userId: id,
        inshoppingcart: 0,
        description: this.state.orderNotes,
        shippingaddress: shippingAddress,
        billingaddress: billingAddress,
        baseprice: this.getTotalofCart(cartList),
        shippingprice: this.getShippingCharges(this.getTotalofCart(cartList)),
      };
    } else {
      placeOrderObj = {
        orderId: this.getOrderId(cartList),
        totalamount:
          this.getTotalofCart(cartList) +
          this.getShippingCharges(this.getTotalofCart(cartList)),
        userId: id,
        inshoppingcart: 0,
        description: this.state.orderNotes,
        shippingaddress: shippingAddress,
        billingaddress: billingAddress,
        shippingprice: this.getShippingCharges(this.getTotalofCart(cartList)),
      };
    }

    const placeOrder = await patchCall(placeOrderUrl, placeOrderObj);
    if (placeOrder.error) {
      this.loadErrorMessageAnimation(placeOrder.error.message);
      if (placeOrder.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("str_your_inquiry_placed_successfully")
            ? getValueFromLanguageObject("str_your_inquiry_placed_successfully")
            : "Your inquiry Placed Successfully!"
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("str_your_order_placed_successfully")
            ? getValueFromLanguageObject("str_your_order_placed_successfully")
            : "Your Order Placed Successfully!"
        );
      }

      setTimeout(() => {
        this.props.history.push(`/orderdetails/${this.getOrderId(cartList)}`);
      }, 750);
    }
  };

  getTotalofCart(cartList) {
    let total = 0;
    if (cartList.length > 0)
      for (let i = 0; i < cartList[0].orderdetail.length; i++) {
        total =
          total +
          cartList[0].orderdetail[i].amount *
            cartList[0].orderdetail[i].quantity;
      }
    return total;
  }

  getShippingPriceFromCart() {
    const { cartList } = this.state;
    let total = 0;
    if (cartList.length > 0)
      for (let i = 0; i < cartList.length; i++) {
        total = cartList[i].shippingprice;
      }
    return total;
  }

  getProductIds = (cartList) => {
    let productIds = "";
    if (cartList.length > 0)
      for (let i = 0; i < cartList[0].orderdetail.length; i++) {
        productIds = productIds.concat(
          `, ${cartList[0].orderdetail[i].productId}`
        );
      }
    return productIds;
  };

  getOrderId(cartList) {
    let orderId = "";
    if (cartList.length > 0) {
      orderId = cartList[0].id;
    }

    return orderId;
  }

  onChangeOfPaymentMethod = (paymentMethod) => {
    if (paymentMethod === "COD") {
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: false });
    }
    if (paymentMethod === "Paytm") {
      this.initPaytmPayment();
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: false });
    }
    if (paymentMethod === "RazorPay") {
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: false });
    }

    if (paymentMethod === "PayU") {
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: false }, () => {
        this.initPayUMoneyPayment();
      });
    }
    if (paymentMethod === "GooglePay") {
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: true }, () => {
        this.initGooglePayPayment();
      });
    }
    if (paymentMethod === "Paypal") {
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: true }, () => {
        this.initPaypalPayment();
      });
    }
  };

  appendHandler(config) {
    const newConfig = { ...config };

    newConfig.handler = {
      notifyMerchant: this.notifyMerchantHandler,
    };

    return newConfig;
  }

  notifyMerchantHandler = (eventType, data) => {
    console.log("MERCHANT NOTIFY LOG", data);
    if (
      data &&
      data.body &&
      data.body.content &&
      data.body.content.STATUS === "TXN_FAILURE"
    ) {
      this.loadErrorMessageAnimation(data.body.content.RESPMSG);
    }
    if (
      data &&
      data.body &&
      data.body.resultInfo &&
      data.body.resultInfo.resultStatus === "F" &&
      data.body.resultInfo.resultCode !== "1006"
    ) {
      this.loadErrorMessageAnimation(data.body.resultInfo.resultMsg);
    }
  };

  loadCheckoutScript = () => {
    const { paymentDetails } = this.state;

    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "Paytm";
      }
    );
    const mId = paytmPaymentMethodDetails[0].config.PaytmMerchantID;

    // Development URL
    // const url =
    //   "https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/";

    // Production URL
    const url = "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/";

    const scriptElement = document.createElement("script");
    scriptElement.async = true;
    scriptElement.src = url.concat(mId);
    scriptElement.type = "application/javascript";
    scriptElement.onload = () => {
      const checkoutJsInstance = this.getCheckoutJsObj();

      if (checkoutJsInstance && checkoutJsInstance.onLoad) {
        checkoutJsInstance.onLoad(() => {
          this.setState({
            checkoutJsInstance,
          });
        });
      } else {
        console.error(SSR_SIMULATE + "onload not available!");
      }
    };
    scriptElement.onerror = (error) => {
      console.error("SSR Simulate: script load fail!");
    };
    document.body.appendChild(scriptElement);
    this.setState({ isScriptLoading: true });
  };

  loadPayUMoneyBoltCheckout = () => {
    const { payUMoneyConfig } = this.state;

    window.bolt.launch(payUMoneyConfig, {
      responseHandler: (response) => {
        // your payment response Code goes here
        if (response.response.status === "success") {
          this.setState(
            {
              paymentResponse: response,
              isPlaceOrderButtonDisable: false,
              isPaymentDone: true,
            },
            async () => {
              this.initUpdatePaymentTransactionId();
            }
          );
        } else if (response.response.txnStatus === "CANCEL") {
          this.loadErrorMessageAnimation(response.response.txnMessage);
          return;
        }
      },
      catchException: (error) => {
        console.log("error");
        console.log(error);
        // the code you use to handle the integration errors goes here
        // Make any UI changes to convey the error to the user
      },
    });
  };

  getCheckoutJsObj() {
    if (window && window.Paytm && window.Paytm.CheckoutJS) {
      return window.Paytm.CheckoutJS;
    } else {
      console.error(SSR_SIMULATE + "Checkout instance not found!");
    }

    return null;
  }

  rednerBillingAndShippingAddress = (billingaddressObj, shippingaddressObj) => {
    return (
      <>
        <div className="row">
          <div className="checkout-title col-md-12 mb-0">
            <h5
              style={{
                color: getColorCodeBasedOnKey("text_billing_details_checkout"),
              }}
            >
              {getValueFromLanguageObject(
                "str_your_order_delivered_to_below_address"
              )
                ? getValueFromLanguageObject(
                    "str_your_order_delivered_to_below_address"
                  )
                : "Your Order delivered to below address"}

              <i
                className="fa fa-pencil ml-2"
                onClick={() => {
                  this.setState({
                    isEditBillingDetailModalVisible: true,
                  });
                }}
              />
            </h5>
          </div>
        </div>
        <div className="checkout-box">
          <div className="check-out">
            <div className="checkout-title col-md-12">
              <h3>
                {getValueFromLanguageObject("billing_details")
                  ? getValueFromLanguageObject("billing_details")
                  : "Billing Details"}
              </h3>
            </div>
            <div className="col-12 f22 mt-2">
              <ul
                className="order-detail"
                style={{
                  color: getColorCodeBasedOnKey(
                    "text_shipping_address_order_detail"
                  ),
                }}
              >
                <li className="mb-2 d-block" style={{ fontWeight: 550 }}>
                  {billingaddressObj.name} - {billingaddressObj.mobile}
                </li>

                {billingaddressObj.email && (
                  <li className="mb-2 d-block">
                    Email Address : {billingaddressObj.email}
                  </li>
                )}
                {billingaddressObj.companyname && (
                  <li className="mb-2 d-block">
                    {" "}
                    Company Name : {billingaddressObj.companyname}
                  </li>
                )}
                {billingaddressObj.gstin && (
                  <li className="mb-2 d-block">
                    {" "}
                    GSTIN : {billingaddressObj.gstin}
                  </li>
                )}

                {billingaddressObj.address1 && (
                  <li className="mb-2 d-block">
                    Address : {billingaddressObj.address1},
                    {billingaddressObj.address2},{billingaddressObj.landmark},
                  </li>
                )}
                <li className="mb-2 d-block">
                  {billingaddressObj.cityname}, {billingaddressObj.statename},{" "}
                  {billingaddressObj.countryname} - {billingaddressObj.zipcode}
                </li>
              </ul>{" "}
            </div>
          </div>
        </div>
        <div className="checkout-box">
          <div className="check-out">
            <div className="checkout-title col-md-12">
              <h3
                style={{
                  color: getColorCodeBasedOnKey(
                    "text_billing_details_checkout"
                  ),
                }}
              >
                {getValueFromLanguageObject("shipping_details")
                  ? getValueFromLanguageObject("shipping_details")
                  : "Shipping Details"}
              </h3>
            </div>
            <div className="col-12 f22 mt-2">
              <ul
                className="order-detail"
                style={{
                  color: getColorCodeBasedOnKey(
                    "text_shipping_address_order_detail"
                  ),
                }}
              >
                <li className="mb-2 d-block" style={{ fontWeight: 600 }}>
                  {shippingaddressObj.name} - {shippingaddressObj.mobile}
                </li>

                {shippingaddressObj.email && (
                  <li className="mb-2 d-block">
                    Email Address : {shippingaddressObj.email}
                  </li>
                )}
                {shippingaddressObj.companyname && (
                  <li className="mb-2 d-block">
                    {" "}
                    Company Name : {shippingaddressObj.companyname}
                  </li>
                )}
                {shippingaddressObj.gstin && (
                  <li className="mb-2 d-block">
                    {" "}
                    GSTIN : {shippingaddressObj.gstin}
                  </li>
                )}

                {shippingaddressObj.address1 && (
                  <li className="mb-2 d-block">
                    Address : {shippingaddressObj.address1},
                    {shippingaddressObj.address2},{shippingaddressObj.landmark},
                  </li>
                )}
                <li className="mb-2 d-block">
                  {shippingaddressObj.cityname}, {shippingaddressObj.statename},{" "}
                  {shippingaddressObj.countryname} -{" "}
                  {shippingaddressObj.zipcode}
                </li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </>
    );
  };

  renderNoBillingAddress = () => {
    const { isInquiry } = this.props;
    if (isInquiry) {
      return <div />;
    }
    return (
      <div>
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  {!isInquiry && (
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/icon/address.png`}
                        className="img-fluid mb-4"
                        alt=""
                      />
                      <div>
                        <h3>
                          <strong>
                            {getValueFromLanguageObject("str_no_address_found")
                              ? getValueFromLanguageObject(
                                  "str_no_address_found"
                                )
                              : "No Billing/Shipping Address Found in your account."}
                          </strong>
                        </h3>
                        <h4>
                          {getValueFromLanguageObject(
                            "str_add_billing_addresss"
                          )
                            ? getValueFromLanguageObject(
                                "str_add_billing_addresss"
                              )
                            : "Add Billing/Shipping Address."}
                        </h4>
                      </div>

                      <button
                        type="button"
                        className="btn-solid btn mobile-btn-block mt-2"
                        onClick={() => {
                          this.setState({
                            isAddBillingDetailModalVisible: true,
                          });
                        }}
                      >
                        {getValueFromLanguageObject("str_add_address")
                          ? getValueFromLanguageObject("str_add_address")
                          : "Add Address"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const { symbol, isInquiry } = this.props;
    const {
      cartList,
      // countryList,
      // stateList,
      // cityList,
      // shippingCountryList,
      // shippingStateList,
      // shippingCityList,
      isLoading,
      isMerchantGSTEnable,
      paymentDetails,
      paymentMethod,
      isPaymentDone,
      orderViaWhatsapp,
      AppTitle,
      currentCatelogueType,
      userDetails,
      isAddBillingDetailModalVisible,
      isEditBillingDetailModalVisible,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
    } = this.state;

    const { billingaddress, shippingaddress } = userDetails;
    const billingaddressObj = billingaddress
      ? JSON.parse(billingaddress)
      : null;
    const shippingaddressObj = shippingaddress
      ? JSON.parse(shippingaddress)
      : null;

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>
            {AppTitle} |{" "}
            {getValueFromLanguageObject("str_checkout_page")
              ? getValueFromLanguageObject("str_checkout_page")
              : "CheckOut Page"}
          </title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={"Checkout"} />

        {isAddBillingDetailModalVisible && (
          <AddBillingAddressModal
            isAddBillingDetailModalVisible={isAddBillingDetailModalVisible}
            onCloseModalAddBillingDetail={() => {
              this.setState({ isAddBillingDetailModalVisible: false }, () => {
                this.initUserDetails();
              });
            }}
          />
        )}

        {isEditBillingDetailModalVisible && (
          <EditBillingAddressModal
            isEditBillingDetailModalVisible={isEditBillingDetailModalVisible}
            onCloseModalEditBillingDetail={() => {
              this.setState({ isEditBillingDetailModalVisible: false }, () => {
                this.initUserDetails();
              });
            }}
          />
        )}

        <section className="section-b-space">
          <div className="container">
            <div className="checkout-page">
              <div className="checkout-form">
                <form>
                  <div className="checkout row">
                    {!isInquiry && (
                      <div className="col-lg-6 col-sm-12 col-xs-12">
                        <div className="row">
                          <div className="checkout-title col-md-12 mb-0">
                            <h5>
                              {getValueFromLanguageObject("str_order_notes")
                                ? getValueFromLanguageObject("str_order_notes")
                                : "Order Notes"}
                            </h5>
                          </div>
                          <div className="form-group col-md-12 col-sm-6 col-xs-12">
                            <input
                              type="text"
                              name="orderNotes"
                              value={this.state.orderNotes}
                              onChange={this.onChangeOfOrderNotes}
                              placeholder={
                                getValueFromLanguageObject(
                                  "str_enter_order_notes"
                                )
                                  ? getValueFromLanguageObject(
                                      "str_enter_order_notes"
                                    )
                                  : "Enter Order Notes"
                              }
                            />
                            {/* {this.validator.message(
                            "orderNotes",
                            this.state.orderNotes,
                            ""
                          )} */}
                          </div>
                        </div>

                        {billingaddressObj &&
                          shippingaddressObj &&
                          this.rednerBillingAndShippingAddress(
                            billingaddressObj,
                            shippingaddressObj
                          )}

                        {!billingaddressObj &&
                          !shippingaddressObj &&
                          this.renderNoBillingAddress()}
                      </div>
                    )}

                    {isInquiry && (
                      <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="checkout-details">
                          <div className="order-box">
                            <div className="title-box">
                              <div
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_product_checkout"
                                  ),
                                }}
                              >
                                {getValueFromLanguageObject("str_product")
                                  ? getValueFromLanguageObject("str_product")
                                  : "Product"}
                              </div>
                            </div>
                            <ul className="qty">
                              {cartList.length > 0 &&
                                cartList[0].orderdetail.map((d, index) => {
                                  return (
                                    <li
                                      key={index}
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_product_name_checkout"
                                        ),
                                      }}
                                    >
                                      {`${d.products.name} (Qty : ${
                                        d.quantity
                                      })`}
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="text-right">
                            {orderViaWhatsapp && orderViaWhatsapp.status === 1 && (
                              <button
                                type="button"
                                className="btn-solid-wp btn mobile-btn-block"
                                onClick={() => this.onClickOfOrderViaWhatsApp()}
                              >
                                {getValueFromLanguageObject(
                                  "str_inquiry_via_whatsapp"
                                )
                                  ? getValueFromLanguageObject(
                                      "str_inquiry_via_whatsapp"
                                    )
                                  : "Inquiry Via WhatsApp"}
                              </button>
                            )}
                            <button
                              type="button"
                              className="btn-solid btn mobile-btn-block"
                              onClick={() => this.placeAnInquiry()}
                            >
                              {getValueFromLanguageObject("str_place_inquiry")
                                ? getValueFromLanguageObject(
                                    "str_place_inquiry"
                                  )
                                : "Place Inquiry"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {!isInquiry && (
                      <div className="col-lg-6 col-sm-12 col-xs-12">
                        {/* checkout details */}

                        <div className="checkout-details">
                          <div className="order-box">
                            <div className="title-box">
                              <div
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_product_checkout"
                                  ),
                                }}
                              >
                                {getValueFromLanguageObject("str_product")
                                  ? getValueFromLanguageObject("str_product")
                                  : "Product"}{" "}
                                <span
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_total_checkout"
                                    ),
                                  }}
                                >
                                  {" "}
                                  {getValueFromLanguageObject("str_total")
                                    ? getValueFromLanguageObject("str_total")
                                    : "Total"}
                                </span>
                              </div>
                            </div>
                            <ul className="qty">
                              {cartList.length > 0 &&
                                cartList[0].orderdetail.map((d, index) => {
                                  return (
                                    <li
                                      key={index}
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_product_name_checkout"
                                        ),
                                      }}
                                    >
                                      {`${
                                        d.products.name
                                      } (${symbol} ${parseFloat(
                                        d.amount
                                      ).toFixed(2)} × ${d.quantity}) `}
                                      <span>
                                        {symbol}{" "}
                                        {parseFloat(
                                          d.quantity * d.amount
                                        ).toFixed(2)}
                                      </span>
                                    </li>
                                  );
                                })}
                            </ul>
                            <ul className="sub-total">
                              <li
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_total_label_chekout"
                                  ),
                                }}
                              >
                                {getValueFromLanguageObject("str_total")
                                  ? getValueFromLanguageObject("str_total")
                                  : "Total"}
                                <span
                                  className="count"
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_total_checkout"
                                    ),
                                  }}
                                >
                                  {symbol}{" "}
                                  {parseFloat(
                                    this.getTotalofCart(cartList)
                                  ).toFixed(2)}
                                </span>
                              </li>

                              <li
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_total_label_chekout"
                                  ),
                                }}
                              >
                                {"+ "}
                                {getValueFromLanguageObject(
                                  "str_shipping_charges"
                                )
                                  ? getValueFromLanguageObject(
                                      "str_shipping_charges"
                                    )
                                  : "Shipping Charges"}
                                <span
                                  className="count"
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_total_checkout"
                                    ),
                                  }}
                                >
                                  {symbol}{" "}
                                  {parseFloat(
                                    this.getShippingCharges(
                                      this.getTotalofCart(cartList)
                                    ).toFixed(2)
                                  )}
                                </span>
                              </li>
                              {isMerchantGSTEnable &&
                                currentCatelogueType &&
                                currentCatelogueType.registerallow !==
                                  "Catalogue_Jewellary" &&
                                this.state.merchantInfo.countryname ===
                                  "India" && (
                                  <li
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_total_label_chekout"
                                      ),
                                    }}
                                  >
                                    {"+ "}
                                    {getValueFromLanguageObject("str_cgst")
                                      ? getValueFromLanguageObject("str_cgst")
                                      : "CGST"}
                                    {"("}
                                    {this.state.merchantInfo.CGST}
                                    {"%)"}
                                    <span
                                      className="count"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_total_checkout"
                                        ),
                                      }}
                                    >
                                      {symbol}{" "}
                                      {parseFloat(
                                        this.getCGSTFromTotal(
                                          this.getTotalofCart(cartList)
                                        )
                                      ).toFixed(2)}
                                    </span>
                                  </li>
                                )}
                              {isMerchantGSTEnable &&
                                currentCatelogueType &&
                                currentCatelogueType.registerallow !==
                                  "Catalogue_Jewellary" &&
                                this.state.merchantInfo.countryname ===
                                  "India" && (
                                  <li
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_total_label_chekout"
                                      ),
                                    }}
                                  >
                                    {"+ "}{" "}
                                    {getValueFromLanguageObject("str_sgst")
                                      ? getValueFromLanguageObject("str_sgst")
                                      : "SGST"}{" "}
                                    {"("}
                                    {this.state.merchantInfo.SGST}
                                    {"%)"}
                                    <span
                                      className="count"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_total_checkout"
                                        ),
                                      }}
                                    >
                                      {symbol}{" "}
                                      {parseFloat(
                                        this.getSGSTFromTotal(
                                          this.getTotalofCart(cartList)
                                        )
                                      ).toFixed(2)}
                                    </span>
                                  </li>
                                )}

                              {isMerchantGSTEnable &&
                                currentCatelogueType &&
                                currentCatelogueType.registerallow !==
                                  "Catalogue_Jewellary" &&
                                this.state.merchantInfo.countryname !==
                                  "India" && (
                                  <li
                                    style={{
                                      color: getColorCodeBasedOnKey(
                                        "text_total_label_chekout"
                                      ),
                                    }}
                                  >
                                    {"+ "}
                                    {getValueFromLanguageObject("str_igst")
                                      ? getValueFromLanguageObject("str_igst")
                                      : "IGST"}
                                    {"("}
                                    {this.state.merchantInfo.IGST}
                                    {"%)"}
                                    <span
                                      className="count"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_total_checkout"
                                        ),
                                      }}
                                    >
                                      {symbol}{" "}
                                      {parseFloat(
                                        this.getIGSTFromTotal(
                                          this.getTotalofCart(cartList)
                                        )
                                      ).toFixed(2)}
                                    </span>
                                  </li>
                                )}

                              <li
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_total_label_chekout"
                                  ),
                                }}
                              >
                                {"- "}
                                {getValueFromLanguageObject("str_discount")
                                  ? getValueFromLanguageObject("str_discount")
                                  : "Discount"}
                                <span
                                  className="count"
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_total_checkout"
                                    ),
                                  }}
                                >
                                  {symbol}
                                  {parseFloat(0).toFixed(2)}
                                </span>
                              </li>
                            </ul>
                            <ul className="total">
                              <li
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_total_label_chekout"
                                  ),
                                }}
                              >
                                {getValueFromLanguageObject(
                                  "str_amount_payable"
                                )
                                  ? getValueFromLanguageObject(
                                      "str_amount_payable"
                                    )
                                  : "Amount Payable"}
                                <span
                                  className="count"
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_total_checkout"
                                    ),
                                  }}
                                >
                                  {symbol}{" "}
                                  {parseFloat(
                                    this.getGrandTotal(
                                      this.getTotalofCart(cartList),
                                      this.getCGSTFromTotal(
                                        this.getTotalofCart(cartList)
                                      ),
                                      this.getSGSTFromTotal(
                                        this.getTotalofCart(cartList)
                                      ),
                                      this.getIGSTFromTotal(
                                        this.getTotalofCart(cartList)
                                      ),
                                      this.getShippingCharges(
                                        this.getTotalofCart(cartList)
                                      )
                                    )
                                  ).toFixed(2)}
                                </span>
                              </li>
                            </ul>
                          </div>

                          {billingaddressObj && shippingaddressObj && (
                            <div className="payment-box">
                              <div className="upper-box">
                                {paymentDetails &&
                                  paymentDetails.length > 0 &&
                                  paymentDetails[0].status === 1 && (
                                    <div className="payment-options">
                                      <h3>
                                        <b>Make your payment via :</b>{" "}
                                      </h3>
                                      <br />
                                      <ul>
                                        <li>
                                          <div className="radio-option stripe">
                                            <input
                                              type="radio"
                                              name="payment-group"
                                              id="COD"
                                              onClick={() =>
                                                this.onChangeOfPaymentMethod(
                                                  "COD"
                                                )
                                              }
                                              checked={
                                                this.state.paymentMethod ===
                                                "COD"
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label htmlFor="COD">
                                              Cash On Delivery(COD)
                                            </label>
                                          </div>
                                        </li>

                                        {paymentDetails &&
                                          paymentDetails.length > 0 &&
                                          paymentDetails[0].status === 1 &&
                                          paymentDetails[0].text &&
                                          paymentDetails[0].text.length > 0 &&
                                          paymentDetails[0].text.map(
                                            (paymentOption) => {
                                              return paymentOption.status ===
                                                1 ? (
                                                <li>
                                                  <div className="radio-option">
                                                    <input
                                                      type="radio"
                                                      name="payment-group"
                                                      id={paymentOption.name}
                                                      onClick={() => {
                                                        this.onChangeOfPaymentMethod(
                                                          paymentOption.name
                                                        );
                                                      }}
                                                      checked={
                                                        this.state
                                                          .paymentMethod ===
                                                        paymentOption.name
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={
                                                        paymentOption.name
                                                      }
                                                    >
                                                      {paymentOption.name}
                                                      <span className="image" />
                                                    </label>
                                                  </div>
                                                </li>
                                              ) : (
                                                ""
                                              );
                                            }
                                          )}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                              <div className="mb-2 text-right">
                                {paymentMethod === "Paypal" &&
                                  !isPaymentDone &&
                                  this.state.payPalConfig &&
                                  this.state.payPalConfig.env && (
                                    <PaypalExpressBtn
                                      env={this.state.payPalConfig.env}
                                      client={this.state.payPalConfig.client}
                                      currency={
                                        this.state.payPalConfig.currency
                                      }
                                      total={this.state.payPalConfig.total}
                                      onError={this.onErrorOfPaypalPayment}
                                      onSuccess={this.onSuccessOfPaypalPayment}
                                      onCancel={this.onCancelOfPaypalPayment}
                                      shipping={1}
                                      style={{
                                        color: "gold",
                                        shape: "rect",
                                        size: "large",
                                        label: "pay",
                                      }}
                                    />
                                  )}

                                {paymentMethod === "GooglePay" &&
                                  !isPaymentDone && (
                                    <GooglePayButton
                                      environment="PRODUCTION"
                                      buttonType="long"
                                      style={{ marginTop: "10px" }}
                                      paymentRequest={
                                        this.state.googlePayConfig
                                      }
                                      onLoadPaymentData={
                                        this.makeGooglePayPayment
                                      }
                                      onCancel={(cancel) => {
                                        console.log("cancel");
                                        console.log(cancel);
                                      }}
                                      onError={(error) => {
                                        console.log("error");
                                        console.log(error);
                                      }}
                                      onReadyToPayChange={(change) => {
                                        console.log("change");
                                        console.log(change);
                                      }}
                                      // onPaymentAuthorized={(auth) => {
                                      //   console.log("auth");
                                      //   console.log(auth);
                                      // }}
                                      // onPaymentDataChanged={(dataChange) => {
                                      //   console.log("dataChange");
                                      //   console.log(dataChange);
                                      // }}
                                    />
                                  )}
                              </div>
                              <div className="text-right">
                                {orderViaWhatsapp &&
                                  orderViaWhatsapp.status === 1 && (
                                    <button
                                      type="button"
                                      className="btn-solid-wp btn mobile-btn-block"
                                      onClick={() =>
                                        this.onClickOfOrderViaWhatsApp()
                                      }
                                    >
                                      {getValueFromLanguageObject(
                                        "str_order_via_whatsapp"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_order_via_whatsapp"
                                          )
                                        : "Order Via WhatsApp"}
                                    </button>
                                  )}
                                <button
                                  type="button"
                                  disabled={
                                    this.state.isPlaceOrderButtonDisable
                                  }
                                  className="btn-solid btn mobile-btn-block"
                                  onClick={() => this.onClickOfPlaceOrder()}
                                  style={{
                                    color: getColorCodeBasedOnKey(
                                      "text_place_order_chekout"
                                    ),
                                    backgroundImage: `linear-gradient(30deg, ${getColorCodeBasedOnKey(
                                      "bg_place_order_chekout"
                                    )} 50%, transparent 50%)`,
                                  }}
                                >
                                  {getValueFromLanguageObject("str_place_order")
                                    ? getValueFromLanguageObject(
                                        "str_place_order"
                                      )
                                    : "Place Order"}
                                </button>
                                {/* )} */}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          {this.state.paytmConfig &&
            this.state.paytmConfig.merchant &&
            this.state.paytmConfig.merchant.mid && (
              <div>
                <CheckoutProvider
                  config={this.state.paytmConfig}
                  checkoutJsInstance={this.state.checkoutJsInstance}
                  openInPopup={this.state.openInPopup}
                >
                  <Checkout />
                </CheckoutProvider>
              </div>
            )}
        </section>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    notificationCounter: getNotificationCounter,
    isInquiryOrCart: isInquiryOrCart,
  }
)(checkOut);
