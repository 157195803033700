import React, { Component } from "react";
import { IntlActions } from "react-redux-multilingual";
import Pace from "react-pace-progress";

// Import custom components
import store, { loadUserDetailsFromLocalStorage } from "../../../store";
import NavBar from "../navbar/navbar";
import CartContainer from "../../../containers/CartContainer";
import TopBar from "../topbar/topbar";
import { changeCurrency,isInquiryOrCart } from "../../../actions";
import { connect } from "react-redux";
import LogoImage from "./logo";
import { withRouter } from "react-router-dom";

class MainHeader extends Component {
  state = { isDrawerVisible: false, userId: "" };
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }
  /*=====================
			   Pre loader
			   ==========================*/
  componentDidMount() {
    // setTimeout(function () {
    // 	document.querySelector(".loader-wrapper").style = "display: none";
    // }, 2000);

    this.setState({ open: true });
    // getNotificationCounter().then((counter) => {
    // 	this.setState({ notificationCounter: counter });
    // });
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails !== undefined) {
      this.setState({ userId: userDetails.id });
    }
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 800) {
      if (window.innerWidth < 576) {
        document.getElementById("sticky").classList.remove("fixed");
      } else document.getElementById("sticky").classList.add("fixed");
    } else {
      document.getElementById("sticky").classList.remove("fixed");
    }
  };

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  }
  // openSearch() {
  //     document.getElementById("search-overlay").style.display = "block";
  // }

  // closeSearch() {
  //     document.getElementById("search-overlay").style.display = "none";
  // }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  render() {
    const {
      notificationCounter,
      handleNotificicationDrawer,
      onClickOnSettingsIcon,
      isInquiry
    } = this.props;
    const { userId } = this.state;
    return (
      <div>
        <header id="sticky" className="sticky">
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option" />
          {/*Top Header Component*/}
          <TopBar />

          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu login-mobile">
                  <div className="menu-left">
                    {/* {userId && (
											<div className="navbar">
												<a href="javascript:void(0)" onClick={this.openNav}>
													<div className="bar-style">
														{" "}
														<i
															className="fa fa-bars sidebar-bar"
															aria-hidden="true"
														/>
													</div>
												</a>
											</div>
										)} */}

                    <div className="brand-logo">
                      <LogoImage />
                    </div>
                  </div>
                  {userId && (
                    <div className="menu-right pull-right">
                      {/*Top Navigation Bar Component*/}
                      <NavBar />

                      <div>
                        <div className="icon-nav">
                          <ul>
                            <li
                              className="onhover-div mobile-notification"
                              onClick={() => {
                                handleNotificicationDrawer();
                              }}
                            >
                              <div>
                                <img
                                  src={`${
                                    process.env.PUBLIC_URL
                                  }/assets/images/icon/bell.png`}
                                  className="img-fluid"
                                  alt=""
                                />
                                <i class="fa fa-bell" aria-hidden="true" />
                              </div>
                              <div className="cart-qty-cls">
                                {notificationCounter}
                              </div>
                            </li>
                            {!isInquiry &&
                            <li className="onhover-div mobile-setting">
                              <div
                                onClick={() => {
                                  onClickOnSettingsIcon();
                                }}
                              >
                                <img
                                  src={`${
                                    process.env.PUBLIC_URL
                                  }/assets/images/icon/setting.png`}
                                  className="img-fluid"
                                  alt=""
                                />
                                <i className="fa fa-cog" />
                              </div>
                            </li>}

                            <CartContainer />
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="search-overlay" className="search-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeSearch}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Search a Product"
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notificationCounter: state.cartCounter.notificationCounter,
  isInquiry: state.settings.isInquiry
});
export default connect(
  mapStateToProps,
  { changeCurrency ,
    isInquiryOrCart: isInquiryOrCart}
)(withRouter(MainHeader));
