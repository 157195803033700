import React, { Component, useRef, useState } from "react";

import Breadcrumb from "../../common/breadcrumb";
import { postCall, getCall } from "../../../api/post";
import {
  saveUserDetailsToLocalStorage,
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store/index";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { connect } from "react-redux";
import {
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import { Helmet } from "react-helmet";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import { isUserAllowedToRegister } from "../../../actions";

class Login extends Component {
  state = {
    cellnumber: "",
    otp: "",
    isLoginButtonClicked: false,
    userId: "",
    remainingTime: 60,
    isLoading: true,
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
  };

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isUserAllowedToRegister(masterDetailsObj);
      const userDetails = loadUserDetailsFromLocalStorage();
      if (userDetails !== undefined) {
        this.props.history.push("/");
        return;
      }

      this.initMerchantInfo().then(() => {
        this.setState({
          isLoading: false,
        });
      });
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.setState({
        AppTitle: "",
      });
    } else {
      this.setState({
        AppTitle: merchantInfo.companyname,
      });
    }
  };

  onClickOfMobileLogin = () => {
    if (this.state.cellnumber.trim().length !== 10) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("msg_enter_mobile_number_incorrect")
          ? getValueFromLanguageObject("msg_enter_mobile_number_incorrect")
          : "Please enter correct mobile number"
      );
    } else {
      this.postLoginApi();
    }
  };

  postLoginApi = async () => {
    const { masterDetailsObj } = this.state;
    if (!masterDetailsObj.id) {
      return;
    }
    const req = {
      cellnumber: this.state.cellnumber,
      devicetoken: "",
      masterdetailId: masterDetailsObj.id,
    };

    this.setState({ isLoading: true }, async () => {
      const login = await postCall("users/login", req);
      this.setState({ isLoading: false });
      if (login.error) {
        this.loadErrorMessageAnimation(login.error.message);
        this.setState({ isLoginButtonClicked: false });
      } else {
        if (login && login.userId != null) {
          localStorage.setItem("apiToken", login.apitoken);
          this.setState(
            {
              userId: login.userId,
              apiToken: login.apitoken,
              isLoginButtonClicked: true,
            },
            async () => {
              this.setState({ isLoading: true }, () => {
                this.getOtp().then(() => {
                  this.setState({ isLoading: false });
                });
              });
            }
          );
        }
      }
    });
  };

  getOtp = async () => {
    const getOtpUrl = `usermetaauths/sendOtp?userId=${
      this.state.userId
    }&receiverType=SMS&type=signinotp`;
    const getOtp = await getCall(getOtpUrl, []);
    if (getOtp.error) {
      this.loadErrorMessageAnimation(getOtp.error.message);
    } else {
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_otp_desc") + " " + this.state.cellnumber
      );
    }
  };

  verifyOtp = async () => {
    const verifyOtpUrl = "usermetaauths/verifyOtp";
    const verifyOtpObj = {
      signinotp: this.state.otp,
      userId: this.state.userId,
      devicetoken: "",
    };
    const verifyOtp = await postCall(verifyOtpUrl, verifyOtpObj);
    if (verifyOtp.error) {
      this.loadErrorMessageAnimation(verifyOtp.error.message);
    } else {
      // store all the user data to localstorage
      localStorage.setItem("apiToken", verifyOtp.apitoken);
      saveUserDetailsToLocalStorage(verifyOtp);
      this.props.history.push("/");
    }
  };

  resendOtp = () => {
    this.setState({ isLoading: true }, () => {
      this.getOtp().then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  onClickOfOTPLogin = () => {
    if (this.state.otp.trim().length === 0) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("alert_invalide_otp")
          ? getValueFromLanguageObject("alert_invalide_otp")
          : "OTP not receive proper."
      );
    } else {
      this.verifyOtp();
    }
  };

  handleChangeOfCellNumber = (event) => {
    this.setState({ cellnumber: event.target.value });
  };

  handleChangeOfOTPNumber = (event) => {
    this.setState({ otp: event.target.value });
  };

  onClickOfResendOtp = () => {
    this.setState({ remainingTime: 60 });
    this.resendOtp();
  };

  onClickOfChangeMobileNumber = () => {
    this.setState({ isLoginButtonClicked: false });
  };

  renderTime = ({ remainingTime }) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
      isNewTimeFirstTick.current = true;
      prevTime.current = currentTime.current;
      currentTime.current = remainingTime;
      this.setState({ remainingTime });
    } else {
      isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
      setTimeout(() => {
        setOneLastRerender((val) => val + 1);
      }, 20);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
      <div className="time-wrapper">
        <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
          {remainingTime}
        </div>
      </div>
    );
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      isLoginButtonClicked,
      isLoading,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
    } = this.state;
    const { isRegisterAllow } = this.props;
    return (
      <>
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>{AppTitle} | Login</title>
            <meta
              name="description"
              content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
            />
          </Helmet>
          {/*SEO Support End */}
          <Breadcrumb
            title={
              getValueFromLanguageObject("str_login")
                ? getValueFromLanguageObject("str_login")
                : "Login"
            }
          />

          {/*Login section*/}
          <section className="login-page section-b-space">
            <div className="container">
              <div className="row row-flex justify-content-md-center">
                <div className="col-lg-6">
                  <h3
                    style={{
                      color: getColorCodeBasedOnKey("text_login"),
                    }}
                  >
                    {getValueFromLanguageObject("str_login")
                      ? getValueFromLanguageObject("str_login")
                      : "Login"}
                  </h3>
                  <div className="theme-card" style={{ position: "relative" }}>
                    {!isLoginButtonClicked && (
                      <form className="theme-form">
                        <div className="form-group">
                          <label
                            htmlFor="cellnumber"
                            style={{
                              color: getColorCodeBasedOnKey(
                                "text_cell_number_login"
                              ),
                            }}
                          >
                            {getValueFromLanguageObject("mobile_number")
                              ? getValueFromLanguageObject("mobile_number")
                              : "Mobile number"}
                          </label>
                          <input
                            id="cellnumber"
                            type="tel"
                            onChange={this.handleChangeOfCellNumber}
                            className="form-control"
                            value={this.state.cellnumber}
                            placeholder={
                              getValueFromLanguageObject(
                                "str_enter_your_mobile_number"
                              )
                                ? getValueFromLanguageObject(
                                    "str_enter_your_mobile_number"
                                  )
                                : "Enter your mobile number"
                            }
                            required={true}
                            pattern="/^\?([0-9]{10})$/"
                          />
                        </div>

                        <div
                          onClick={() => {
                            this.onClickOfMobileLogin();
                          }}
                          className="btn btn-solid"
                          style={{
                            color: getColorCodeBasedOnKey("btn_text_login"),
                            backgroundImage: `linear-gradient(30deg, ${getColorCodeBasedOnKey(
                              "btn_backgroud_iamge_login"
                            )} 50%, transparent 50%)`,
                          }}
                        >
                          {getValueFromLanguageObject("str_login")
                            ? getValueFromLanguageObject("str_login")
                            : "Login"}
                        </div>
                      </form>
                    )}

                    {isLoginButtonClicked && (
                      <form
                        className="theme-form"
                        style={{ position: "relative" }}
                      >
                        <div className="form-group">
                          <label
                            htmlFor="otp"
                            style={{
                              color: getColorCodeBasedOnKey("text_otp_login"),
                            }}
                          >
                            {getValueFromLanguageObject("str_otp")
                              ? getValueFromLanguageObject("str_otp")
                              : "OTP"}
                          </label>
                          {/* <OtpInput
                            className="otp-field"
                            value={this.state.otp}
                            onChange={this.handleChangeOfOTPNumber}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                            // separator={<span>-</span>}
                            htmlAttrs={{
                              autocomplete: 'off',
                              name: 'number'
                        }}
                        isInputSecure
                            inputStyle={{
                              width: "0.625rem",
                              height: "1rem",
                              marginTop: "1rem",
                              marginBottom: "1rem",
                              marginLeft: "5px",
                              marginRight: "5px",
                              fontSize: "1rem",
                              // borderRadius: 7,
                              border: "2px solid rgba(0,0,0,0.3)",
                            }} /> */}
                          <input
                            type="number"
                            className="form-control"
                            id="otp"
                            onChange={this.handleChangeOfOTPNumber}
                            placeholder={
                              getValueFromLanguageObject("str_otp_title")
                                ? getValueFromLanguageObject("str_otp_title")
                                : "Please enter the OTP to verify your account"
                            }
                            required={true}
                          />
                        </div>
                        <div
                          onClick={() => {
                            this.onClickOfOTPLogin();
                          }}
                          className="btn btn-solid"
                        >
                          {getValueFromLanguageObject("str_login")
                            ? getValueFromLanguageObject("str_login")
                            : "Login"}
                        </div>
                        <div>
                          <div className="pull-right otp-loader mt-2 mb-3">
                            {this.state.remainingTime !== 0 && (
                              <CountdownCircleTimer
                                size={50}
                                isPlaying
                                duration={this.state.remainingTime}
                                colors={[
                                  ["#004777", 0.33],
                                  ["#F7B801", 0.33],
                                  ["#A30000"],
                                ]}
                              >
                                {this.renderTime}
                              </CountdownCircleTimer>
                            )}
                          </div>
                          {this.state.remainingTime === 0 && (
                            <h6
                              className="resend-click"
                              style={{
                                color: getColorCodeBasedOnKey(
                                  "text_resend_otp_login"
                                ),
                              }}
                            >
                              {getValueFromLanguageObject(
                                "str_dont_receive_otp_with_question_mark"
                              )
                                ? getValueFromLanguageObject(
                                    "str_dont_receive_otp_with_question_mark"
                                  )
                                : "Don't receive OTP? "}

                              <span
                                className="resend-otp"
                                onClick={() => {
                                  this.onClickOfResendOtp();
                                }}
                                style={{
                                  marginTop: "0px",
                                  color: "#ff4c3b",
                                  cursor: "pointer",
                                }}
                              >
                                {getValueFromLanguageObject("str_resend_otp")
                                  ? getValueFromLanguageObject("str_resend_otp")
                                  : " Resend OTP"}
                              </span>
                            </h6>
                          )}
                        </div>
                        <div
                          className="pull-right"
                          onClick={() => {
                            this.onClickOfChangeMobileNumber();
                          }}
                        >
                          Change Mobile Number
                        </div>
                      </form>
                    )}
                  </div>
                </div>
                {isRegisterAllow && (
                  <div className="col-lg-6 right-login">
                    <h3
                      style={{
                        color: getColorCodeBasedOnKey(
                          "text_new_customer_login"
                        ),
                      }}
                    >
                      {getValueFromLanguageObject("str_new_customer")
                        ? getValueFromLanguageObject("str_new_customer")
                        : "New Customer"}
                    </h3>
                    <div className="theme-card authentication-right">
                      <h6
                        className="title-font"
                        style={{
                          color: getColorCodeBasedOnKey(
                            "text_create_an_account_login"
                          ),
                        }}
                      >
                        {getValueFromLanguageObject("str_create_new_account")
                          ? getValueFromLanguageObject("str_create_new_account")
                          : "Create an Account"}
                      </h6>
                      <p
                        style={{
                          color: getColorCodeBasedOnKey(
                            "text_create_an_account_login"
                          ),
                        }}
                      >
                        {getValueFromLanguageObject(
                          "str_create_new_account_desc"
                        )
                          ? getValueFromLanguageObject(
                              "str_create_new_account_desc"
                            )
                          : "Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click 'create an account'."}
                      </p>
                      <button
                        onClick={() => this.props.history.push("/signup")}
                        className="btn btn-solid"
                        style={{
                          color: getColorCodeBasedOnKey("btn_text_login"),
                          backgroundImage: `linear-gradient(30deg, ${getColorCodeBasedOnKey(
                            "btn_backgroud_iamge_login"
                          )} 50%, transparent 50%)`,
                        }}
                      >
                        {getValueFromLanguageObject("str_create_new_account")
                          ? getValueFromLanguageObject("str_create_new_account")
                          : "Create an Account"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>

          {isLoading && <MainLoader />}
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isRegisterAllow: state.settings.isRegisterAllow,
});

export default connect(
  mapStateToProps,
  { isUserAllowedToRegister: isUserAllowedToRegister }
)(Login);
// export default Login;
