import React, { Component } from "react";
import { getCall } from "../../../api/post";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getMerchantInformationUrl,
  getPaymentDetailsUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import { connect } from "react-redux";
import Moment from "react-moment";
import {
  getCommonCounter,
  getNotificationCounter,
  isInquiryOrCart,
} from "../../../actions";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../../store";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import RightTickMarkAnim from "../../../animation/RightTickMarkAnim";

class OrderDetails extends Component {
  state = {
    items: [],
    orderId: this.props.match.params.id,
    inquiryId: this.props.match.params.id,
    orderSummary: {},
    inquirySummary: {},
    isLoading: true,
    isMerchantGSTEnable: false,
    merchantInfo: {},
    paymentDetails: [],
    currentCatelogueType: {},
    showErrorMessageModal: false,
    errorMessage: "",
    masterDetailsObj: {},
  };

  componentDidMount() {
    const { masterDetailsObj } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      this.props.history.push(`/login`);
      return;
    }
    this.initMasterDetailsId().then(() => {
      this.initOrderSummary().then(() => {
        this.setState({ isLoading: false });
      });

      this.initMerchantInfo();
      this.initPaymentDetails();
      this.initCatelogueSettings();
      this.props.counter();
      this.props.notificationCounter();
      this.props.isInquiryOrCart(masterDetailsObj);
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };
  initInquirySummary = async () => {
    const { orderId } = this.state;
    const inquirySummaryUrl = `orders/${orderId} `;
    const orderSummary = await getCall(inquirySummaryUrl, []);

    if (orderSummary.error) {
      this.loadErrorMessageAnimation(orderSummary.error.message);
      if (orderSummary.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ orderSummary });
    }
  };
  initOrderSummary = async () => {
    const { orderId } = this.state;

    const orderSummaryUrl = `orders/${orderId}?filter[include]=orderdetails&filter[include]=productmedia`;
    const orderSummary = await getCall(orderSummaryUrl, []);

    if (orderSummary.error) {
      this.loadErrorMessageAnimation(orderSummary.error.message);
      if (orderSummary.error.statusCode === 401) {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("apiToken");
        this.props.history.push(`/login`);
        return;
      }
    } else {
      this.setState({ orderSummary });
    }
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      this.setState({ isMerchantGSTEnable: false, merchantInfo: {} });
    } else {
      this.setState({
        merchantInfo,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initPaymentDetails = async () => {
    const { masterDetailsObj } = this.state;
    const paymentDetailsUrl = getPaymentDetailsUrl(masterDetailsObj);
    const paymentDetails = await getCall(paymentDetailsUrl, []);
    if (paymentDetails.error) {
      this.loadErrorMessageAnimation(paymentDetails.error.message);
    } else {
      this.setState({ paymentDetails });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  getTotalofCart(cartList) {
    let total = 0;
    if (cartList.orderdetail)
      for (let i = 0; i < cartList.orderdetail.length; i++) {
        total =
          total +
          cartList.orderdetail[i].amount * cartList.orderdetail[i].quantity;
      }
    return total;
  }

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharges) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharges;
      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharges;
      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharges;
    } else {
      grandTotal = total + igst + shippingCharges;
    }
    return grandTotal;
  };

  renderBillingAddress = () => {
    const { orderSummary } = this.state;
    if (
      orderSummary !== undefined &&
      orderSummary.address &&
      orderSummary.address !== undefined
    ) {
      const address = JSON.parse(orderSummary.address);
      if (Object.keys(address).length === 0) {
        return;
      }
      if (address !== undefined) {
        const billingAddress = address.billingaddress;
        return (
          <ul className="order-detail">
            <li style={{ fontWeight: 550 }}> Name : {billingAddress.name}</li>
            <li> Mobile number : {billingAddress.mobile}</li>
            {billingAddress.email && (
              <li> Email Address : {billingAddress.email}</li>
            )}
            {billingAddress.companyname && (
              <li> Company Name : {billingAddress.companyname}</li>
            )}
            {billingAddress.gstin && <li> GSTIN : {billingAddress.gstin}</li>}

            {billingAddress.address1 && (
              <li>
                Address : {billingAddress.address1},{billingAddress.address2},
                {billingAddress.landmark},
              </li>
            )}
            <li>
              {billingAddress.cityname}, {billingAddress.statename},{" "}
              {billingAddress.countryname} - {billingAddress.zipcode}
            </li>
          </ul>
        );
      }
    }
  };

  renderShippingAddress = () => {
    const { orderSummary } = this.state;
    if (orderSummary !== undefined && orderSummary.address !== undefined) {
      const address = JSON.parse(orderSummary.address);
      if (Object.keys(address).length === 0) {
        return;
      }
      if (address !== undefined) {
        const shippingaddress = address.shippingaddress;
        return (
          <ul className="order-detail">
            <li style={{ fontWeight: 550 }}> Name : {shippingaddress.name}</li>
            <li> Mobile number : {shippingaddress.mobile}</li>
            {shippingaddress.email && (
              <li> Email Address : {shippingaddress.email}</li>
            )}
            {shippingaddress.companyname && (
              <li> Company Name : {shippingaddress.companyname}</li>
            )}
            {shippingaddress.gstin && <li> GSTIN : {shippingaddress.gstin}</li>}

            {shippingaddress.address1 && (
              <li>
                Address : {shippingaddress.address1},{shippingaddress.address2},
                {shippingaddress.landmark},
              </li>
            )}
            <li>
              {shippingaddress.cityname}, {shippingaddress.statename},{" "}
              {shippingaddress.countryname} - {shippingaddress.zipcode}
            </li>
          </ul>
        );
      }
    }
  };

  renderPriceBreakdownSJ = (other) => {
    const jwelleryData = JSON.parse(other);
    if (jwelleryData.jewelleryData) {
      return (
        <div className="col-12" style={{ textAlign: "end" }}>
          <h4>Price Breakdown</h4>
          <p>
            {jwelleryData.jewelleryData.name} :{" "}
            {jwelleryData.jewelleryData.weightintoprice}
          </p>
          {jwelleryData.jewelleryData.makingcharges &&
            jwelleryData.jewelleryData.makingcharges.map((makingcharge) => {
              return (
                <p>
                  {makingcharge.pricelable} : {makingcharge.amount}
                </p>
              );
            })}
        </div>
      );
    } else {
      return <div />;
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      orderSummary,
      isLoading,
      isMerchantGSTEnable,
      currentCatelogueType,
      paymentDetails,
      showErrorMessageModal,
      errorMessage,
      masterDetailsObj,
    } = this.state;
    const { symbol, isInquiry } = this.props;

    return (
      <div>
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="success-text">
                  {/* <i className="fa fa-check-circle" aria-hidden="true" /> */}
                  <RightTickMarkAnim />
                  <h2>
                    {getValueFromLanguageObject("str_thank_you")
                      ? getValueFromLanguageObject("str_thank_you")
                      : "thank you"}
                  </h2>

                  <div>
                    <p>
                      {!isInquiry
                        ? getValueFromLanguageObject(
                            "str_order_placed_successfully"
                          )
                          ? getValueFromLanguageObject(
                              "str_order_placed_successfully"
                            )
                          : "Order Placed Successfully"
                        : getValueFromLanguageObject(
                            "str_inquiry_placed_successfully"
                          )
                        ? getValueFromLanguageObject(
                            "str_inquiry_placed_successfully"
                          )
                        : "Inquiry Placed Successfully"}
                    </p>
                    <p>
                      {!isInquiry
                        ? getValueFromLanguageObject("str_order_no_")
                          ? getValueFromLanguageObject("str_order_no_")
                          : "Order ID"
                        : getValueFromLanguageObject("str_inquiry_no_")
                        ? getValueFromLanguageObject("str_inquiry_no_")
                        : "Inquiry ID"}
                      : {orderSummary.orderno}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="product-order order-success-sec">
                  <h4 className="f22">
                    {!isInquiry
                      ? getValueFromLanguageObject("str_your_order_details")
                        ? getValueFromLanguageObject("str_your_order_details")
                        : "Your order details"
                      : getValueFromLanguageObject("str_your_inquiry_details")
                      ? getValueFromLanguageObject("str_your_inquiry_details")
                      : "Your inquiry details"}
                  </h4>

                  {orderSummary.orderdetail &&
                    orderSummary.orderdetail.map((item, index) => {
                      return (
                        <div className="row product-order-detail" key={index}>
                          <div className="col-12 col-sm-2">
                            <img
                              src={
                                item.products.productmedia.length > 0
                                  ? getImageUrl(
                                      item.products.productmedia[0].productname,
                                      masterDetailsObj
                                    )
                                  : getPlaceHolderImage()
                              }
                              alt=""
                              className="img-fluid"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = getPlaceHolderImage();
                              }}
                            />
                          </div>
                          <div className="col-12 col-sm-4 order_detail">
                            <div>
                              <h4>
                                {getValueFromLanguageObject("str_product_name")
                                  ? getValueFromLanguageObject(
                                      "str_product_name"
                                    )
                                  : "product name"}
                              </h4>
                              <h5>{item.products.name}</h5>
                            </div>
                          </div>
                          <div className="col-6 col-sm-3 order_detail">
                            <div>
                              <h4>
                                {getValueFromLanguageObject("str_quantity")
                                  ? getValueFromLanguageObject("str_quantity")
                                  : "quantity"}
                              </h4>
                              <h5>{item.quantity}</h5>
                            </div>
                          </div>
                          {!isInquiry && (
                            <div className="col-6 col-sm-3 order_detail">
                              <div>
                                <h4>
                                  {getValueFromLanguageObject("str_price")
                                    ? getValueFromLanguageObject("str_price")
                                    : "price"}
                                </h4>
                                <h5>
                                  {symbol}
                                  {parseFloat(item.amount).toFixed(2)}
                                </h5>
                              </div>
                            </div>
                          )}
                          {currentCatelogueType &&
                            currentCatelogueType.registerallow ===
                              "Catalogue_Jewellary" &&
                            item.products.other &&
                            item.products.other &&
                            this.renderPriceBreakdownSJ(item.products.other)}
                        </div>
                      );
                    })}
                  {!isInquiry && (
                    <div className="total-sec">
                      <div className="final-total">
                        <h4>
                          {getValueFromLanguageObject("str_total")
                            ? getValueFromLanguageObject("str_total")
                            : "total"}
                          <span>
                            {`${symbol} ${parseFloat(
                              this.getTotalofCart(orderSummary)
                            ).toFixed(2)}`}
                          </span>
                        </h4>
                        <h4>
                          {`+ ${
                            getValueFromLanguageObject("str_shipping_charges")
                              ? getValueFromLanguageObject(
                                  "str_shipping_charges"
                                )
                              : "Shipping Charges"
                          }`}
                          <span>
                            {`${symbol} ${parseFloat(
                              orderSummary.shippingprice
                                ? orderSummary.shippingprice
                                : 0
                            ).toFixed(2)}`}
                          </span>
                        </h4>
                        {isMerchantGSTEnable &&
                          currentCatelogueType &&
                          currentCatelogueType.registerallow !==
                            "Catalogue_Jewellary" &&
                          this.state.merchantInfo.countryname === "India" && (
                            <h4>
                              {`+ ${
                                getValueFromLanguageObject("str_cgst")
                                  ? getValueFromLanguageObject("str_cgst")
                                  : "CGST"
                              } (${this.state.merchantInfo.CGST}%) `}
                              <span>
                                {`${symbol} ${parseFloat(
                                  this.getCGSTFromTotal(
                                    this.getTotalofCart(orderSummary)
                                  )
                                ).toFixed(2)}`}
                              </span>
                            </h4>
                          )}
                        {isMerchantGSTEnable &&
                          currentCatelogueType &&
                          currentCatelogueType.registerallow !==
                            "Catalogue_Jewellary" &&
                          this.state.merchantInfo.countryname === "India" && (
                            <h4>
                              {`+ ${
                                getValueFromLanguageObject("str_sgst")
                                  ? getValueFromLanguageObject("str_sgst")
                                  : "SGST"
                              } (${this.state.merchantInfo.SGST}%) `}
                              <span>
                                {symbol}{" "}
                                {parseFloat(
                                  this.getSGSTFromTotal(
                                    this.getTotalofCart(orderSummary)
                                  )
                                ).toFixed(2)}
                              </span>
                            </h4>
                          )}
                        {isMerchantGSTEnable &&
                          currentCatelogueType &&
                          currentCatelogueType.registerallow !==
                            "Catalogue_Jewellary" &&
                          this.state.merchantInfo.countryname !== "India" && (
                            <h4>
                              {`+ ${
                                getValueFromLanguageObject("str_igst")
                                  ? getValueFromLanguageObject("str_igst")
                                  : "IGST"
                              } (${this.state.merchantInfo.IGST}%) `}

                              <span>
                                {`${symbol} ${parseFloat(
                                  this.getIGSTFromTotal(
                                    this.getTotalofCart(orderSummary)
                                  )
                                ).toFixed(2)}`}
                              </span>
                            </h4>
                          )}
                        <h4>
                          {`- ${
                            getValueFromLanguageObject("str_discount")
                              ? getValueFromLanguageObject("str_discount")
                              : "Discount"
                          }`}
                          <span>{`${symbol} ${parseFloat(0).toFixed(2)}`}</span>
                        </h4>
                        <h4>
                          {getValueFromLanguageObject("str_amount_payable")
                            ? getValueFromLanguageObject("str_amount_payable")
                            : "Amount Payable"}
                          <span>
                            {symbol}{" "}
                            {parseFloat(
                              this.getGrandTotal(
                                this.getTotalofCart(orderSummary),
                                this.getCGSTFromTotal(
                                  this.getTotalofCart(orderSummary)
                                ),
                                this.getSGSTFromTotal(
                                  this.getTotalofCart(orderSummary)
                                ),
                                this.getIGSTFromTotal(
                                  this.getTotalofCart(orderSummary)
                                ),
                                orderSummary.shippingprice
                              )
                            ).toFixed(2)}
                          </span>
                        </h4>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row order-success-sec">
                  <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                    <h4
                      className="f22"
                      style={{
                        color: getColorCodeBasedOnKey(
                          "text_summery_order_detail"
                        ),
                      }}
                    >
                      {getValueFromLanguageObject("str_summery")
                        ? getValueFromLanguageObject("str_summery")
                        : "summery"}
                    </h4>
                    <ul className="order-detail">
                      <li>
                        {!isInquiry
                          ? getValueFromLanguageObject("str_order_id")
                            ? getValueFromLanguageObject("str_order_id")
                            : "Order ID"
                          : getValueFromLanguageObject("str_inquiry_id")
                          ? getValueFromLanguageObject("str_inquiry_id")
                          : "Inquiry ID"}
                        : {orderSummary.orderno}
                      </li>

                      <li>
                        {!isInquiry
                          ? getValueFromLanguageObject("str_order_date")
                            ? getValueFromLanguageObject("str_order_date")
                            : "Order Date"
                          : getValueFromLanguageObject("str_inquiry_date")
                          ? getValueFromLanguageObject("str_inquiry_date")
                          : "Inquiry Date"}
                        :
                        <Moment format="DD-MMM-YYYY">
                          {orderSummary.date}
                        </Moment>
                      </li>

                      {!isInquiry && (
                        <li>
                          {getValueFromLanguageObject("str_delivery_date")
                            ? getValueFromLanguageObject("str_delivery_date")
                            : "Delivery Date"}
                          :
                          <Moment format="DD-MMM-YYYY">
                            {orderSummary.deliverydate}
                          </Moment>
                        </li>
                      )}
                      {!isInquiry && (
                        <li>
                          {getValueFromLanguageObject("str_order_total")
                            ? getValueFromLanguageObject("str_order_total")
                            : "Order Total"}
                          : {symbol}
                          {parseFloat(
                            this.getGrandTotal(
                              this.getTotalofCart(orderSummary),
                              this.getCGSTFromTotal(
                                this.getTotalofCart(orderSummary)
                              ),
                              this.getSGSTFromTotal(
                                this.getTotalofCart(orderSummary)
                              ),
                              this.getIGSTFromTotal(
                                this.getTotalofCart(orderSummary)
                              ),
                              orderSummary.shippingprice
                            )
                          ).toFixed(2)}
                        </li>
                      )}
                      {!isInquiry && (
                        <div>
                          <li>
                            <b>
                              {getValueFromLanguageObject("str_order_notes")
                                ? getValueFromLanguageObject("str_order_notes")
                                : "Order Notes"}
                              :
                            </b>
                          </li>
                          <li>{orderSummary.description || "-"}</li>
                        </div>
                      )}
                    </ul>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                    <h4 className="f22">
                      {getValueFromLanguageObject("str_customer_details")
                        ? getValueFromLanguageObject("str_customer_details")
                        : "Customer Details"}
                    </h4>
                    <ul className="order-detail">
                      <li
                        style={{
                          color: getColorCodeBasedOnKey(
                            "text_order_id_order_detail"
                          ),
                        }}
                      >
                        {getValueFromLanguageObject("str_name")
                          ? getValueFromLanguageObject("str_name")
                          : "Name"}
                        :{" "}
                        {orderSummary.orderuser &&
                          orderSummary.orderuser.username}
                      </li>
                      <li
                        style={{
                          color: getColorCodeBasedOnKey(
                            "text_order_id_order_detail"
                          ),
                        }}
                      >
                        {getValueFromLanguageObject("str_mobile_number")
                          ? getValueFromLanguageObject("str_mobile_number")
                          : "Mobile Number"}
                        :{" "}
                        {orderSummary.orderuser &&
                          orderSummary.orderuser.cellnumber}
                      </li>

                      {orderSummary.orderuser &&
                        orderSummary.orderuser.cityname && (
                          <li>
                            {getValueFromLanguageObject("str_city")
                              ? getValueFromLanguageObject("str_city")
                              : "City"}
                            : {orderSummary.orderuser.cityname}
                          </li>
                        )}
                    </ul>
                  </div>
                  {!isInquiry &&
                    paymentDetails &&
                    paymentDetails.length > 0 &&
                    paymentDetails[0].status === 1 && (
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <h4 className="f22">
                          {getValueFromLanguageObject("str_payment_details")
                            ? getValueFromLanguageObject("str_payment_details")
                            : "Payment Details"}
                        </h4>

                        <ul className="order-detail">
                          <li>
                            {getValueFromLanguageObject("str_pay_via")
                              ? getValueFromLanguageObject("str_pay_via")
                              : "Pay Via"}
                            :{" "}
                            {orderSummary.paymentDetail &&
                            JSON.parse(orderSummary.paymentDetail).pay_via !==
                              "COD"
                              ? JSON.parse(orderSummary.paymentDetail).pay_via
                              : "Cash On Delivery(COD)"}
                          </li>
                          <li>
                            {getValueFromLanguageObject("str_payment_id")
                              ? getValueFromLanguageObject("str_payment_id")
                              : "Payment ID"}
                            :{" "}
                            {orderSummary.paymentDetail &&
                            JSON.parse(orderSummary.paymentDetail)
                              .transaction_no !== ""
                              ? JSON.parse(orderSummary.paymentDetail)
                                  .transaction_no
                              : "N/A"}
                          </li>
                        </ul>
                      </div>
                    )}
                  {!isInquiry && (
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                      <h4 className="f22">
                        {getValueFromLanguageObject("shipping_address")
                          ? getValueFromLanguageObject("shipping_address")
                          : "shipping address"}
                      </h4>
                      {this.renderBillingAddress()}
                    </div>
                  )}

                  {!isInquiry && (
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                      <h4 className="f22">
                        {getValueFromLanguageObject("billing_address")
                          ? getValueFromLanguageObject("billing_address")
                          : "billing address"}
                      </h4>
                      {this.renderShippingAddress()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
    isInquiryOrCart: isInquiryOrCart,
  }
)(OrderDetails);
